<div class="confirm">
  <div class="header">
    <h2>Edit comment</h2>
    <span class="icon-Close close-icon" title="Close" (click)="close()"></span>
  </div>
  <div class="modal-content">
    <form [formGroup]="form" class="form">
      <div class="form__wrapper">
        <textarea
          rows="5"
          class="form__field"
          formControlName="message"
          placeholder="Add a comment"
          #messageInput></textarea>
        <div class="form__reactions">
          <app-reactions-popover
            (selectReactionEvent)="onAddReactionToMessage($event)"
            [position]="popoverPosition"></app-reactions-popover>
        </div>
      </div>
      <div class="modal-content__buttons-list">
        <app-button (click)="close()" [buttonData]="closeBtn"></app-button>
        <app-button (click)="confirm()" [attr.disabled]="form.invalid" [buttonData]="confirmBtn"></app-button>
      </div>
    </form>
  </div>
</div>
