import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AdditionalService } from '../../../../shared/services/additional.service';
import { TwitchService } from '../../../../shared/services/twitch.service';
import { WalletConnectService } from '../../../../shared/services/wallet-connect.service';
import { Button, Channel } from '../../../../shared/intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../../shared/enums';
import { debounceTime, skip } from 'rxjs/operators';
import { TurnstileService } from '../../../../shared/services/turnstile.service';

@Component({
  selector: 'app-channel-page',
  templateUrl: './channel-page.component.html',
  styleUrls: ['./channel-page.component.scss']
})
export class ChannelPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  isBrowserVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  channelData: Channel = {} as Channel;
  isChatOpen: boolean = true;
  channelName: string = '';

  readonly openDonatePopoverBtn: Button = {
    name: 'Donate',
    size: BUTTON_SIZE.SM,
    type: BUTTON_TYPE.FILLED,
    iconLeft: 'icon-donate'
  };

  constructor(
    public additionalService: AdditionalService,
    public twitchService: TwitchService,
    public walletConnectService: WalletConnectService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public turnstileService: TurnstileService
  ) {
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange(): void {
    this.isBrowserVisible$.next(document.visibilityState !== 'hidden');
  }

  ngOnInit(): void {
    this.subscriptions.add(this.activatedRoute.paramMap.subscribe((params) => this.handleRouteParams(params)));

    this.subscriptions.add(
      this.turnstileService.turnstileToken$.subscribe((token) => this.handleTurnstileToken(token))
    );

    this.subscriptions.add(
      this.isBrowserVisible$
        .pipe(skip(1), debounceTime(1000))
        .subscribe((isBrowserFocus) => this.handleBrowserVisibility(isBrowserFocus))
    );
  }

  private handleRouteParams(params: ParamMap): void {
    this.channelName = params.get('channel')!;
    this.twitchService.initializeTwitchPlayer(this.channelName);
    this.twitchService.currentChannelName$.next(this.channelName);
  }

  private handleTurnstileToken(token: string | null): void {
    if (token !== null) {
      this.subscriptions.add(
        this.twitchService.channelsWithStatus$.subscribe((channels) => this.handleChannelStatus(channels))
      );
    }
  }

  private handleChannelStatus(channels: Channel[]): void {
    if (channels.length > 0 && !this.channelData.channelName) {
      const channelData = this.twitchService.getCurrentChannelData();
      if (channelData?.channelName) {
        this.channelData = channelData;
        if (this.channelData.isLive) {
          this.refreshTokenAndStartSession();
        }
      }
    } else if (this.channelData.channelName) {
      const updatedChannelData = this.twitchService.getCurrentChannelData();
      const wasLive = this.channelData.isLive;
      this.channelData.isLive = updatedChannelData?.isLive || false;
      if (this.channelData.isLive && !wasLive) {
        this.refreshTokenAndStartSession();
      } else if (!this.channelData.isLive) {
        this.twitchService.stopCollectingReward();
      }
    }
  }

  private handleBrowserVisibility(isBrowserFocus: boolean): void {
    if (this.channelData.isLive) {
      if (isBrowserFocus) {
        this.refreshTokenAndStartSession();
      } else {
        this.refreshTokenAndUpdateSession();
        this.twitchService.stopCollectingReward();
      }
    }
  }

  async refreshTokenAndStartSession(): Promise<void> {
    try {
      await this.turnstileService.refreshToken();
      this.twitchService.startCollectingReward();
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  }

  async refreshTokenAndUpdateSession(): Promise<void> {
    try {
      await this.turnstileService.refreshToken();
      this.twitchService.updateWatchingSessionRequest().subscribe();
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  }

  openDonatePopover() {
    if (this.walletConnectService.isWalletConnected$.getValue()) {
      this.walletConnectService.isDonatePopoverOpen = !this.walletConnectService.isDonatePopoverOpen;
    } else {
      this.toastrService.success('Please connect your wallet to make a donation!');
    }
  }

  closeDonatePopover() {
    this.walletConnectService.isDonatePopoverOpen = false;
  }

  toggleChatOpen() {
    this.isChatOpen = !this.isChatOpen;
  }

  ngOnDestroy(): void {
    this.twitchService.stopCollectingReward();
    this.subscriptions?.unsubscribe();
  }
}
