import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AdditionalService } from '../../../services/additional.service';
import { UserService } from '../../../services/user.service';
import { StoreService } from '../../../services/store.service';
import { Button, OrderData } from '../../../intarfaces';
import { BUTTON_SIZE, COLOSSEUM_TIERS } from '../../../enums';

@Component({
  selector: 'store-shipping-modal',
  templateUrl: './store-shipping-modal.component.html',
  styleUrls: ['./store-shipping-modal.component.scss']
})
export class StoreShippingModalComponent implements OnInit {
  @AutoUnsubscribe()
  private userInfoSubs: Subscription | undefined;
  @AutoUnsubscribe()
  private userSubscriptionTypeSubs: Subscription | undefined;
  @AutoUnsubscribe()
  private activitySub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private createOrderSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private sendInvoiceSub: Subscription = {} as Subscription;

  protected form: FormGroup = new FormGroup({});
  protected discountAmount: number = 0;
  readonly nameMinLength: number = 2;
  readonly nameMaxLength: number = 50;
  readonly phoneMinLength: number = 7;
  readonly phoneMaxLength: number = 15;
  readonly EMAIL_REGEX: string = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';

  readonly confirmBtn: Button = { name: 'Confirm', size: BUTTON_SIZE.LG };

  private readonly variantId: number | undefined;
  private readonly quantity: number | undefined;

  constructor(
    public dialogRef: MatDialogRef<StoreShippingModalComponent>,
    protected additionalService: AdditionalService,
    private userService: UserService,
    private toastrService: ToastrService,
    private storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) data: { quantity: number; variantId: number }
  ) {
    this.variantId = data?.variantId;
    this.quantity = data?.quantity;
  }

  ngOnInit() {
    this.userInfoSubs = this.userService.userInfo$.subscribe((data) => {
      this.form = new FormGroup({
        firstName: new FormControl(data.firstName, [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z- ]*$')
        ]),
        lastName: new FormControl(data.lastName, [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z- ]*$')
        ]),
        email: new FormControl(data.email, [Validators.required, Validators.pattern(this.EMAIL_REGEX)]),
        city: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z- ]*$')]),
        country: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z- ]*$')]),
        address: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9-,. ]*$')]),
        zipCode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
        state: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z- ]*$')]),
        mobilePhone: new FormControl(data.mobilePhone, [
          Validators.minLength(this.phoneMinLength),
          Validators.maxLength(this.phoneMaxLength),
          Validators.pattern('^\\+[0-9]*$')
        ])
      });
    });

    this.userSubscriptionTypeSubs = this.userService.userSubscriptionData$.subscribe((userSubscriptionData) => {
      switch (userSubscriptionData.type) {
        case COLOSSEUM_TIERS.GOLD:
          this.discountAmount = 10;
          break;
        case COLOSSEUM_TIERS.PLATINUM:
          this.discountAmount = 25;
          break;
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formData = { ...this.form.value };
      const orderData: OrderData = {
        discount_codes: [
          {
            code: 'COLOSSEUM_DISCOUNT',
            amount: this.discountAmount,
            type: 'percentage'
          }
        ],
        line_items: [
          {
            variant_id: this.variantId!,
            quantity: this.quantity!
          }
        ],
        financial_status: 'pending',
        customer: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email
        },
        billing_address: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          address1: formData.address,
          phone: formData.phone,
          city: formData.city,
          province: formData.state,
          country: formData.country,
          zip: formData.zip
        },
        shipping_address: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          address1: formData.address,
          phone: formData.phone,
          city: formData.city,
          province: formData.state,
          country: formData.country,
          zip: formData.zip
        },
        email: formData.email
      };

      this.createOrderSub = this.storeService.createOrder(orderData).subscribe((orderResponse) => {
        const orderId = orderResponse.admin_graphql_api_id;
        const userEmail = orderResponse.email;

        this.sendInvoiceSub = this.storeService.sendInvoice(orderId, userEmail).subscribe(() => {
          this.sendUserActivity();
        });
      });
    }
  }

  onMobilePhoneClick() {
    if (!this.form.value.mobilePhone || this.form.value.mobilePhone === '') {
      this.form.get('mobilePhone')?.setValue('+');
    }
  }

  onKeyup(value: any) {
    const previousValue = this.form.value.mobilePhone;
    if (previousValue.length > 0 && previousValue[0] !== '+' && previousValue.indexOf('+') < 0) {
      this.form.get('mobilePhone')?.setValue('+' + previousValue);
    }
  }

  sendUserActivity() {
    this.activitySub = this.userService.sendUserActivityRequest().subscribe({
      next: () => {
        this.close();
        this.toastrService.success('We have sent you an invoice by email. Please verify receipt.', 'Order information');
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
