<div class="container">
  <div class="profile">
    <h2 class="back-title">
      <div routerLink="/content">
        <span class="icon icon-Arrow-Left"></span>
      </div>
    </h2>
    <h2 class="title">Fan Profile</h2>

    <div class="profile__data">
      <div class="profile__data-user">
        <div class="profile__data-user-avatar">
          <img
            class="avatar"
            [src]="(userService.userInfo$ | async)?.avatarUrl"
            onError="this.src = 'assets/icons/default-user-image.png'"
            alt="" />
          <span class="icon-Edit" (click)="onAvatarEdit()"></span>
        </div>

        <div class="profile__data-user-name">
          <h3>{{ (userService.userInfo$ | async)?.firstName }}</h3>
          <h4>{{ (userService.userSubscriptionData$ | async)?.name }}</h4>
        </div>
      </div>
      <div class="profile__data-membership">
        <div
          class="profile__data-membership-item duration"
          *ngIf="
            (userService.userSubscriptionData$ | async)?.type &&
            (userService.userSubscriptionData$ | async)?.type !== COLOSSEUM_TIERS.BRONZE
          ">
          <span class="days">{{ (userService.userSubscriptionData$ | async)?.days }} Days</span>
          <span class="text">Member duration</span>
        </div>
        <div
          class="profile__data-membership-item duration"
          *ngIf="
            (userService.userSubscriptionData$ | async)?.type &&
            (userService.userSubscriptionData$ | async)?.type !== COLOSSEUM_TIERS.BRONZE
          ">
          <span class="days">{{ subsService.unlockedDays }} Days</span>
          <span class="text">Unlocked duration</span>
        </div>
        <div
          class="profile__data-membership-item type"
          (click)="packagesModalOpen()"
          *ngIf="
            (userService.userSubscriptionData$ | async)?.type &&
            (userService.userSubscriptionData$ | async)?.type !== COLOSSEUM_TIERS.BRONZE
          ">
          <img [src]="(userService.userSubscriptionData$ | async)?.img" alt="" />
          <span class="text">{{ (userService.userSubscriptionData$ | async)?.name }} member</span>
        </div>
        <div class="profile__data-membership-item wallet" (click)="walletConnectService.openModal()">
          <span class="icon icon-Withdraw"></span>
          <span class="text">{{
            walletConnectService.walletAddress
              ? (walletConnectService.walletAddress | slice: 0 : 4) +
                '....' +
                (walletConnectService.walletAddress | slice: -4)
              : 'Connect wallet'
          }}</span>
        </div>
      </div>
    </div>

    <div
      class="profile__data-membership-mobile"
      *ngIf="
        (userService.userSubscriptionData$ | async)?.type &&
        (userService.userSubscriptionData$ | async)?.type !== COLOSSEUM_TIERS.BRONZE
      ">
      <div class="profile__data-membership-mobile-item">
        <span class="days">{{ (userService.userSubscriptionData$ | async)?.days }} Days</span>
        <span class="text">Member duration</span>
      </div>
      <div class="profile__data-membership-mobile-item">
        <span class="days">{{ subsService.unlockedDays }} Days</span>
        <span class="text">Unlocked duration</span>
      </div>
      <div class="profile__data-membership-mobile-item type" (click)="packagesModalOpen()">
        <img [src]="(userService.userSubscriptionData$ | async)?.img" alt="" />
        <span class="text">{{ (userService.userSubscriptionData$ | async)?.name }} member</span>
      </div>
    </div>

    <ul class="profile__tabs">
      <li [class.active]="activeTab === 0" (click)="setActiveTab(0)">My Profile</li>
      <li [class.active]="activeTab === 1" (click)="setActiveTab(1)">Fan Passport</li>
    </ul>

    <ng-container *ngIf="activeTab === 0">
      <div class="my-profile" *ngIf="activeTab === 0">
        <form
          *ngIf="(userService.userInfo$ | async)?.id"
          action=""
          class="form"
          [formGroup]="form"
          (ngSubmit)="onSubmit()">
          <div class="form__field">
            <label for="userId">User ID</label>
            <div class="form__field-group">
              <input id="userId" formControlName="id" #lastName placeholder="User ID" />
            </div>
          </div>
          <div class="form__field">
            <label for="firstName">First Name</label>
            <div class="form__field-group">
              <input id="firstName" formControlName="firstName" #firstName placeholder="First Name" />
              <div class="form__error" *ngIf="form.get('firstName')?.invalid && form.get('firstName')?.touched">
                <div *ngIf="form.get('firstName')?.hasError('required')">First Name should be present</div>

                <ng-container
                  *ngIf="
                    form.get('firstName')?.hasError('minlength') || form.get('firstName')?.hasError('maxlength');
                    else firstName_pattern
                  ">
                  <div>
                    First Name should have
                    <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
                    characters. You currently typed
                    <mark>{{ (form.get('firstName')?.value).length }}</mark>
                  </div>
                </ng-container>

                <ng-template #firstName_pattern>
                  <ng-container>
                    <div *ngIf="form.get('firstName')?.hasError('pattern'); else firstName_complexity">
                      First Name should have only latin characters, hyphen and
                      <mark>1</mark>
                      space
                    </div>
                  </ng-container>
                </ng-template>

                <ng-template #firstName_complexity>
                  <div
                    *ngIf="
                      !form.get('firstName')?.hasError('pattern') && form.get('firstName')?.hasError('complexity')
                    ">
                    Only one space can be used
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="form__field">
            <label for="lastName">Last Name</label>
            <div class="form__field-group">
              <input id="lastName" formControlName="lastName" #lastName placeholder="Last Name" />
              <div class="form__error" *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched">
                <div *ngIf="form.get('lastName')?.hasError('required')">Last Name should be present</div>

                <ng-container
                  *ngIf="
                    form.get('lastName')?.hasError('minlength') || form.get('lastName')?.hasError('maxlength');
                    else lastName_pattern
                  ">
                  <div>
                    Last Name should have
                    <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
                    characters. You currently typed
                    <mark>{{ (form.get('lastName')?.value).length }}</mark>
                  </div>
                </ng-container>

                <ng-template #lastName_pattern>
                  <ng-container>
                    <div *ngIf="form.get('lastName')?.hasError('pattern'); else lastName_complexity">
                      Last Name should have only latin characters, hyphen and
                      <mark>1</mark>
                      space
                    </div>
                  </ng-container>
                </ng-template>

                <ng-template #lastName_complexity>
                  <div
                    *ngIf="!form.get('lastName')?.hasError('pattern') && form.get('lastName')?.hasError('complexity')">
                    Only one space can be used
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="form__field">
            <label for="lastName">Email</label>
            <div class="form__field-group">
              <input id="email" formControlName="email" #lastName placeholder="Email" />
            </div>
          </div>
          <div class="form__field">
            <label for="mobilePhone">Phone Number</label>
            <div class="form__field-group">
              <input
                id="mobilePhone"
                formControlName="mobilePhone"
                #mobilePhone
                placeholder="Phone Number"
                (keypress)="additionalService.allowOnlyNumbers($event)"
                (keyup)="onKeyup($any($event.target).value)"
                (focus)="onMobilePhoneClick()"
                (click)="onMobilePhoneClick()" />
              <div class="form__error" *ngIf="form.get('mobilePhone')?.invalid && form.get('mobilePhone')?.touched">
                <ng-container
                  *ngIf="
                    form.get('mobilePhone')?.hasError('minlength') || form.get('mobilePhone')?.hasError('maxlength');
                    else mobilePhone_pattern
                  ">
                  <div>
                    Phone Number should have
                    <mark>{{ phoneMinLength - 1 }} - {{ phoneMaxLength - 1 }}</mark>
                    characters. You currently typed
                    <mark>{{ (form.get('mobilePhone')?.value).length - 1 }}</mark>
                  </div>
                </ng-container>

                <ng-template #mobilePhone_pattern>
                  <ng-container>
                    <div *ngIf="form.get('mobilePhone')?.hasError('pattern')">
                      Phone should start with '+' and contain only digits
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="form__field">
            <label for="nickName">Username</label>
            <div class="form__field-group">
              <input id="nickName" formControlName="nickName" #nickName placeholder="Username" />
              <div class="form__error" *ngIf="form.get('nickName')?.invalid && form.get('nickName')?.touched">
                <ng-container
                  *ngIf="
                    form.get('nickName')?.hasError('minlength') || form.get('nickName')?.hasError('maxlength');
                    else nickName_pattern
                  ">
                  <div>
                    Username should have
                    <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
                    characters. You currently typed
                    <mark>{{ (form.get('nickName')?.value).length }}</mark>
                  </div>
                </ng-container>

                <ng-template #nickName_pattern>
                  <ng-container>
                    <div *ngIf="form.get('nickName')?.hasError('pattern'); else nickName_not_unique">
                      Username can have latin characters, digits and symbols
                    </div>
                  </ng-container>
                </ng-template>

                <ng-template #nickName_not_unique>
                  <ng-container>
                    <div *ngIf="form.get('nickName')?.hasError('notUnique')">This username is already taken</div>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="form__field with-border connect-wrapper">
            <h3>Connect social media</h3>
            <div class="connect">
              <div class="connect-soc-name"><img src="assets/icons/discord-icon.png" alt="" /> Discord</div>
              <div class="connect-user">
                <ng-container *ngIf="(userService.userInfo$ | async)?.discordId">
                  <img
                    [src]="(userService.userInfo$ | async)?.discordAvatarUrl"
                    onError="this.src = 'assets/icons/default-user-image.png'"
                    alt="" />
                  <span class="connect-user-name">{{ (userService.userInfo$ | async)?.discordUsername }}</span>
                  <span class="connect-user-btn" (click)="disconnectToDiscord()">Disconnect</span>
                </ng-container>
                <span
                  *ngIf="!(userService.userInfo$ | async)?.discordId"
                  class="connect-user-btn"
                  (click)="connectToDiscord()"
                  >Connect</span
                >
              </div>
            </div>
          </div>

          <div class="form__field with-border">
            <label>Change Password</label>
            <app-button
              class="form__change-password"
              [buttonData]="changePasswordButton"
              [buttonType]="'button'"
              (click)="changePassword($event)"></app-button>
          </div>
          <div class="form__field with-border">
            <app-button class="form__button" [buttonData]="saveButton" [attr.disabled]="!form.valid"></app-button>
          </div>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab === 1">
      <div class="fan-passport" *ngIf="activeTab === 1">
        <h3 class="fan-passport__title">Digital Fan Passport</h3>
        <div class="fan-passport__nft-items" *ngIf="walletConnectService.usersNftsWithCount?.length; else emptyNfts">
          <div class="fan-passport__nft-item" *ngFor="let nft of walletConnectService.usersNftsWithCount">
            <img class="fan-passport__nft-item__image" [src]="nft.nftData.imageUrl" alt="DGA" />
            <span class="fan-passport__nft-item__name">{{ nft.nftData.name }}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <app-loader></app-loader>
  </div>
</div>

<ng-template #emptyNfts>
  <app-empty></app-empty>
</ng-template>
