import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { StoreShippingModalComponent } from '../store-shipping-modal/store-shipping-modal.component';
import { UserService } from '../../../services/user.service';
import { StoreService } from '../../../services/store.service';
import { Button, Product } from '../../../intarfaces';
import { BUTTON_SIZE, COLOSSEUM_TIERS } from '../../../enums';

interface ProductInfo {
  productName: string;
  materials: string[];
}

@Component({
  selector: 'store-product-item-modal',
  templateUrl: './store-product-item-modal.component.html',
  styleUrls: ['./store-product-item-modal.component.scss']
})
export class StoreProductItemModalComponent implements OnInit {
  @AutoUnsubscribe()
  userSubscriptionTypeSubs: Subscription | undefined;

  productItem: Product;
  productInfo: ProductInfo | null;
  activeVariantIds: { [productId: number]: number } = {};
  quantity: number = 1;
  currentVariantId: number = 1;
  buyNowBtn: Button = { name: 'BUY NOW', size: BUTTON_SIZE.LG };
  imageSrc: string | undefined;
  prices = [
    { type: COLOSSEUM_TIERS.SILVER, title: 'Silver price', active: false },
    { type: COLOSSEUM_TIERS.GOLD, title: 'Gold price', active: false },
    { type: COLOSSEUM_TIERS.PLATINUM, title: 'Platinum price', active: false }
  ];
  showFullText = false;

  constructor(
    public dialogRef: MatDialogRef<StoreProductItemModalComponent>,
    private dialogService: MatDialog,
    private userService: UserService,
    public storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) data: Product
  ) {
    this.productItem = data;
    this.imageSrc = data.image.src;
    this.productInfo = this.parseProductInfo(data.body_html);
  }

  ngOnInit() {
    this.storeService.updatePrices(this.prices);
  }

  goToPage(productName: string) {
    window.open(`https://shop.gaimingladiators.gg/products/${productName}`, '_blank');
  }

  selectImage(imageSrc: string) {
    this.imageSrc = imageSrc;
  }

  toggleActive(productId: number, variantId: number) {
    this.activeVariantIds[productId] = variantId;
    this.currentVariantId = variantId;
  }

  isActive(productId: number, variantId: number): boolean {
    return this.activeVariantIds[productId] === variantId;
  }

  increaseQuantity() {
    this.quantity++;
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  parseProductInfo(body_html: string): ProductInfo | null {
    const parser = new DOMParser();
    const doc = parser.parseFromString(body_html, 'text/html');
    const productNameElement = doc.querySelector('p > span');
    const productName = productNameElement?.textContent?.trim() || '';
    const materialElements = doc.querySelectorAll('ul li span');
    const materials: string[] = [];
    materialElements.forEach((element) => {
      const material = element.textContent?.trim();
      if (material) {
        materials.push(material);
      }
    });

    if (productName && materials.length > 0) {
      return {
        productName,
        materials
      };
    }

    return null;
  }

  buyNow() {
    const orderData = { quantity: this.quantity, variantId: this.currentVariantId };
    this.closeModal();
    this.dialogService.open(StoreShippingModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-form-edit'],
      data: orderData
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
