import { Component, Input } from '@angular/core';
import { SocialLinks } from '../../intarfaces';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent {
  @Input() socialLinks: SocialLinks = {} as SocialLinks;

  constructor() {}

  openExternalLink(link: string) {
    window.open(link, '_blank');
  }
}
