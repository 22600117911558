<div class="shipping">
  <div class="header">
    <h2>Provide your shipping information</h2>
    <span class="icon-Close close-icon" title="Close" (click)="close()"></span>
  </div>
  <div class="modal-content">
    <form class="form" [formGroup]="form">
      <div class="form__fields">
        <label for="firstName">
          First Name
          <input id="firstName" class="form__field" formControlName="firstName" placeholder="First Name" />
          <ng-container *ngIf="form.get('firstName')?.invalid && form.get('firstName')?.touched">
            <span class="form__field__error" *ngIf="form.get('firstName')?.hasError('required')">
              First Name should be present
            </span>

            <ng-container
              *ngIf="
                form.get('firstName')?.hasError('minlength') || form.get('firstName')?.hasError('maxlength');
                else firstName_pattern
              ">
              <span class="form__field__error">
                First Name should have
                <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
                characters. You currently typed
                <mark>{{ form.get('firstName')?.value.length }}</mark>
              </span>
            </ng-container>

            <ng-template #firstName_pattern>
              <ng-container>
                <span class="form__field__error" *ngIf="form.get('firstName')?.hasError('pattern')">
                  First Name should have only latin characters, hyphen and space
                </span>
              </ng-container>
            </ng-template>
          </ng-container>
        </label>

        <label for="lastName">
          Last Name
          <input id="lastName" class="form__field" formControlName="lastName" placeholder="Last Name" />
          <ng-container *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched">
            <span class="form__field__error" *ngIf="form.get('lastName')?.hasError('required')" \>
              Last Name should be present
            </span>
            <ng-container
              *ngIf="
                form.get('lastName')?.hasError('minlength') || form.get('lastName')?.hasError('maxlength');
                else lastName_pattern
              ">
              <span class="form__field__error">
                Last Name should have
                <mark>{{ nameMinLength }} - {{ nameMaxLength }}</mark>
                characters. You currently typed
                <mark>{{ form.get('lastName')?.value.length }}</mark>
              </span>
            </ng-container>

            <ng-template #lastName_pattern>
              <ng-container>
                <span class="form__field__error" *ngIf="form.get('lastName')?.hasError('pattern')">
                  Last Name should have only latin characters, hyphen and space
                </span>
              </ng-container>
            </ng-template>
          </ng-container>
        </label>
      </div>

      <label for="email">
        Email
        <input id="email" class="form__field" formControlName="email" placeholder="Email" />
        <ng-container *ngIf="form.get('email')?.invalid && form.get('email')?.touched">
          <span class="form__field__error" *ngIf="form.get('email')?.hasError('required'); else email_pattern">
            Email should be present
          </span>
          <ng-template #email_pattern>
            <ng-container>
              <span class="form__field__error" *ngIf="form.get('email')?.hasError('pattern')">
                Valid email address required
              </span>
            </ng-container>
          </ng-template>
        </ng-container>
      </label>

      <label for="mobilePhone">
        Phone Number
        <input
          id="mobilePhone"
          class="form__field"
          formControlName="mobilePhone"
          placeholder="Phone Number"
          (keypress)="additionalService.allowOnlyNumbers($event)"
          (keyup)="onKeyup($any($event.target).value)"
          (focus)="onMobilePhoneClick()"
          (click)="onMobilePhoneClick()" />
        <ng-container *ngIf="form.get('mobilePhone')?.invalid && form.get('mobilePhone')?.touched">
          <ng-container
            *ngIf="
              form.get('mobilePhone')?.hasError('minlength') || form.get('mobilePhone')?.hasError('maxlength');
              else mobilePhone_pattern
            ">
            <span class="form__field__error">
              Phone Number should have
              <mark>{{ phoneMinLength - 1 }} - {{ phoneMaxLength - 1 }}</mark>
              characters. You currently typed
              <mark>{{ form.get('mobilePhone')?.value?.length - 1 }}</mark>
            </span>
          </ng-container>

          <ng-template #mobilePhone_pattern>
            <ng-container>
              <span class="form__field__error" *ngIf="form.get('mobilePhone')?.hasError('pattern')">
                Phone should start with '+' and contain only digits
              </span>
            </ng-container>
          </ng-template>
        </ng-container>
      </label>

      <div class="form__fields">
        <label for="country">
          Country
          <input id="country" class="form__field" formControlName="country" placeholder="Country" />
          <ng-container *ngIf="form.get('country')?.invalid && form.get('country')?.touched">
            <span class="form__field__error" *ngIf="form.get('country')?.hasError('required'); else country_pattern">
              Country should be present
            </span>
            <ng-template #country_pattern>
              <ng-container>
                <span class="form__field__error" *ngIf="form.get('country')?.hasError('pattern')">
                  Country should have only latin characters, hyphen and space
                </span>
              </ng-container>
            </ng-template>
          </ng-container>
        </label>

        <label for="state">
          State / Region
          <input id="state" class="form__field" formControlName="state" placeholder="State / Province / Region" />
          <ng-container *ngIf="form.get('state')?.invalid && form.get('state')?.touched">
            <span class="form__field__error" *ngIf="form.get('state')?.hasError('required'); else state_pattern">
              State / Province / Region should be present
            </span>
            <ng-template #state_pattern>
              <ng-container>
                <span class="form__field__error" *ngIf="form.get('state')?.hasError('pattern')">
                  State / Province / Region should have only latin characters, hyphen and space
                </span>
              </ng-container>
            </ng-template>
          </ng-container>
        </label>
      </div>

      <div class="form__fields">
        <label for="zipCode">
          Postal Code
          <input id="zipCode" class="form__field" formControlName="zipCode" placeholder="Zip / Postal Code" />
          <ng-container *ngIf="form.get('zipCode')?.invalid && form.get('zipCode')?.touched">
            <span class="form__field__error" *ngIf="form.get('zipCode')?.hasError('required'); else zipCode_pattern">
              Postal Code should be present
            </span>
            <ng-template #zipCode_pattern>
              <ng-container>
                <span class="form__field__error" *ngIf="form.get('zipCode')?.hasError('pattern')">
                  Postal Code should contain only digits
                </span>
              </ng-container>
            </ng-template>
          </ng-container>
        </label>

        <label for="city">
          City
          <input id="city" class="form__field" formControlName="city" placeholder="City" />
          <ng-container *ngIf="form.get('city')?.invalid && form.get('city')?.touched">
            <span class="form__field__error" *ngIf="form.get('city')?.hasError('required'); else city_pattern">
              City should be present
            </span>
            <ng-template #city_pattern>
              <ng-container>
                <span class="form__field__error" *ngIf="form.get('city')?.hasError('pattern')">
                  City should have only latin characters, hyphen and space
                </span>
              </ng-container>
            </ng-template>
          </ng-container>
        </label>
      </div>

      <label for="address">
        Address
        <input id="address" class="form__field" formControlName="address" placeholder="Address" />
        <ng-container *ngIf="form.get('address')?.invalid && form.get('address')?.touched">
          <span class="form__field__error" *ngIf="form.get('address')?.hasError('required'); else address_pattern">
            Address should be present </span
          ><ng-template #address_pattern>
            <ng-container>
              <span class="form__field__error" *ngIf="form.get('address')?.hasError('pattern')">
                Address should have only latin characters, digits, comma, point, hyphen and space
              </span>
            </ng-container>
          </ng-template>
        </ng-container>
      </label>

      <app-button (click)="onSubmit()" [attr.disabled]="form.invalid" [buttonData]="confirmBtn"></app-button>
    </form>
  </div>
</div>
