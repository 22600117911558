import { Component, OnInit, Inject, AfterViewInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ScrollService } from '../../../services/scroll.service';
import { ContentService } from '../../../services/content.service';
import { StoriesService } from '../../../services/stories.service';
import { StoriesCommentsListComponent } from '../../stories-comments-list/stories-comments-list.component';
import { StoriesData, ReactionsListData, CommentsData } from '../../../intarfaces';
import { POPOVER_TYPE, SORTING_ORDER } from '../../../../shared/enums';

@Component({
  selector: 'stories-modal',
  templateUrl: './stories-modal.component.html',
  styleUrls: ['./stories-modal.component.scss']
})
export class StoriesModalComponent implements OnInit, AfterViewInit {
  @AutoUnsubscribe()
  private storiesSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private reactionsSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private detailsSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private storiesUpdateSubs: Subscription = {} as Subscription;

  @ViewChild(StoriesCommentsListComponent) commentsListComponent!: StoriesCommentsListComponent;

  private timeOut!: ReturnType<typeof setTimeout>;
  currentItem: StoriesData | null = null;
  isPrevBtnVisible: boolean = true;
  isNextBtnVisible: boolean = true;
  mediaVideoUrl: SafeResourceUrl | null = null;

  reactions: ReactionsListData[] = [];
  comments: CommentsData[] = [];
  popoverListPosition: POPOVER_TYPE = POPOVER_TYPE.RIGHT;

  private scrollToPreviewSubject = new Subject<number>();
  private debounceTimeMs = 200;

  isCommentsVisible: boolean = false;
  commentsPosition: {} = {};
  commentsOrder: SORTING_ORDER = SORTING_ORDER.DESC;

  @ViewChild('modalContainer') modalContainerRef!: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<StoriesModalComponent>,
    private scrollService: ScrollService,
    public contentService: ContentService,
    public storiesService: StoriesService,
    private sanitizer: DomSanitizer,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public currentIndex: number
  ) {}

  ngOnInit(): void {
    this.storiesSubs = this.storiesService.stories$.subscribe({
      next: (response) => {
        if (response) {
          this.isNextBtnVisible = this.currentIndex == response.length - 1 ? false : true;
          this.setCurrentItem(this.currentIndex);
        }
      }
    });

    this.scrollToPreviewSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((index) => {
      document.getElementById('imagePreview' + index)?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  onAuthorsSelectionChange(event: MatSelectionListChange) {
    this.currentIndex = 0;
    this.storiesService.selectedAuthors = event.source.selectedOptions.selected.map((option) => option.value.id);
    this.storiesService.params.page = 1;
    this.storiesService.getData(this.storiesService.params, { ids: this.storiesService.selectedAuthors }, true);
  }

  onChangeSortingOrder(event: MatSelectChange) {
    this.currentIndex = 0;
    this.storiesService.params.sort = event.value;
    this.storiesService.params.page = 1;
    this.storiesService.getData(this.storiesService.params, { ids: this.storiesService.selectedAuthors }, true);
  }

  onSelectReaction(reaction: ReactionsListData) {
    this.reactionsSubs = this.storiesService.handleStoriesReactionRequest(this.currentItem!.id, reaction.name).subscribe();
  }

  ngAfterViewInit() {
    this.timeOut = setTimeout(() => {
      this.scrollToPreviewSubject.next(this.currentIndex);
    }, 1000);
  }

  onPrevClick() {
    const index = this.currentIndex - 1;
    if (this.storiesService.stories$.getValue()![index]) {
      this.setCurrentItem(index);
    } else {
      this.scrollToPreviewSubject.next(this.currentIndex);
    }
  }

  onNextClick() {
    const index = this.currentIndex + 1;
    if (this.storiesService.stories$.getValue()![index]) {
      this.setCurrentItem(index);
    } else {
      this.scrollToPreviewSubject.next(this.currentIndex);
    }
  }

  selectImage(index: number) {
    this.setCurrentItem(index);
  }

  setCurrentItem(index: number) {
    this.currentIndex = index;
    this.reactions = [];
    this.comments = [];
    if (this.storiesService.stories$.getValue() && this.storiesService.stories$.getValue()![index]) {
      this.currentItem = this.storiesService.stories$.getValue()![index];
      if (this.currentItem.videoUrl) {
        this.mediaVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentItem.videoUrl);
      } else {
        this.mediaVideoUrl = null;
      }
      this.isPrevBtnVisible = index == 0 ? false : true;
      this.isNextBtnVisible = index == this.storiesService.stories$.getValue()!.length - 1 ? false : true;
      this.scrollToPreviewSubject.next(this.currentIndex);

      setTimeout(() => this.setCommentsPosition(), 0);

      this.detailsSubs = this.storiesService
        .getStoriesDetailsRequest(this.currentItem.id, this.commentsOrder)
        .subscribe({
          next: (res) => {
            if (res.success && res.data) {
              this.reactions = res.data.reactions;
              this.comments = res.data.comments;
              if (this.isCommentsVisible) {
                this.commentsListComponent.scrollToBottom();
              }
            }
          }
        });

      if (!this.currentItem.viewed && this.contentService.isContentVisible(this.currentItem.tier)) {
        this.storiesUpdateSubs = this.storiesService.updateStoriesRequest(this.currentItem.id).subscribe({
          next: (res) => {
            if (res.success) {
              this.currentItem!.viewed = true;
            }
          }
        });
      }
    } else {
      this.currentItem = null;
    }
  }

  goToPackages() {
    this.dialogRef.close();
    this.scrollService.scrollTo('packagesSection');
    this.router.navigateByUrl('/');
  }

  openCommentsPopup() {
    this.isCommentsVisible = !this.isCommentsVisible;
    this.setCommentsPosition();
  }

  setCommentsPosition() {
    const modalWidth = this.modalContainerRef.nativeElement.offsetWidth;
    const modalHeight = this.modalContainerRef.nativeElement.offsetHeight;

    const leftOffset = (window.innerWidth - modalWidth) / 2;
    const rect = this.modalContainerRef.nativeElement.getBoundingClientRect();
    const topOffset = rect.top + window.scrollY;

    const styles = {
      width: leftOffset - 20 + 'px',
      height: modalHeight + 'px',
      top: topOffset < 0 ? 0 : topOffset + 'px',
      left: leftOffset + modalWidth + 5 + 'px'
    };

    this.commentsPosition = styles;
  }

  onChangeCommentsSortingOrder(event: MatSelectChange) {
    this.commentsOrder = event.value;
    this.detailsSubs = this.storiesService
      .getStoriesDetailsRequest(this.currentItem!.id, this.commentsOrder)
      .subscribe({
        next: (res) => {
          if (res.success && res.data) {
            this.comments = res.data.comments;
            if (this.isCommentsVisible) {
              this.commentsListComponent.scrollToBottom();
            }
          }
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setCommentsPosition();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.onPrevClick();
    }
    if (event.key === 'ArrowRight') {
      this.onNextClick();
    }
  }
}
