import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TurnstileService {
  private widgetId?: string;
  private tokenPromiseResolve!: (value: string | PromiseLike<string>) => void;

  turnstileToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  /**
   * Initializes the Turnstile captcha.
   *
   * @param elRef - The ElementRef of the HTML element where the captcha will be rendered.
   * @param tokenEmitter - EventEmitter for emitting the Turnstile token.
   * @param expired - EventEmitter for handling expiration events.
   * @param error - EventEmitter for handling error events.
   */
  initTurnstileCaptcha(
    elRef: ElementRef,
    tokenEmitter: EventEmitter<string>,
    expired: EventEmitter<void>,
    error: EventEmitter<void>
  ) {
    try {
      turnstile.ready(() => {
        this.widgetId = turnstile.render(elRef.nativeElement.querySelector('#turnstile-captcha'), {
          sitekey: environment.cloudflareTurnstileSiteKey,
          callback: (token: string) => {
            tokenEmitter.emit(token);
            if (this.tokenPromiseResolve) {
              this.tokenPromiseResolve(token);
            }
          },
          'expired-callback': () => expired.emit(),
          'error-callback': (errorMsg: string) => {
            console.error('Turnstile error:', errorMsg);
            error.emit();
          },
          'refresh-expired': 'auto'
        });
      });
    } catch (initializationError) {
      console.error('Error initializing Turnstile widget:', initializationError);
      error.emit();
    }
  }

  /**
   * Resets and re-executes the Turnstile widget to obtain a new token.
   *
   * @returns {Promise<string>} - A promise that resolves with the new token.
   */
  refreshToken(): Promise<string> {
    if (this.widgetId) {
      turnstile.reset(this.widgetId);
      return new Promise((resolve) => {
        this.tokenPromiseResolve = resolve; // Sets up token promise resolution
      });
    }
    return Promise.reject('Widget ID is not defined');
  }

  /**
   * Resets the Turnstile widget (useful for cleanup).
   */
  destroyCaptcha(): void {
    if (this.widgetId) {
      turnstile.reset(this.widgetId);
      this.widgetId = undefined;
      this.turnstileToken$.next(null);
    }
  }

  /**
   * Handles the case when the captcha expires by refreshing the token.
   */
  handleExpired(): void {
    console.log('Captcha expired');
    this.refreshToken().catch((error: any) => {
      console.error('Error refreshing Turnstile token:', error);
    });
  }

  /**
   * Handles captcha errors (logs an error message).
   *
   * @param error - The error message.
   */
  handleError(error: unknown) {
    console.error('Captcha error:', error);
  }

  /**
   * Handles the received Turnstile token (logs it and updates the BehaviorSubject).
   *
   * @param token - The Turnstile token.
   */
  handleToken(token: string) {
    this.turnstileToken$.next(token);
  }
}
