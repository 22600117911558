export const reactionsList = [
  'trophy',
  'crossed_swords',
  'smile',
  'rolling_on_the_floor_laughing',
  'sunglasses',
  'star-struck',
  'flushed',
  'hot_face',
  'cold_face',
  'heart',
  '100',
  '+1',
  'popcorn',
  'muscle'
];
