import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ReactionsListData } from '../../intarfaces';
import { POPOVER_TYPE } from '../../enums';

@Component({
  selector: 'app-reactions-list',
  templateUrl: './reactions-list.component.html',
  styleUrls: ['./reactions-list.component.scss'],
})
export class ReactionsListComponent implements OnInit {
  @Output() selectReactionEvent = new EventEmitter<ReactionsListData>();
  @Input() reactions: ReactionsListData[] = [];
  @Input() position: POPOVER_TYPE = POPOVER_TYPE.CENTER;

  reactionSize: number = 23;
  reactionSet: Emoji['set'] = 'google';
  isReactionsPopoverVisible: boolean = false;
  sortedReactions: ReactionsListData[] = [];

  constructor() {}

  ngOnInit(): void {
    this.sortedReactions = this.sortReactions([...this.reactions]);
  }

  showReactionsPopover() {
    this.isReactionsPopoverVisible = !this.isReactionsPopoverVisible;
  }

  closeReactionsPopover() {
    this.isReactionsPopoverVisible = false;
  }

  onSelectReaction(item: ReactionsListData) {
    this.closeReactionsPopover();
    item.selected = !item.selected;
    if (item.selected) {
      item.count++;
    } else {
      item.count--;
    }
    this.selectReactionEvent.emit(item);
    this.sortedReactions = this.sortReactions(this.sortedReactions);
  }

  sortReactions(reactions: ReactionsListData[]) {
    return reactions.sort((a, b) => b.count - a.count);
  }
}
