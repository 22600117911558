import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { InfographicsModalComponent } from '../modals/infographics-modal/infographics-modal.component';
import { InfographicsSlider } from '../../intarfaces';

@Component({
  selector: 'app-infographics',
  templateUrl: './infographics.component.html',
  styleUrls: ['./infographics.component.scss']
})
export class InfographicsComponent {
  @Input() infographics!: InfographicsSlider;

  customOptions: OwlOptions = {
    items: 3,
    nav: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 12,
    navSpeed: 500,
    navText: ['<span class="icon-Arrow-Left"></span>', '<span class="icon-Arrow-Right"></span>'],
    startPosition: 0,
    responsive: {
      // it's slider container width, not the screen width
      0: {
        items: 1,
        nav: true
      },
      400: {
        items: 2,
        nav: true
      },
      650: {
        items: 3,
        nav: true
      }
    }
  };

  constructor(private dialogService: MatDialog) {}

  openPopup(index: number) {
    this.dialogService.open(InfographicsModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-image-preview'],
      data: { infographics: this.infographics, index: index }
    });
  }
}
