<ul>
  <li
    *ngIf="socialLinks.telegram"
    (click)="openExternalLink(socialLinks.telegram)"
    [tooltip]="'Telegram'"
    [tooltipPosition]="'top'">
    <span class="sprite sprite-telegram"></span>
  </li>
  <li *ngIf="socialLinks.x" (click)="openExternalLink(socialLinks.x)" [tooltip]="'X'" [tooltipPosition]="'top'">
    <span class="sprite sprite-x"></span>
  </li>
  <li
    *ngIf="socialLinks.instagram"
    (click)="openExternalLink(socialLinks.instagram)"
    [tooltip]="'Instagram'"
    [tooltipPosition]="'top'">
    <span class="sprite sprite-instagram"></span>
  </li>
  <li
    *ngIf="socialLinks.twitch"
    (click)="openExternalLink(socialLinks.twitch)"
    [tooltip]="'Twitch'"
    [tooltipPosition]="'top'">
    <span class="sprite sprite-twitch"></span>
  </li>
  <li
    *ngIf="socialLinks.discord"
    (click)="openExternalLink(socialLinks.discord)"
    [tooltip]="'Discord'"
    [tooltipPosition]="'top'">
    <span class="sprite sprite-discord"></span>
  </li>
  <li
    *ngIf="socialLinks.youtube"
    (click)="openExternalLink(socialLinks.youtube)"
    [tooltip]="'YouTube'"
    [tooltipPosition]="'top'">
    <span class="sprite sprite-youtube"></span>
  </li>
</ul>
