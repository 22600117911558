import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { TwitchService } from '../../shared/services/twitch.service';
import { AdditionalService } from '../../shared/services/additional.service';

@Component({
  selector: 'streams-page',
  templateUrl: './streams-page.component.html',
  styleUrls: ['./streams-page.component.scss']
})
export class StreamsPageComponent implements OnInit, OnDestroy {
  @AutoUnsubscribe()
  private streamersSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private streamingStatusSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private pendingRewardSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private withdrawalOptionsSub: Subscription = {} as Subscription;

  private readonly UPDATE_CHANNEL_STATUS_TIME: number = 1000 * 30;
  private interval: ReturnType<typeof setInterval> = {} as ReturnType<typeof setInterval>;

  constructor(
    private additionalService: AdditionalService,
    private twitchService: TwitchService
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.pendingRewardSub = this.twitchService.calculatePendingReward();
    this.withdrawalOptionsSub = this.twitchService.getWithdrawalOptions();
    this.streamersSubs = this.twitchService.getStreamersRequest().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.twitchService.channelsList$.next(response.data);
          this.streamingStatusSubs = this.twitchService.updateStreamingStatus();
          this.interval = setInterval(() => {
            this.streamingStatusSubs = this.twitchService.updateStreamingStatus();
          }, this.UPDATE_CHANNEL_STATUS_TIME);
        }
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
