import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { AdditionalService } from '../../../services/additional.service';
import { Button, ChangePasswordRequest, ChangePasswordData } from '../../../intarfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  readonly passwordMinLength: number = 8;
  readonly passwordMaxLength: number = 50;

  readonly submitBtn: Button = { name: 'Change password', size: BUTTON_SIZE.LG };

  form: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.maxLength(this.passwordMaxLength),
      Validators.pattern('^(?!\\s)(?!.*\\s$)(?!.*?(\\s)).*$'),
      this.additionalService.regexValidator(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\W]).*$'), {
        complexity: true
      })
    ])
  });

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    private toastrService: ToastrService,
    public authService: AuthService,
    public additionalService: AdditionalService,
    @Inject(MAT_DIALOG_DATA)
    public data: ChangePasswordData
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
      const changePasswordRequest: ChangePasswordRequest = {
        oktaId: this.data.oktaId,
        oldPassword: this.form.value.oldPassword,
        newPassword: this.form.value.newPassword
      };

      this.authService.changePasswordRequest(changePasswordRequest).subscribe({
        next: (response) => {
          if (response.success) {
            this.toastrService.success('Password changed successfully!');
            this.closeModal();
            this.authService.openLoginPopup();
          }
        }
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
