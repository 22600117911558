import { Component, OnDestroy } from '@angular/core';
import { AdditionalService } from '../../shared/services/additional.service';

@Component({
  selector: 'faqs-page',
  templateUrl: './faqs-page.component.html',
  styleUrls: ['./faqs-page.component.scss']
})
export class FaqsPageComponent implements OnDestroy {
  constructor(private additionalService: AdditionalService) {
    document.body.className = 'home-page-template';
    this.additionalService.isHomePageTemplate$.next(true);
  }

  ngOnDestroy() {
    document.body.className = '';
    this.additionalService.isHomePageTemplate$.next(false);
  }
}
