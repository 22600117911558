import { Component, HostListener, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ContentService } from '../../services/content.service';
import { ArticlesService } from '../../services/articles.service';
import { ScrollService } from '../../services/scroll.service';
import { ArticleData, AuthorsFilter, SortingPageFilter } from '../../intarfaces';
import { SORTING_ORDER } from '../../enums';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  @AutoUnsubscribe()
  private articlesSubs: Subscription = {} as Subscription;

  @Input() playerId: number | null = null;

  articles$: BehaviorSubject<ArticleData[] | null> = new BehaviorSubject<ArticleData[] | null>(null);

  selectedAuthors: number[] = [];

  params: SortingPageFilter = {
    page: 1,
    size: 15,
    sort: SORTING_ORDER.DESC
  };
  totalPages: number = 0;
  columnGap: number = 15;

  loading: boolean = false;
  contentPage: boolean = false;

  constructor(
    private scrollService: ScrollService,
    public contentService: ContentService,
    public articlesService: ArticlesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contentPage = !this.playerId;
    this.getData(this.params, { ids: [] });
  }

  getData(params: SortingPageFilter, ids: AuthorsFilter, emptyList = false) {
    this.loading = true;
    if (this.contentPage) {
      this.articlesSubs = this.articlesService.getArticlesRequest(params, ids).subscribe((res) => {
        if (res.success && res.data) {
          if (emptyList) {
            this.articles$.next(res.data.articles);
          } else {
            const currentArticles = this.articles$.getValue() || [];
            this.articles$.next([...currentArticles, ...res.data.articles]);
          }
          this.totalPages = res.data.pagination.totalPages;
        }
        this.loading = false;
      });
    } else {
      this.articlesSubs = this.articlesService
        .getArticlesByPlayerIdRequest(this.playerId!, ids, this.params.sort)
        .subscribe((res) => {
          if (res.success && res.data) {
            this.articles$.next(res.data);
          }
          this.loading = false;
        });
    }
  }

  onAuthorsSelectionChange(event: MatSelectionListChange) {
    this.selectedAuthors = event.source.selectedOptions.selected.map((option) => option.value.id);
    this.params.page = 1;
    this.getData(this.params, { ids: this.selectedAuthors }, true);
  }

  onChangeSortingOrder(event: MatSelectChange) {
    this.params.sort = event.value;
    this.params.page = 1;
    this.getData(this.params, { ids: this.selectedAuthors }, true);
  }

  navigateTo(id: number) {
    this.router.navigateByUrl(`/article/${id}`);
  }

  openInNewTab(id: number) {
    window.open(`/article/${id}`, '_blank');
  }

  goToPackages() {
    this.scrollService.scrollTo('packagesSection');
    this.router.navigateByUrl('/');
  }

  loadMore() {
    this.params.page++;
    this.getData(this.params, { ids: this.selectedAuthors });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.contentPage && this.totalPages > this.params.page) {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (!this.loading) {
          this.loadMore();
        }
      }
    }
  }
}
