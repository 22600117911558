<div class="container">
  <div class="store">
    <h2 class="title">Store</h2>
    <div class="store__discount">
      <h3>You have got merch with discount</h3>
    </div>
    <h4 class="store__title">The best deals today</h4>
    <div class="store__products" *ngIf="products?.length">
      <div class="store__products-item" *ngFor="let product of products">
        <div class="store__products-item__sale">
          <img src="../../../assets/icons/packages/platinum.png" alt="Platinum" />
          Save 25%
        </div>

        <ng-container *ngIf="product && product.status === 'active'">
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let sliderItem of product.images; let i = index">
              <ng-template *ngIf="sliderItem.src" carouselSlide [id]="i.toString()">
                <img [src]="sliderItem.src" alt="Product" (click)="openProduct(product)" />
              </ng-template>
            </ng-container>
          </owl-carousel-o>

          <div class="store__products-item__description">
            <h6 class="store__products-item__title">{{ product.title }}</h6>
            <ul>
              <ng-container *ngFor="let price of prices">
                <li [ngClass]="{ active: price.active }">
                  <span class="title">{{ price.active ? 'Your&nbsp;' + price.title.toLowerCase() : price.title }}</span>
                  <span class="amount">
                    <img [src]="'assets/icons/packages/' + price.type.toLowerCase() + '.png'" [alt]="price.type" />
                    ${{ storeService.calculateNewPrice(product.variants[0].price, price.type) | number: '1.2-2' }}
                    <span *ngIf="!price.active" (click)="goToPage(product.handle)" class="icon icon-Arrow-Right"></span>
                  </span>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
