<div class="sorting">
  <mat-form-field class="dropdown-singleselect-label">
    <mat-label>Sort by:</mat-label>
    <mat-select
      [(value)]="selectedSorting"
      (selectionChange)="onChangeSortingOrder($event)"
      panelClass="dropdown-singleselect-list">
      <mat-option [value]="SORTING_ORDER.DESC">Newest to oldest</mat-option>
      <mat-option [value]="SORTING_ORDER.ASC">Oldest to newest</mat-option>
    </mat-select>
  </mat-form-field>
</div>
