import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { ScrollService } from '../../../shared/services/scroll.service';
import { ContentService } from '../../../shared/services/content.service';
import { VideosService } from '../../../shared/services/videos.service';
import { Button } from '../../../shared/intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../shared/enums';

@Component({
  selector: 'app-videos-list',
  templateUrl: './videos-list.component.html',
  styleUrls: ['./videos-list.component.scss']
})
export class VideosListComponent implements OnInit {
  readonly loadMoreBtn: Button = {
    name: 'Load more',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK
  };

  constructor(
    private router: Router,
    private scrollService: ScrollService,
    public contentService: ContentService,
    public videosService: VideosService
  ) {}

  ngOnInit(): void {}

  onAuthorsSelectionChange(event: MatSelectionListChange) {
    this.videosService.selectedAuthors = event.source.selectedOptions.selected.map((option) => option.value.id);
    this.videosService.params.page = 1;
    this.videosService.getData(this.videosService.params, { ids: this.videosService.selectedAuthors }, true);
  }

  onChangeSortingOrder(event: MatSelectChange) {
    this.videosService.params.sort = event.value;
    this.videosService.params.page = 1;
    this.videosService.getData(this.videosService.params, { ids: this.videosService.selectedAuthors }, true);
  }

  navigateTo(id: number) {
    this.router.navigateByUrl(`/videos/${id}`);
  }

  goToContent() {
    this.router.navigateByUrl(`/content`);
  }

  openInNewTab(id: number) {
    window.open(`/videos/${id}`, '_blank');
  }

  goToPackages() {
    this.scrollService.scrollTo('packagesSection');
    this.router.navigateByUrl('/');
  }

  loadMore() {
    this.videosService.params.page++;
    this.videosService.getData(this.videosService.params, { ids: this.videosService.selectedAuthors });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.videosService.totalPages > this.videosService.params.page) {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (!this.videosService.loading) {
          this.loadMore();
        }
      }
    }
  }
}
