import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Button } from '../../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../enums';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Output() confirmRequest: EventEmitter<any> = new EventEmitter<any>();

  readonly confirmBtn: Button = { name: 'Confirm', size: BUTTON_SIZE.LG };
  readonly closeBtn: Button = { name: 'Cancel', size: BUTTON_SIZE.LG, type: BUTTON_TYPE.DARK };

  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close();
    this.confirmRequest.emit();
  }
}
