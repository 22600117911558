<div class="claim-reward">
  <div class="header">
    <h2>Claim Reward</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>

  <div class="modal-content">
    <form [formGroup]="form">
      <label class="claim-reward__label">
        Wallet Address
        <input formControlName="wallet" class="claim-reward__field" #wallet placeholder="Wallet Address" />

        <div>
          <ng-container *ngIf="form.get('wallet')!.invalid && form.get('wallet')!.dirty">
            <span *ngIf="form.get('wallet')!.hasError('required')" class="claim-reward__field__error">
              Wallet Address should be present
            </span>

            <ng-container *ngIf="form.get('wallet')!.hasError('pattern')">
              <span class="claim-reward__field__error"> Enter valid wallet address </span>
            </ng-container>
          </ng-container>
        </div>
      </label>

      <app-button
        (click)="onSubmit()"
        [attr.disabled]="form.invalid"
        [buttonData]="submitBtn"></app-button>
    </form>
  </div>
</div>
