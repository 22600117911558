<button
  [ngClass]="[
    buttonData.type ? 'button_' + buttonData.type : 'button_primary',
    buttonData.size ? 'button_' + buttonData.size : 'button_md'
  ]"
  class="button"
  [type]="buttonType"
  [disabled]="disabled">
  <span *ngIf="buttonData.iconLeft" class="icon icon-left" [ngClass]="buttonData.iconLeft"></span>
  <span *ngIf="buttonData.name" class="text">{{ buttonData.name }}</span>
  <span *ngIf="buttonData.iconRight" class="icon icon-right" [ngClass]="buttonData.iconRight"></span>
  <img *ngIf="buttonData.img" [src]="'assets/icons/' + buttonData.img" class="image" alt="" />
</button>
