<mat-accordion [displayMode]="'flat'">
  <mat-expansion-panel id="item1" (afterExpand)="scrollToExpanded('item1')">
    <mat-expansion-panel-header>
      <mat-panel-title>About the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <h2>What is the Colosseum?</h2>
      <p>
        The Colosseum is the
        <a href="https://www.gaimingladiators.gg/" target="_blank">Gaimin Gladiators</a> Web3 Fan Loyalty Membership
        Program, built around the GAIMIN Fan Token, $GMRX. The Colosseum is an exclusive fan loyalty program designed
        for our most dedicated fans. It offers a unique set of features and benefits, providing an immersive experience
        that goes beyond traditional fan engagement.
      </p>
      <h2>Why did the Gaimin Gladiators (GG) launch the Colosseum?</h2>
      <p>
        Being a top esports team and a trendsetter in the space, GG launched the Colosseum in 2024 as a platform where
        the GG team connects deeper with its beloved fans and offers them premium features, exclusive benefits, and
        money-can’t-buy experiences. Also being an industry leader in innovation, GG has built the Colosseum to
        incorporate the cutting-edge modern technology of Web3.
      </p>
      <h2>What is the Technology behind the Colosseum?</h2>
      <p>
        The Colosseum is powered by Web3, Crypto & Blockchain technologies which let users interact with the platform
        freely and allow transactions to occur in a decentralized manner through the blockchain. The Colosseum also
        adopts cryptocurrency through its utility Fan Token, $GMRX which lets fans engage with our platform seamlessly
        while also earning rewards in the process.
      </p>
      <h2>What are Fan Tokens?</h2>
      <p>
        Fan tokens are a special category of cryptocurrencies that lets fans engage or perform specific activities with
        their favorite sports teams or players and access special perks and rewards.
      </p>
      <h2>What is $GMRX?</h2>
      <p>
        $GMRX is the GAIMIN Fan Token, the token that powers The Colosseum experience. $GMRX transcends traditional
        tokens; it's your key to premium features and benefits on The Colosseum. With your GMRX tokens, you can engage,
        donate, earn, and unlock the full spectrum of fan privileges on The Colosseum. You can get $GMRX from available
        exchanges (markets)
        <a href="https://coinmarketcap.com/currencies/gaimin/" target="_blank">here</a> and then send it to your Web 3
        wallet which should be connected to the Colosseum to access the full perks of the Colosseum. Since $GMRX is a
        BNB Smart Chain (BSC) token, you would also need some BNB for gas fees to perform on-chain transactions like
        sending tokens from your Web 3 wallet. BNB chain gas fees are very cheap and at the moment cost a few cents for
        transactions.
      </p>
      <h2>What are the Features & Benefits of the Colosseum?</h2>
      <p>Features (and Benefits) available on the Colosseum, now and in the near future include:</p>
      <ul>
        <li>
          Web3 Wallet Connection: Fans will be able to connect their Web3 wallets after signing up to interact on the
          platform with $GMRX tokens.
        </li>
        <li>
          Monetized Streaming: This feature lets fans donate and earn while streaming live content from the GG team,
          players, and selected external streamers.
        </li>
        <li>
          Fan Profile Area: Interactive fan profile page where fans can view and showcase their assets, and details
          inside the Colosseum. Fans can also connect their social profiles to create their unique identity on the
          Colosseum.
        </li>
        <li>
          Premium Content: This feature gives members access to a treasure trove of content from the Gaimin Gladiators.
          This content includes exclusive behind-the-scenes, extended version, and early access content across posts,
          and short and long-form videos.
        </li>
        <li>
          Tiered Fan Badges: Fans will be able to subscribe to any level of the Colosseum simply by locking up a
          specified amount of $GMRX tokens and unlocking special tiers on the Colosseum.
        </li>
        <li>
          Shop Section: Special mouthwatering discount deals on your GG swag purchases with up to unlimited discounts
          for premium members. In the future, premium fans could also be able to access special discount offers from
          partner (external) stores.
        </li>
        <li>
          Free POAP NFTs: Also known as Digital Proof of Attendance (DPAs). These FREE (soon tradeable) exclusive
          Digital Proof of Attendance (POAPs) can be claimed for attending Gaimin Gladiators events.
        </li>
        <li>
          Fan Engagement Area: The fan engagement area lets fans participate in engagement activities like voting,
          polls, quizzes, etc to earn points that rank them on a leaderboard. Top-ranking fans win exclusive rewards and
          money-can’t-buy experiences periodically.
        </li>
        <li>
          Rewards: The reward section shows you rewards and money-can’t-buy experiences available for you to claim or
          unlock with your $GMRX tokens.
        </li>
        <li>
          Fanverse: Fanverse is an interactive section that will let fans chat live with other fans and with the team,
          with fan badges identifying the tier of fandom.
        </li>
        <li>
          Auction: This is the marketplace for bidding on GG’s most prized assets and unique experiences. Soon, fans
          will also be able to sell their GG digital collectibles like POAPs on the platform or purchase assets from
          other fans.
        </li>
        <li>
          Player Profiles Area: This section lets fans explore the profiles of Gaimin Gladiators players and receive
          in-depth information about them exclusively only on the Colosseum. Also, fans will be able to access a catalog
          of all exclusive content from players on the Colosseum, like their interviews, podcasts, and many more.
        </li>
        <li>
          Referral program: Fans will be able to earn instant GMRX rewards for inviting their friends to experience the
          Colosseum too.
        </li>
        <li>
          Charity Feature: Gaimin Gladiators also partners with charity organizations to enable fans to donate to
          various charity causes all over the world.
        </li>
      </ul>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item2" (afterExpand)="scrollToExpanded('item2')">
    <mat-expansion-panel-header>
      <mat-panel-title>Web3 Wallets on the Colosseum?</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <h2>What is BNB Smart Chain (BSC) and how does it relate to $GMRX and the Colosseum?</h2>
      <p>
        The BNB Smart Chain (BSC) is a blockchain specifically designed for fast and affordable transactions. The GAIMIN
        Fan Token, $GMRX is built on this chain; more like a ledger where its transactions occur and are stored. The
        Colosseum utilizes $GMRX, a fan token built on the BSC, to provide unique experiences and benefits to fans. By
        using BSC, $GMRX transactions are intended to be faster and cheaper compared to other blockchain platforms,
        potentially making it easier for fans to have a better experience engaging with the Colosseum through $GMRX.
      </p>
      <h2>What is a Web3 wallet?</h2>
      <p>
        A Web3 wallet is a digital tool to store and manage crypto assets like $GMRX, letting you interact with apps
        like the Colosseum. Web3 wallets are 3rd party tools meaning that the Colosseum doesn’t own or control assets or
        transactions in/from your Web3 wallets.
      </p>
      <h2>What are Private Keys?</h2>
      <p>
        Your Web3 wallet's private key is like your crypto password. Keep it secret and secure to protect your $GMRX!
      </p>
      <h2>What is a gas fee?</h2>
      <p>
        Gas fees are small transaction fees paid to cover network costs on a blockchain. Since $GMRX is a token on the
        BSC network where its transactions occur, gas fees for its transactions are typically very cheap.
      </p>
      <h2>Must I have a Web3 wallet to interact with the Colosseum?</h2>
      <p>
        Yes. Since you’d be making transactions on the Colosseum like sending, receiving, and locking $GMRX, a BSC
        token, you need a Web3 wallet that is BEP-20 (BNB Smart Chain) compatible to fully interact with the Colosseum
        and utilize its features.
      </p>
      <h2>Are my tokens deposited to the Colosseum when I connect my Web3 wallet?</h2>
      <p>
        No. Your tokens are not deposited on the Colosseum just because you connected your wallet. Tokens only leave
        your Web3 wallet whenever you make transactions like donating tokens to creators or locking tokens; and whenever
        you are making these transactions, you get confirmation prompts (pop-ups) from your wallet asking you to confirm
        the transaction.
      </p>
      <h2>Are my $GMRX tokens and transactions safe on a Web3 wallet?</h2>
      <p>
        Web3 wallets offer more control and security over your tokens and transactions if you choose a reputable one and
        keep your private keys secure. With Web 3 wallets, you have control over your assets and transactions, as long
        as you don’t share your private keys with anyone.
      </p>
      <h2>Is it difficult to make use of Web3 wallets on the Colosseum?</h2>
      <p>
        No, using a Web3 wallet for transactions on the Colosseum isn’t difficult. Most BEP-20 (BNB Smart Chain) wallets
        are designed to be user-friendly. All you just need to do is to make sure your wallet is connected to the
        Colosseum while making the transaction and you will be sent a prompt (pop-up) whenever you need to confirm the
        transaction on your wallet.
      </p>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item3" (afterExpand)="scrollToExpanded('item3')">
    <mat-expansion-panel-header>
      <mat-panel-title>Activation & Subscription on the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <h2>Is the Colosseum Free?</h2>
      <p>
        The Colosseum is a “Freemium’ service, meaning that we have a free version as well as various premium paid
        versions. The free plan gives you access to basic features of the Colosseum, while higher premium packages give
        you access to more exclusive features and benefits. The premium packages come with an activation fee and
        subscription lock model.
      </p>
      <h2>How does Activation Fee work on the Colosseum?</h2>
      <p>
        The activation fee on the Colosseum is a one-time fee that you pay every year (365 days) to activate your
        account to be eligible for features and benefits on the Colosseum. However, your first year of receiving premium
        features and benefits on the Colosseum is FREE from this fee as you don’t pay the activation fee for the first
        365 days after joining the Colosseum on a premium membership.
      </p>
      <h2>How does Subscription Lock work on the Colosseum?</h2>
      <p>
        In addition to activating your plan every year, you need to be on a subscription plan. Subscription on the
        Colosseum works in a way that involves fans choosing their desired package of features & benefits and locking
        the specified amount of $GMRX to enjoy these perks during the subscription period. You keep getting the
        stipulated perks for that package as long as you are subscribed (lock tokens) in that package. What makes the
        Colosseum’s subscription mechanism special is that it isn’t a payment unlike most platforms; you only stake your
        $GMRX which you might already be holding as a passionate Gaimin Gladiators fan.
      </p>
      <h2>Do price fluctuations affect my locked tokens?</h2>
      <p>
        Yes. Your locked GMRX tokens are real tokens and still track the current price of GMRX tokens, so they will be
        worth their current value at real-time market price. However, this doesn’t affect your subscription status
        during the subscription period.
      </p>
      <h2>What happens if my staked $GMRX value falls below the dollar value of the subscription package?</h2>
      <p>
        For example, let’s say you subscribed for the Gold package where you locked $25 worth of GMRX at the start of
        your subscription period. If during your subscription period, your locked tokens fall under $25, you won’t be
        asked to lock more tokens as long as you are still under that subscription (the subscription period). However,
        if you cancel your subscription (by unlocking your tokens) at your GMRX worth $20 and want to resubscribe to the
        same Gold plan, you will need to stake more tokens since you will be required to stake $25 worth of GMRX at any
        time to subscribe to the Gold package.
      </p>
      <h2>What happens if my staked $GMRX value rises above the dollar value of the subscription package?</h2>
      <p>
        For this example, let’s still say you subscribed for the Gold package where you locked $25 worth of GMRX at the
        start of your subscription period. If during your subscription period, your locked tokens rise to above $25 in
        value, you won’t be automatically upgraded to a higher plan. However, if you upgrade to a higher package
        (Platinum), which you’d have to do manually, at a time when your $25 worth of GMRX is now worth $100, you won’t
        be required to deposit any more GMRX since your amount of GMRX is now worth the stipulated lock value for that
        package.
      </p>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item4" (afterExpand)="scrollToExpanded('item4')">
    <mat-expansion-panel-header>
      <mat-panel-title>Monetised Streaming on the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <h2>Is Monetized Streaming available to everyone?</h2>
      <p>
        Yes, monetized streaming is available to everyone, even free package holders; as long as you have an account on
        the Colosseum, you can donate and earn while streaming.
      </p>
      <h2>Am I restricted to streaming only official GG games on the Colosseum?</h2>
      <p>
        No. In the Colosseum, you also get access to stream other content aside from official GG games. You also get to
        stream content from GG players and other approved creators on our platform.
      </p>
      <h2>Is it mandatory to Donate to streamers (players and content creators) on the Colosseum?</h2>
      <p>
        No. It’s not mandatory to donate to streamers on the Colosseum. Donations on the Colosseum are free-will gifts
        to your favorite creators.
      </p>
      <h2>Do I have to do any other thing to start earning $GMRX for streaming (Watch-to-Earn)?</h2>
      <p>
        No. All you need to do is sign into your Colosseum account (Sign up if you haven’t), go to the “Streams” section
        of the Colosseum, and pick a stream of your choice. Your $GMRX earnings start accumulating immediately you start
        streaming and pause the moment you stop streaming.
      </p>
      <h2>Is there a minimum donation amount?</h2>
      <p>No, there is no minimum donation amount. You can donate any amount you want.</p>
      <h2>Is there a minimum withdrawal amount for my streaming earnings?</h2>
      <p>
        Yes. To be able to withdraw the funds from your Watch-to-Earn rewards, you need to have accumulated at least 400
        GMRX.
      </p>
      <h2>Do I pay for the gas fees on my Donations?</h2>
      <p>
        Yes, you have to pay a gas fee whenever you initiate an on-chain transaction; this includes your donations on
        the Colosseum which is basically you sending tokens out from your wallet to another wallet. However, gas fees
        are typically cheap on the BSC network and cost a few cents at the moment.
      </p>
      <h2>Do I pay for the gas fees on my earnings Withdrawal?</h2>
      <p>
        No. Gas fees are usually deducted from the initiating wallets, so you won’t be paying gas fees on your
        withdrawals from the Colosseum.
      </p>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item5" (afterExpand)="scrollToExpanded('item5')">
    <mat-expansion-panel-header>
      <mat-panel-title>Premium Content on the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <h2>Must I be a Premium member to access all the content on the Colosseum?</h2>
      <p>
        Yes, content on the Colosseum is exclusive to premium members. So you need to be a premium member, i.e.
        subscribed to the Silver package or above, in order to access all Colosseum content.
      </p>
      <h2>What types of content do I get on the Colosseum?</h2>
      <p>
        In the Colosseum, you get access to various kinds of content about the Gaimin Gladiators. From detailed
        documentaries about our tournaments to podcasts, interviews, Highlight moments, behind-the-scenes footage,
        day-in-life clips, detailed information about some of GG’s most confidential announcements, and many more. These
        content are published under 3 categories in the Colosseum; Moments for short-form videos, Videos for long video
        content, and Posts for text-based content with capturing images.
      </p>
      <h2>How frequently do I get content on the Colosseum?</h2>
      <p>
        The content type varies, and so does the duration of production. However, in general, new content is uploaded on
        the Colosseum on a daily basis.
      </p>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item6" (afterExpand)="scrollToExpanded('item6')">
    <mat-expansion-panel-header>
      <mat-panel-title>Digital Proof of Attendance (POAP NFTs) on the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <h2>How can I get GG’s Digital Proof of Attendance, also known as DPAs?</h2>
      <p>
        Aside from special occasions where we might choose to airdrop DPAs to fans for performing or participating in
        certain activities within a period, you mainly get access to DPAs at live GG events. As the name implies,
        Digital Proof of Attendance proves that you attended an event; so to get it, you need to be at the event to
        claim them.
      </p>
      <h2>How can I claim my Digital Proof of Attendance?</h2>
      <p>
        First, it has to be a DPA-available GG event; we would usually announce this on the Colosseum, so make sure to
        stay tuned for updates. Next, you need to be present at the event. Then at the end of the event, locate a GG
        staff and ask about your DPA. You will be directed on how to claim yours live at the event; this typically
        involves you scanning the QR code to claim your DPA for the event.
      </p>
      <h2>What happens after I claim my Digital Proof of Attendance?</h2>
      <p>
        After claiming your DPA at the event, please note that you need to connect your wallet to be able to receive
        your DPA. Your DPA will be airdropped to your Colosseum account after the event. You can then view your new
        collectibles in your “Fan Profile” area and even showcase them to other fans. In the future, fans could be able
        to trade their DPAs for tokens and other incentives.
      </p>
      <h2>Why do I need to claim as many DPAs as I can?</h2>
      <p>
        There are many reasons why you should collect GG Digital Proof of Attendance, the obvious one being that they
        are 100% FREE. Other reasons include that they could be tradeable soon so you be able to exchange your DPAs for
        real tokens. Also, there could be opportunities for fans with more DPAs to unlock more perks or bigger reward
        drops in the future. The potential in the GG DPAs is endless, considering that you get them for FREE.
      </p>
    </div>
  </mat-expansion-panel>
</mat-accordion>
