import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AdditionalService } from '../../shared/services/additional.service';
import { ScrollService } from '../../shared/services/scroll.service';
import { AuthService } from '../../shared/services/auth.service';
import { Button } from '../../shared/intarfaces';
import { BUTTON_SIZE } from '../../shared/enums';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @AutoUnsubscribe()
  private scrollSubs: Subscription = {} as Subscription;

  readonly memberBtn: Button = {
    name: 'Become a member',
    size: BUTTON_SIZE.XL
  };

  constructor(
    private additionalService: AdditionalService,
    private scrollService: ScrollService,
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {
    document.body.className = 'home-page-template';
    this.additionalService.isHomePageTemplate$.next(true);
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        if (route.url == '/signin' || route.url == '/signup') {
          if (!this.authService.isLoggedIn$.getValue()) {
            if (route.url == '/signin') {
              this.authService.openLoginPopup();
            } else {
              this.authService.openLoginPopup(true);
            }
          } else {
            this.location.replaceState('/');
          }
        }
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.scrollSubs = this.scrollService.scrollSubject$.subscribe((section) => {
      setTimeout(() => {
        if (section) {
          const element = document.getElementById(section);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            this.scrollService.scrollSubject$.next(null);
          }
        }
      }, 100);
    });
  }

  goToPackages() {
    if (!this.authService.isLoggedIn$.getValue()) {
      this.authService.openLoginPopup(true);
    } else {
      this.scrollService.scrollTo('silverPackage');
    }
  }

  ngOnDestroy() {
    document.body.className = '';
    this.additionalService.isHomePageTemplate$.next(false);
  }
}
