import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AdditionalService } from '../../shared/services/additional.service';
import { ContentService } from '../../shared/services/content.service';
import { PlayersListData, GamesData } from '../../shared/intarfaces';

@Component({
  selector: 'players-page',
  templateUrl: './players-page.component.html',
  styleUrls: ['./players-page.component.scss']
})
export class PlayersPageComponent implements OnInit {
  @AutoUnsubscribe()
  private playersSubs: Subscription = {} as Subscription;

  constructor(
    private additionalService: AdditionalService,
    private contentService: ContentService
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.playersSubs = this.contentService.getPlayersRequest().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.contentService.playersList$.next(response.data);

          const groupedPlayers = response.data.reduce(
            (acc, player) => {
              if (player.game) {
                const gameId = player.game.id;
                if (!acc[gameId]) {
                  acc[gameId] = { game: player.game, players: [] };
                }
                acc[gameId].players.push(player);
              }
              return acc;
            },
            {} as Record<number, { game: GamesData; players: PlayersListData[] }>
          );

          this.contentService.playersListByGame$.next(groupedPlayers);
        }
      }
    });
  }
}
