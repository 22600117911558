<div class="content">
  <img *ngIf="content.image" [src]="content.image" [alt]="content.title" />
  <h1>{{ content.title }}</h1>
  <p *ngIf="content.description">{{ content.description }}</p>
  <div class="content__unavailable">
    <span *ngIf="!isAccessRestricted">
      Full content is available only to authorized users. Please sign in to access the complete page.
    </span>
    <span *ngIf="isAccessRestricted">
      The complete content is available only to members with a higher subscription level.
    </span>
  </div>
</div>
