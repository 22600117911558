import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { SHOW_LOADER } from '../../interceptors/loader.interceptor';
import {
  Channel,
  ChannelStatus,
  Response,
  StartWatchingSession,
  StreamingClaimReward,
  StreamingData,
  UpdateSessionData,
  WithdrawalOptions,
  WithdrawalOptionsData
} from '../intarfaces';
import { environment } from '../../../environments/environment';
import { CHAINS } from '../enums';
import { TurnstileService } from './turnstile.service';

@Injectable({
  providedIn: 'root'
})
export class TwitchService {
  private readonly UPDATE_INTERVAL: number = 1000;

  channelsList$: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
  channelsWithStatus$: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
  currentChannelName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  streamingRewardBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  withdrawalOptions$: BehaviorSubject<WithdrawalOptions> = new BehaviorSubject<WithdrawalOptions>(
    {} as WithdrawalOptions
  );

  private updateWatchingSessionTimeout: ReturnType<typeof setInterval> = {} as ReturnType<typeof setInterval>;
  private balanceCounterInterval: ReturnType<typeof setInterval> = {} as ReturnType<typeof setInterval>;
  private startWatchingSub?: Subscription;
  private updateWatchingSub?: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private turnstileService: TurnstileService,
    private toastrService: ToastrService
  ) {}

  initializeTwitchPlayer(channel: string) {
    const twitchPlayerConfiguration = {
      width: '100%',
      height: '100%',
      channel: channel,
      allowfullscreen: true,
      layout: 'video',
      muted: true,
      parent: ['localhost']
    };
    new Twitch.Player('twitchPlayer', twitchPlayerConfiguration);
  }

  getCurrentChannelData(): Channel | undefined {
    return this.channelsWithStatus$.getValue().find((el) => el.channelName == this.currentChannelName$.getValue());
  }

  updateStreamingStatus(): Subscription {
    return this.streamingStatusRequest().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          const updatedChannels: Channel[] = [];
          response.data.forEach((status: ChannelStatus) => {
            const channelToUpdate = this.channelsList$
              .getValue()
              .find((channel: Channel) => channel.id === status.streamerId);
            if (channelToUpdate) {
              channelToUpdate.isLive = status.isLive;
              updatedChannels.push(channelToUpdate);
            }
          });
          this.channelsWithStatus$.next(updatedChannels);
        }
      },
      error: (err) => {
        console.log('Error while streamingStatusRequest:', err);
      }
    });
  }

  startCollectingReward(): void {
    this.stopCollectingReward();
    console.log('Start collecting reward');
    this.startWatchingSub = this.startWatchingSessionRequest().subscribe({
      next: (response) => {
        console.log('startWatchingSessionRequest response', response);
        if (response.success && response.data) {
          const responseData: StartWatchingSession = response.data;
          this.streamingRewardBalance$.next(responseData.conversionRate * responseData.currentViewingTime);
          this.turnOnUpdateWatchingSession(responseData.updateInterval, responseData.conversionRate);
        }
      }
    });
  }

  stopCollectingReward() {
    console.log('Stop collecting reward');
    this.startWatchingSub?.unsubscribe();
    this.updateWatchingSub?.unsubscribe();
    clearInterval(this.balanceCounterInterval);
    clearTimeout(this.updateWatchingSessionTimeout);
  }

  claimGMRX(data: StreamingClaimReward) {
    this.claimWatchingRewardRequest(data).subscribe({
      next: async (response) => {
        if (response.success) {
          this.toastrService.success('Claim successful');
          console.log('claimWatchingReward response', response);
          this.streamingRewardBalance$.next(0);
          if (this.getCurrentChannelData()?.isLive) {
            await this.turnstileService.refreshToken();
            this.startCollectingReward();
          }
        }
      }
    });
  }

  calculatePendingReward(): Subscription {
    return this.getPendingRewardRequest().subscribe({
      next: (response: Response<StreamingData>) => {
        if (response.success && response.data) {
          const calculatePendingReward: number = response.data.conversionRate * response.data.currentViewingTime;
          this.streamingRewardBalance$.next(calculatePendingReward);
        }
      },
      error: (error) => {
        console.log('calculatePendingReward error', error);
      }
    });
  }

  turnOnUpdateWatchingSession(updateWatchingSessionTime: number, conversionRate: number) {
    this.stopCollectingReward();

    this.balanceCounterInterval = setInterval(() => {
      const balance: number = this.streamingRewardBalance$.getValue();
      this.streamingRewardBalance$.next(balance + conversionRate);
    }, 1000);

    const updateSession = async () => {
      console.log('updateWatchingSessionInterval');
      this.updateWatchingSub?.unsubscribe();
      this.updateWatchingSub = this.updateWatchingSessionRequest().subscribe({
        next: (response: Response<UpdateSessionData>) => {
          console.log('updateWatchingSession response', response);
          if (response.success && response.data && !response.data.sessionFound) {
            this.startCollectingReward();
          }
        },
        error: (err) => {
          console.error('updateWatchingSessionRequest error', err);
        }
      });

      this.updateWatchingSessionTimeout = setTimeout(updateSession, updateWatchingSessionTime * this.UPDATE_INTERVAL);
    };

    this.updateWatchingSessionTimeout = setTimeout(updateSession, updateWatchingSessionTime * this.UPDATE_INTERVAL);
  }

  getWithdrawalOptions(): Subscription {
    return this.withdrawalOptionsRequest().subscribe({
      next: (response: Response<WithdrawalOptionsData>) => {
        if (response.success && response.data) {
          const filteredData = response.data.options.find((item) => item.chain === CHAINS.BNB_CHAIN);
          if (filteredData) {
            this.withdrawalOptions$.next(filteredData);
          }
        }
      },
      error: (error) => {
        console.log('getWithdrawalOptions error', error);
      }
    });
  }

  getStreamersRequest(): Observable<Response<Channel[]>> {
    return this.http.get<Response<Channel[]>>(`${environment.gaiminApi}/streaming/streamers`);
  }

  updateWatchingSessionRequest(): Observable<Response<UpdateSessionData>> {
    return this.http.patch<Response<UpdateSessionData>>(
      `${environment.gaiminApi}/streaming/update-session`,
      { turnstileResponse: this.turnstileService.turnstileToken$.getValue() },
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  private streamingStatusRequest(): Observable<Response<ChannelStatus[]>> {
    return this.http.get<Response<ChannelStatus[]>>(`${environment.gaiminApi}/streaming/v2/status`, {
      context: new HttpContext().set(SHOW_LOADER, false)
    });
  }

  private withdrawalOptionsRequest(): Observable<Response<WithdrawalOptionsData>> {
    return this.http.get<Response<WithdrawalOptionsData>>(`${environment.gaiminApi}/withdrawals/options`, {
      headers: this.authService.authorizationHeader()
    });
  }

  private startWatchingSessionRequest(): Observable<Response<StartWatchingSession>> {
    return this.http.post<Response<StartWatchingSession>>(
      `${environment.gaiminApi}/streaming/start-session`,
      { turnstileResponse: this.turnstileService.turnstileToken$.getValue() },
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  private getPendingRewardRequest(): Observable<Response<StreamingData>> {
    return this.http.get<Response<StreamingData>>(`${environment.gaiminApi}/streaming/data`, {
      headers: this.authService.authorizationHeader()
    });
  }

  private claimWatchingRewardRequest(data: StreamingClaimReward): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/streaming/withdraw-reward`, data, {
      headers: this.authService.authorizationHeader()
    });
  }
}
