<ng-container *ngIf="articles$ | async as articlesSub">
  <div class="actions">
    <h3 *ngIf="contentPage">News feed</h3>
    <app-filter-authors [params]="params" (selectAuthors)="onAuthorsSelectionChange($event)"></app-filter-authors>
    <app-filter-sorting (changeSorting)="onChangeSortingOrder($event)"></app-filter-sorting>
  </div>

  <div class="articles" NgxMasonryGrid [gap]="columnGap" [align]="'start'">
    <div
      class="articles-item"
      [ngClass]="{ locked: !contentService.isContentVisible(item.tier) }"
      *ngFor="let item of articlesSub"
      (click)="contentService.isContentVisible(item.tier) ? navigateTo(item.id) : false"
      (auxclick)="contentService.isContentVisible(item.tier) ? openInNewTab(item.id) : false">
      <div class="articles-item__date">{{ item.publishDate | customDate }}</div>
      <div class="articles-item__author" *ngIf="item.author">
        <img [src]="item.author.avatarUrl" alt="" />
        <div class="articles-item__author-name">
          <span class="name">{{ item.author.name }}</span>
          <span class="username">&#64;{{ item.author.username }}</span>
        </div>
      </div>
      <div class="articles-item__text">
        <h3>{{ item.title }}</h3>
        <div *ngIf="item.text" class="text">{{ item.text | stripHtml }}</div>
        <div class="video-wrapper">
          <img *ngIf="item.imageUrl" [src]="item.imageUrl" [alt]="item.title" />
          <img *ngIf="item.thumbnailUrl" [src]="item.thumbnailUrl" [alt]="item.title" />
          <span *ngIf="item.videoUrl" class="play icon-Play"></span>
          <div *ngIf="!contentService.isContentVisible(item.tier)" (click)="goToPackages()" class="unlock">
            <span>Unlock</span>
            <span class="icon icon-Arrow-Down-Left"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-empty *ngIf="articlesSub.length == 0"></app-empty>
</ng-container>
