<div class="confirm">
  <div class="header">
    <h2>Are you sure you want to remove {{ data.itemName }}?</h2>
    <span class="icon-Close close-icon" title="Close" (click)="close()"></span>
  </div>
  <div class="modal-content">
    <div class="modal-content__buttons-list">
      <app-button (click)="confirm()" [buttonData]="confirmBtn"></app-button>
      <app-button (click)="close()" [buttonData]="closeBtn"></app-button>
    </div>
  </div>
</div>
