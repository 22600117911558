import { AfterViewInit, Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';

import { FloatingMenuService } from '../../services/floating-menu.service';
import { NotificationService } from '../../services/notification.service';
import { BUTTON_TYPE, FAN_AREA_MENU_TITLES } from '../../enums';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent implements AfterViewInit, OnDestroy {
  @AutoUnsubscribe()
  private breakpointSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private routerSub: Subscription = {} as Subscription;

  isNotificationRoute$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.router.url === '/notifications');

  BUTTON_TYPE = BUTTON_TYPE;
  FAN_AREA_MENU_TITLES = FAN_AREA_MENU_TITLES;

  constructor(
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public floatingMenuService: FloatingMenuService,
    public notificationService: NotificationService
  ) {
    this.breakpointSub = this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.floatingMenuService.closeDrawer();
          
          if (this.floatingMenuService.selectedItemTitle === FAN_AREA_MENU_TITLES.NOTIFICATION) {
            this.router.navigateByUrl('/notifications');
          }
          
          this.floatingMenuService.reset();
        }
      });
  }

  ngAfterViewInit() {
    this.floatingMenuService.setRenderer(this.renderer);

    this.routerSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        this.isNotificationRoute$.next(this.router.url === '/notifications');
      });
  }


  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'overflow');
  }

  closeDrawer() {
    this.floatingMenuService.closeDrawer();
    this.floatingMenuService.reset();
  }
}
