import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TwitchService } from '../../../shared/services/twitch.service';
import { LoaderService } from '../../../shared/services/loader.service';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss']
})
export class ChannelListComponent implements OnInit {
  constructor(
    public twitchService: TwitchService,
    private router: Router,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {}

  navigateTo(channel: string) {
    this.router.navigateByUrl(`/streams/${channel}`);
  }
}
