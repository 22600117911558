import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TwitchService } from '../../services/twitch.service';
import { ClaimRewardModalComponent } from '../../components/modals/claim-reward-modal/claim-reward-modal.component';
import { Button } from '../../intarfaces';
import { BUTTON_SIZE } from '../../enums';

@Component({
  selector: 'app-streams-claim-reward',
  templateUrl: './streams-claim-reward.component.html',
  styleUrls: ['./streams-claim-reward.component.scss']
})
export class StreamsClaimRewardComponent implements OnInit, OnDestroy {
  readonly claimBtnData: Button = {
    name: 'Claim',
    size: BUTTON_SIZE.SM
  };

  constructor(
    public twitchService: TwitchService,
    private dialogService: MatDialog
  ) {}

  ngOnInit(): void {}

  openClaimRewardModal() {
    this.dialogService.open(ClaimRewardModalComponent, {
      panelClass: ['dialog-overlay-pane']
    });
  }

  ngOnDestroy(): void {}
}
