<div class="reactions" appClickedOutside (clickOutside)="closeReactionsPopover()">
  <span
    class="reactions__icon icon-Add-Reaction"
    [ngClass]="{ active: isReactionsPopoverVisible }"
    (click)="showReactionsPopover()"></span>

  <div class="reactions__popover" [ngClass]="[isReactionsPopoverVisible ? 'visible' : '', 'position-' + position]">
    <div class="reactions__list">
      <ngx-emoji
        *ngFor="let item of reactions"
        [emoji]="item"
        [size]="reactionSize"
        (emojiClick)="onSelectReaction($event)"></ngx-emoji>
    </div>
  </div>
</div>
