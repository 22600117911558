import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ContentService } from '../../shared/services/content.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { BannerData } from '../../shared/intarfaces';
import { COLOSSEUM_PAGES } from '../../shared/enums';

@Component({
  selector: 'content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {
  @AutoUnsubscribe()
  private bannersSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private authorsListSubs: Subscription = {} as Subscription;

  banners$: BehaviorSubject<BannerData[]> = new BehaviorSubject<BannerData[]>([]);

  constructor(
    private additionalService: AdditionalService,
    private contentService: ContentService
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.bannersSubs = this.contentService.getBannersRequest([COLOSSEUM_PAGES.COLOSSEUM_CONTENT]).subscribe((res) => {
      if (res.success && res.data) {
        this.banners$.next(res.data);
      }
    });

    this.authorsListSubs = this.contentService.getAuthorsRequest().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.contentService.authorsList$.next(response.data);
        }
      }
    });
  }
}
