import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { TurnstileService } from '../../services/turnstile.service';

@Component({
  selector: 'turnstile-captcha',
  template: '<div id="turnstile-captcha"></div>'
})
export class TurnstileCaptchaComponent implements AfterViewInit, OnDestroy {
  @Output() token = new EventEmitter<string>();
  @Output() expired = new EventEmitter<void>();
  @Output() error = new EventEmitter<void>();

  constructor(
    private turnstileService: TurnstileService,
    private elRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    this.turnstileService.initTurnstileCaptcha(this.elRef, this.token, this.expired, this.error);
  }

  ngOnDestroy(): void {
    this.turnstileService.destroyCaptcha();
  }
}
