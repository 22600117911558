import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AuthService } from '../../shared/services/auth.service';
import { ContentService } from '../../shared/services/content.service';
import { VideosService } from '../../shared/services/videos.service';
import { AdditionalService } from '../../shared/services/additional.service';

@Component({
  selector: 'videos-page',
  templateUrl: './videos-page.component.html',
  styleUrls: ['./videos-page.component.scss']
})
export class VideosPageComponent implements OnInit, OnDestroy {
  @AutoUnsubscribe()
  private authorsListSubs: Subscription = {} as Subscription;

  constructor(
    private authService: AuthService,
    private additionalService: AdditionalService,
    private contentService: ContentService,
    public videosService: VideosService
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn$.getValue()) {
      this.videosService.getData(this.videosService.params, { ids: [] });

      this.authorsListSubs = this.contentService.getAuthorsRequest().subscribe({
        next: (response) => {
          if (response.success && response.data) {
            this.contentService.authorsList$.next(response.data);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.videosService.clearFilters();
    this.videosService.videos$.next(null);
    this.videosService.videosSubs?.unsubscribe();
  }
}
