<app-home-header></app-home-header>
<div class="home-container">
  <div class="faqs">
    <h2 class="back-title">
      <div routerLink="/">
        <span class="icon icon-Arrow-Left"></span>
      </div>
    </h2>
    <h2 class="title">Frequently asked questions</h2>
    <h3>
      For more information about The Colosseum, contact us at
      <a href="mailto:colosseum@gaimin.io">colosseum&#64;gaimin.io</a>
    </h3>
    <mat-tab-group dynamicHeight>
      <mat-tab label="Guides">
        <app-faqs-guides></app-faqs-guides>
      </mat-tab>
      <mat-tab label="Queries">
        <app-faqs-queries></app-faqs-queries>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<app-home-footer></app-home-footer>
