import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ContentService } from '../../../../shared/services/content.service';
import { WalletConnectService } from '../../../../shared/services/wallet-connect.service';
import { PlayerProfilesListData, HonorData, Button } from '../../../../shared/intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../../shared/enums';

@Component({
  selector: 'app-player-page',
  templateUrl: './player-page.component.html',
  styleUrls: ['./player-page.component.scss']
})
export class PlayerPageComponent implements OnInit {
  @AutoUnsubscribe()
  private playerSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private honorsSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private authorsListSubs: Subscription = {} as Subscription;

  readonly openDonatePopoverBtn: Button = {
    name: 'Send Message',
    size: BUTTON_SIZE.MD
  };

  readonly messagesBtn: Button = {
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.ICON,
    iconLeft: 'icon-Notification'
  };

  wishlistBtn: Button = {
    name: 'Wishlist',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.GREY
  };

  id: string | null = null;
  data: PlayerProfilesListData = {} as PlayerProfilesListData;
  honors: HonorData[] = [];
  showAll: boolean = false;
  activeTab: number = 0;
  isMobile = false;
  isMessagesVisible: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public contentService: ContentService,
    public walletConnectService: WalletConnectService,
    private toastrService: ToastrService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.id) {
      this.playerSub = this.contentService.getPlayerByIdRequest(this.id).subscribe((res) => {
        if (res.success && res.data) {
          this.data = res.data;
          this.wishlistBtn.name = this.data.player.username + ' Wishlist';
        }
      });

      this.honorsSub = this.contentService.getHonorsByPlayerIdRequest(this.id).subscribe((res) => {
        if (res.success && res.data) {
          this.honors = res.data;
        }
      });

      this.authorsListSubs = this.contentService.getAuthorsRequest().subscribe({
        next: (response) => {
          if (response.success && response.data) {
            this.contentService.authorsList$.next(response.data);
          }
        }
      });
    } else {
      this.goToPlayers();
    }
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  goToPlayers() {
    this.router.navigateByUrl(`/players`);
  }

  openMessagesList(event: any) {
    event.stopPropagation();
    this.closeDonatePopover();

    if (this.isMessagesVisible) {
      this.closeMessagesList();
    } else {
      this.data.unreadMessages = 0;
      this.isMessagesVisible = true;
    }
  }

  closeMessagesList() {
    this.isMessagesVisible = false;
  }

  openDonatePopover(event: any) {
    event.stopPropagation();
    this.closeMessagesList();
    if (this.walletConnectService.isWalletConnected$.getValue()) {
      this.walletConnectService.isDonatePopoverOpen = !this.walletConnectService.isDonatePopoverOpen;
    } else {
      this.toastrService.success('Please connect your wallet to make a donation!');
    }
  }

  closeDonatePopover() {
    this.walletConnectService.isDonatePopoverOpen = false;
  }

  goToWishlist(url: string | null) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  setActiveTab(tabIndex: number): void {
    this.activeTab = tabIndex;
  }

  isNumber(n: any): boolean {
    return typeof n === 'number';
  }
}
