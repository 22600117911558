<header class="home-header">
  <app-email-confirmation></app-email-confirmation>
  <div class="home-wide-container">
    <div class="home-header__menu-mobile" (click)="openSidebar()">
      <span class="icon-Menu"></span>
    </div>
    <ul class="home-header__menu">
      <li (click)="goToPackages()">Packages</li>
      <li (click)="goToFaqs()">FAQ</li>
      <li (click)="goToBlog()">Blog</li>
    </ul>
    <img class="home-header__logo" src="../../../../../assets/gladiators-logo.svg" alt="" (click)="openRoot()" />
    <div *ngIf="!(authService.isLoggedIn$ | async)" class="home-header__btns">
      <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
      <app-button (click)="becomeMember()" [buttonData]="memberBtn"></app-button>
    </div>
    <app-header-profile
      *ngIf="authService.isLoggedIn$ | async"
      class="header__profile"
      [isHomePage]="true"></app-header-profile>
  </div>
</header>

<div class="home-sidebar__wrapper" [ngClass]="{ active: isSideBarOpen$ | async }" (click)="closeSidebar()">
  <div class="home-sidebar" [ngClass]="{ active: isSideBarOpen$ | async }" (click)="stopPropagation($event)">
    <span class="close-btn icon-Close-Bold" (click)="closeSidebar()"></span>
    <div class="home-sidebar__header">
      <img src="../../../../assets/gladiators-logo.svg" alt="" (click)="openRoot()" />
    </div>
    <ul class="home-sidebar__menu">
      <li (click)="goToPackages()">Packages <span class="icon-Arrow-Right"></span></li>
      <li (click)="goToFaqs()">FAQ <span class="icon-Arrow-Right"></span></li>
      <li (click)="goToBlog()">Blog <span class="icon-Arrow-Right"></span></li>
    </ul>
    <div class="home-sidebar__btns" *ngIf="!(authService.isLoggedIn$ | async)">
      <app-button (click)="signIn()" [buttonData]="signInBtn"></app-button>
      <app-button (click)="becomeMember()" [buttonData]="memberBtn"></app-button>
    </div>
  </div>
</div>
