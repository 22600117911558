import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { EditCommentModalComponent } from '../modals/edit-comment-modal/edit-comment-modal.component';
import { StoriesService } from '../../services/stories.service';
import { UserService } from '../../services/user.service';
import { Button, CommentsData, ReactionsListData, Response } from '../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE, POPOVER_TYPE, SORTING_ORDER, USER_ROLES } from '../../enums';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-stories-comments-list',
  templateUrl: './stories-comments-list.component.html',
  styleUrls: ['./stories-comments-list.component.scss']
})
export class StoriesCommentsListComponent implements OnInit, AfterViewInit {
  @ViewChild('messageInput') messageInput!: ElementRef;
  @ViewChild('commentsList') commentsList!: ElementRef;
  @Output() changeSorting = new EventEmitter<any>();
  @Input() comments: CommentsData[] = [];
  @Input() id!: number;
  @Input() sortingOrder: SORTING_ORDER = SORTING_ORDER.DESC;

  @AutoUnsubscribe()
  private storiesSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private reactionsSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private messageValueChangesSubs: Subscription | undefined = {} as Subscription;

  form = new FormGroup({
    message: new FormControl('', [Validators.max(2500), Validators.required])
  });
  popoverPosition: POPOVER_TYPE = POPOVER_TYPE.LEFT;
  popoverListPosition: POPOVER_TYPE = POPOVER_TYPE.RIGHT;

  protected readonly submitBtn: Button = {
    name: 'Send',
    size: BUTTON_SIZE.AUTO,
    type: BUTTON_TYPE.GREY
  };

  protected readonly USER_ROLES = USER_ROLES;

  constructor(
    public storiesService: StoriesService,
    public userService: UserService,
    private dialogService: MatDialog,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.messageValueChangesSubs = this.form.get('message')?.valueChanges.subscribe(() => {
      this.messageInput.nativeElement.style.height = 'auto';
      this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
    });
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.commentsList.nativeElement.scrollTop = this.commentsList.nativeElement.scrollHeight;
    }, 0);
  }

  onSelectReaction(reaction: ReactionsListData, commentId: number) {
    this.reactionsSub = this.storiesService.handleCommentReactionRequest(this.id!, commentId, reaction.name).subscribe();
  }

  onAddReactionToMessage(reaction: EmojiData) {
    let message = this.form.value.message || '';
    const inputElement = this.messageInput.nativeElement as HTMLInputElement;
    const startPos = inputElement.selectionStart || 0;
    const endPos = inputElement.selectionEnd || 0;
    message = message.slice(0, startPos) + reaction.native + message.slice(endPos);
    this.form.get('message')?.setValue(message);
  }

  onAddComment() {
    const { message } = this.form.value;

    this.storiesSub = this.storiesService.addCommentRequest(this.id, message!).subscribe((res) => {
      if (res.success && res.data) {
        if (this.sortingOrder == SORTING_ORDER.ASC) {
          this.comments.push(res.data);
        } else {
          this.comments.unshift(res.data);
        }
        this.messageInput.nativeElement.style.height = 'auto';
        this.scrollToBottom();
      }
    });

    this.form.reset();
  }

  onEditComment(comment: CommentsData) {
    const dialogRef = this.dialogService.open(EditCommentModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-form-edit'],
      data: { message: comment.message }
    });

    dialogRef.componentRef?.instance.confirmRequest.subscribe((message: string) => {
      if (comment.commentId) {
        this.storiesService.updateCommentRequest(this.id, comment.commentId, message).subscribe({
          next: (response: Response<any>) => {
            if (response.success) {
              const index = this.comments.findIndex((item) => item.commentId === comment.commentId);
              if (index !== -1) {
                const updatedComment = { ...this.comments[index], message: message };
                this.comments.splice(index, 1, updatedComment);
              }
            }
          }
        });
      }
    });
  }

  onRemoveComment(comment: CommentsData) {
    const dialogRef = this.dialogService.open(ConfirmationModalComponent, {
      panelClass: 'dialog-overlay-pane',
      data: { itemName: 'comment' }
    });

    dialogRef.componentRef?.instance.confirmRequest.subscribe(() => {
      if (comment.commentId) {
        const isManager = this.authService.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER]);
        const credential = isManager
          ? this.authService.managerEncodedCreds$.getValue()! || this.authService.managerToken
          : null;
        this.deleteComment(isManager, credential, comment);
      }
    });
  }

  deleteComment(isManager: boolean, credential: string | null, comment: CommentsData) {
    const deleteCommentObservable = isManager
      ? this.storiesService.deleteCommentRequestByAdmin(this.id, comment.commentId, credential!)
      : this.storiesService.deleteCommentRequest(this.id, comment.commentId);

    deleteCommentObservable.subscribe({
      next: (response: Response<any>) => {
        if (response.success) {
          const index = this.comments.findIndex((item) => item.commentId === comment.commentId);
          if (index !== -1) {
            this.comments.splice(index, 1);
          }
        }
      }
    });
  }
}
