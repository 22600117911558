import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MeldService } from '../../../services/meld.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'meld-modal',
  templateUrl: './meld-modal.component.html',
  styleUrls: ['./meld-modal.component.scss']
})
export class MeldModalComponent implements OnInit {
  @AutoUnsubscribe()
  private cryptoWizardDetailsSub: Subscription | undefined;

  urlSafe: string = '';
  destinationCurrencyCode = encodeURIComponent('GMRX_BSC');
  sourceCurrencyCode = encodeURIComponent('USD');
  transactionType = encodeURIComponent('BUY');
  countryCode = encodeURIComponent('US');

  constructor(
    private dialogRef: MatDialogRef<MeldModalComponent>,
    private meldService: MeldService
  ) {
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.cryptoWizardDetailsSub = this.meldService.getCryptoWizardDetailsRequest().subscribe({
      next: (response) => {
        if (response.success) {
          const { publicKey, walletAddress, userId } = response.data!;

          const urlParams = new URLSearchParams({
            publicKey: publicKey,
            countryCode: this.countryCode,
            sourceCurrencyCode: this.sourceCurrencyCode,
            destinationCurrencyCode: this.destinationCurrencyCode,
            walletAddress: walletAddress ?? '',
            externalCustomerId: userId.toString(),
            transactionType: this.transactionType
          });

          this.urlSafe = `${environment.meldLink}/?${urlParams.toString()}`;
        }
      },
      error: () => {
        this.closeModal();
      }
    });
  }
}
