<div class="home">
  <app-home-header></app-home-header>
  <div class="home-container">
    <div class="home__banner">
      <div class="home__banner-content">
        <div class="home__banner-games">
          <img class="dota" src="assets/icons/home/banner-dota.svg" alt="" />
          <img class="battlegrounds" src="assets/icons/home/banner-battlegrounds.svg" alt="" />
          <img class="fortnite" src="assets/icons/home/banner-fortnite.svg" alt="" />
          <img class="tekken" src="assets/icons/home/banner-tekken.svg" alt="" />
          <img class="street-fighter" src="assets/icons/home/banner-street-fighter.svg" alt="" />
          <img class="rocket-league" src="assets/icons/home/banner-rocket-league.svg" alt="" />
          <img class="apex" src="assets/icons/home/banner-apex.svg" alt="" />
          <img class="star-craft" src="assets/icons/home/banner-star-craft.svg" alt="" />
          <img class="mobile-legends" src="assets/icons/home/banner-mobile-legends.svg" alt="" />
        </div>
        <h2>Join The Gaimin Gladiators "Colosseum"</h2>
        <h3>Unlock VIP benefits via our exclusive Gaimin Gladiators Fan Token «GMRX»</h3>
      </div>
      <img class="home__banner-img" src="assets/icons/home/banner.png" alt="" />
    </div>
    <div class="home__info">
      <app-button class="member-btn" (click)="goToPackages()" [buttonData]="memberBtn"></app-button>
      <h4>Join the inner circle of the most loyal esports fans worldwide!</h4>
    </div>
    <app-youtube></app-youtube>
  </div>
  <div class="home-wide-container">
    <div id="packagesSection" class="home__packages">
      <h2 class="title">Choose your package</h2>
      <app-packages [isLevelsVisible]="false"></app-packages>
      <div class="home__packages-link">
        For more information about our packages, click
        <a href="/packages">here</a>
      </div>
    </div>

    <div id="faqsSection" class="home__faqs">
      <h2>The Colosseum FAQs</h2>
      <h3>
        For more information about The Colosseum, contact us at
        <a href="mailto:colosseum@gaimin.io">colosseum&#64;gaimin.io</a>
      </h3>
      <app-faqs-guides></app-faqs-guides>
      <div class="home__faqs-link">
        Looking for more information? <a routerLink="/faqs">Click here</a> to visit our FAQs page.
      </div>
    </div>
  </div>
  <app-ticker></app-ticker>
  <app-home-footer></app-home-footer>
</div>
