import { Component, Input, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'store-carousel',
  templateUrl: './store-carousel.component.html',
  styleUrls: ['./store-carousel.component.scss']
})
export class StoreCarouselComponent {
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent | undefined;
  @Input() images: any;

  constructor() {}

  moveLeft() {
    this.ds?.moveLeft();
  }

  moveRight() {
    this.ds?.moveRight();
  }

  moveTo(index: number) {
    this.ds?.moveTo(index);
  }

  ngAfterViewInit() {
    // Starting ngx-drag-scroll from specified index(3)
    setTimeout(() => {
      this.ds?.moveTo(3);
    }, 0);
  }
}
