import { Component } from '@angular/core';
import { LoaderService } from '../../shared/services/loader.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { RewardsData } from '../../shared/intarfaces';

@Component({
  selector: 'rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss']
})
export class RewardsPageComponent {
  imagePath = 'assets/icons/rewards/reward.png';

  rewards: RewardsData[] = [
    {
      name: 'Digital Meet with the GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Play Against GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Physical Meet with the GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Video Message from the GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Signed Jerseys from the GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Open Practice Session with the GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Attend Exclusive GG Podcasts Live',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Signed Rewards on Live Streams',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Game-Used Devices from GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Exclusive Competitions Access',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'VIP Tickets to Tournaments with GG Players',
      imageUrl: this.imagePath,
      comingSoon: true
    },
    {
      name: 'Dinner with GG Staff at Exclusive Events',
      imageUrl: this.imagePath,
      comingSoon: true
    }
  ];

  constructor(
    private additionalService: AdditionalService,
    private loaderService: LoaderService
  ) {
    this.loaderService.showLoader();
    this.additionalService.isHomePageTemplate$.next(false);
  }

  onImageLoad() {
    this.loaderService.hideLoader();
  }
}
