import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { CryptoWizardDetails, Response } from '../intarfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeldService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getCryptoWizardDetailsRequest(): Observable<Response<CryptoWizardDetails>> {
    return this.http.get<Response<CryptoWizardDetails>>(`${environment.gaiminApi}/meld/session-details `, {
      headers: this.authService.authorizationHeader()
    });
  }
}
