import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Button } from '../../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE, POPOVER_TYPE } from '../../../enums';

@Component({
  selector: 'edit-comment-modal',
  templateUrl: './edit-comment-modal.component.html',
  styleUrls: ['./edit-comment-modal.component.scss']
})
export class EditCommentModalComponent implements OnInit {
  @Output() confirmRequest: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('messageInput') messageInput!: ElementRef;

  form = new FormGroup({
    message: new FormControl('', [Validators.max(2500), Validators.required])
  });

  popoverPosition: POPOVER_TYPE = POPOVER_TYPE.LEFT;

  readonly confirmBtn: Button = { name: 'Confirm', size: BUTTON_SIZE.LG };
  readonly closeBtn: Button = { name: 'Cancel', size: BUTTON_SIZE.LG, type: BUTTON_TYPE.DARK };

  constructor(
    private dialogRef: MatDialogRef<EditCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit(): void {
    this.form.get('message')?.setValue(this.data.message);
  }

  onAddReactionToMessage(reaction: EmojiData) {
    let message = this.form.value.message || '';
    const inputElement = this.messageInput.nativeElement as HTMLInputElement;
    const startPos = inputElement.selectionStart || 0;
    const endPos = inputElement.selectionEnd || 0;
    message = message.slice(0, startPos) + reaction.native + message.slice(endPos);
    this.form.get('message')?.setValue(message);
  }

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.confirmRequest.emit(this.form.value.message);
    this.dialogRef.close();
  }
}
