import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import {
  AuthorsListData,
  BannerData,
  EventData,
  GamesData,
  PlayersListData,
  PlayerProfilesListData,
  PlayerConversation,
  HonorData,
  Response
} from '../intarfaces';
import { COLOSSEUM_PAGES, COLOSSEUM_TIERS } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  authorsList$: BehaviorSubject<AuthorsListData[]> = new BehaviorSubject<AuthorsListData[]>([]);
  playersList$: BehaviorSubject<PlayersListData[]> = new BehaviorSubject<PlayersListData[]>([]);
  playersListByGame$: BehaviorSubject<Record<number, { game: GamesData; players: PlayersListData[] }>> =
    new BehaviorSubject<Record<number, { game: GamesData; players: PlayersListData[] }>>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  isContentVisible(contentTier: COLOSSEUM_TIERS) {
    return !(
      contentTier !== COLOSSEUM_TIERS.BRONZE &&
      this.authService.acquireUserSubscriptionData$.getValue()?.tier == COLOSSEUM_TIERS.BRONZE
    );
  }

  getBannersRequest(pages: COLOSSEUM_PAGES[]): Observable<Response<BannerData[]>> {
    return this.http.get<Response<BannerData[]>>(`${environment.gaiminApi}/content/banners`, {
      headers: this.authService.authorizationHeader(),
      params: { pages: pages.toString() }
    });
  }

  getAuthorsRequest(): Observable<Response<AuthorsListData[]>> {
    return this.http.get<Response<AuthorsListData[]>>(`${environment.gaiminApi}/content/authors`, {
      headers: this.authService.authorizationHeader()
    });
  }

  getPlayersRequest(): Observable<Response<PlayersListData[]>> {
    return this.http.get<Response<PlayersListData[]>>(`${environment.gaiminApi}/players`, {
      headers: this.authService.authorizationHeader()
    });
  }

  getPlayerByIdRequest(id: string): Observable<Response<PlayerProfilesListData>> {
    return this.http.get<Response<PlayerProfilesListData>>(`${environment.gaiminApi}/players/${id}/profiles`, {
      headers: this.authService.authorizationHeader()
    });
  }

  getPlayerMessagesByIdRequest(id: number): Observable<Response<PlayerConversation[]>> {
    return this.http.get<Response<PlayerConversation[]>>(`${environment.gaiminApi}/players/${id}/profiles/messages`, {
      headers: this.authService.authorizationHeader()
    });
  }

  getHonorsByPlayerIdRequest(id: string): Observable<Response<HonorData[]>> {
    return this.http.get<Response<HonorData[]>>(`${environment.gaiminApi}/players/${id}/tournaments`, {
      headers: this.authService.authorizationHeader()
    });
  }

  getEventInfoByIdRequest(id: string): Observable<Response<EventData>> {
    return this.http.get<Response<EventData>>(`${environment.gaiminApi}/dpa-event/basic-info?eventId=${id}`);
  }

  claimDpaEventRequest(id: string, token: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.gaiminApi}/dpa-event/claim?id=${id}&token=${token}`,
      {},
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }
}
