import { Component } from '@angular/core';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent {
  isBgVisible: boolean = true;

  constructor() {}

  showVideo() {
    this.isBgVisible = false;
  }
}
