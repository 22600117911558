<div class="content">
  <app-banners
    *ngIf="(banners$ | async) && (banners$ | async)!.length > 0"
    [banners]="(banners$ | async)!"></app-banners>
  <app-stories></app-stories>
  <app-videos></app-videos>
  <div class="container">
    <app-articles></app-articles>
  </div>
</div>
