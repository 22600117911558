import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { AdditionalService } from '../../services/additional.service';
import { UserService } from '../../services/user.service';
import { Response, Button, StreamerWallet, PlayerConversation } from '../../intarfaces';
import { BUTTON_SIZE } from '../../enums';

@Component({
  selector: 'app-donate-popover',
  templateUrl: './donate-popover.component.html',
  styleUrls: ['./donate-popover.component.scss']
})
export class DonatePopoverComponent implements OnInit, OnDestroy {
  @AutoUnsubscribe() private amountChangeSubs: Subscription = {} as Subscription;
  @AutoUnsubscribe() private balanceChangeSubs: Subscription = {} as Subscription;

  @Input() wallets: StreamerWallet[] = [];
  @Input() messagePlayerId: number | null = null;

  form: FormGroup = new FormGroup({});

  selectableDonationAmounts = [
    {
      value: 10,
      isSelected: false
    },
    {
      value: 20,
      isSelected: false
    },
    {
      value: 50,
      isSelected: false
    },
    {
      value: 100,
      isSelected: false
    },
    {
      value: 500,
      isSelected: false
    }
  ];

  isLoggedInToWalletPage: boolean = false;
  isHaveCreatedWallets: boolean = false;
  messageMinLength: number = 2;
  messageMaxLength: number = 120;
  minAmountToSendMessage: number = 400;
  minAmountToDonate: number = 1;

  readonly donateButtonData: Button = {
    name: 'Donate',
    size: BUTTON_SIZE.LG
  };

  constructor(
    private additionalService: AdditionalService,
    private walletConnectService: WalletConnectService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.minAmountToDonate = this.messagePlayerId ? this.minAmountToSendMessage : 1;
    this.form = new FormGroup({
      amount: new FormControl(undefined, [
        Validators.min(this.minAmountToDonate),
        Validators.max(this.walletConnectService.walletGmrxBalance$.getValue()!),
        Validators.required
      ]),
      message: new FormControl('', [
        Validators.minLength(this.messageMinLength),
        Validators.maxLength(this.messageMaxLength),
        ...(this.messagePlayerId ? [Validators.required] : [])
      ])
    });

    if (this.messagePlayerId) {
      this.selectableDonationAmounts = [
        {
          value: 400,
          isSelected: false
        },
        {
          value: 500,
          isSelected: false
        },
        {
          value: 600,
          isSelected: false
        },
        {
          value: 800,
          isSelected: false
        },
        {
          value: 1000,
          isSelected: false
        }
      ];
    }

    this.amountChangeSubs = this.form.get('amount')!.valueChanges.subscribe({
      next: (amount) => {
        this.selectableDonationAmounts.forEach((el) => {
          el.isSelected = el.value === amount;
        });
      }
    });

    this.balanceChangeSubs = this.walletConnectService.walletGmrxBalance$.subscribe({
      next: (balance) => {
        if (balance !== null) {
          this.form
            .get('amount')!
            .setValidators([Validators.min(this.minAmountToDonate), Validators.max(balance), Validators.required]);
        }
      }
    });
  }

  donate() {
    const { amount } = this.form.value;
    const streamerWallet = this.getStreamerWalletAddress();
    const { message } = this.form.value;

    if (!streamerWallet) {
      this.toastrService.error(`Can't find streamer's wallet address.`);
      return;
    }

    this.walletConnectService.makeDonate(streamerWallet, amount).then((response) => {
      if (this.messagePlayerId) {
        this.sendMessageToPlayer(message);
      }
    });

    this.close();
    this.form.reset();
  }

  sendMessageToPlayer(message: string) {
    this.userService.sendMessageToPlayerRequst(message, this.messagePlayerId!).subscribe({
      next: (response: Response<PlayerConversation>) => {
        if (response.success && response.data) {
          this.toastrService.success('Message sent successfully!');
        }
      }
    });
  }

  getStreamerWalletAddress() {
    const address = this.wallets.filter((wallet) => wallet.chain == 'BNB_CHAIN');
    return address[0]?.wallet;
  }

  close() {
    this.walletConnectService.isDonatePopoverOpen = false;
  }

  selectDonateAmount(value: number) {
    this.selectableDonationAmounts.forEach((el) => {
      if (el.value === value) {
        el.isSelected = true;
        this.form.get('amount')!.setValue(el.value);
        this.form.get('amount')!.markAsDirty();
      } else {
        el.isSelected = false;
      }
    });
  }

  onChangeAmount(event: any) {
    const amount = this.additionalService.normalizeAmount(event.data, event.target.value, 2);
    this.additionalService.previousValue$.next(amount);
    this.form.get('amount')!.setValue(amount);
  }

  ngOnDestroy(): void {
    this.additionalService.previousValue$.next('');
    this.close();
  }
}
