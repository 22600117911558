import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AuthService } from '../../../../shared/services/auth.service';
import { VideosService } from '../../../../shared/services/videos.service';
import { AdditionalService } from '../../../../shared/services/additional.service';
import { VideoData, ReactionsListData, CommentsData, UnauthorizedContent } from '../../../../shared/intarfaces';
import { POPOVER_TYPE } from '../../../../shared/enums';

@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.scss'],
  providers: [VideosService]
})
export class VideoPageComponent implements OnInit {
  @AutoUnsubscribe()
  private videosSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private reactionsSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private metaSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private routeSub: Subscription = {} as Subscription;

  id: number | null = null;
  data: VideoData = {} as VideoData;
  reactions: ReactionsListData[] = [];
  comments: CommentsData[] = [];
  popoverListPosition: POPOVER_TYPE = POPOVER_TYPE.CENTER_RIGHT;
  isSharePopoverOpen: boolean = false;
  unauthorizedContent: UnauthorizedContent | null = null;
  isAccessRestricted: boolean = false;
  loading: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public additionalService: AdditionalService,
    public videosService: VideosService
  ) {}

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.id = parseInt(id);
        this.getVideoById(this.id);
      } else {
        this.goToVideos();
      }
    });
  }

  getVideoById(id: number, sortingOrder?: string) {
    this.metaSub = this.videosService.getVideoMetaByIdRequest(id).subscribe((res) => {
      if (res.success && res.data) {
        this.unauthorizedContent = {
          image: res.data.imageUrl,
          title: res.data.title,
          description: res.data.text
        };

        this.additionalService.updateMetaTags({
          ...this.unauthorizedContent,
          url: window.location.href
        });
      }
    });

    if (this.authService.isLoggedIn$.getValue()) {
      this.loading = true;

      this.videosSub = this.videosService.getVideoByIdRequest(id, sortingOrder).subscribe((res) => {
        if (res.success && res.data) {
          this.data = res.data.video;
          this.reactions = res.data.reactions;
          this.comments = res.data.comments;
        } else if (res.error && res.error.type == 'UNAVAILABLE') {
          this.isAccessRestricted = true;
        }
        this.loading = false;
      });
    }
  }

  goToVideos() {
    this.router.navigateByUrl(`/videos`);
  }

  onSelectReaction(reaction: ReactionsListData) {
    this.reactionsSub = this.videosService.handleVideoReactionRequest(this.id!, reaction.name).subscribe();
  }

  updateCommentsOrder(sortingOrder: string) {
    this.getVideoById(this.id!, sortingOrder);
  }

  openSharePopover() {
    this.isSharePopoverOpen = !this.isSharePopoverOpen;
  }

  closeSharePopover() {
    this.isSharePopoverOpen = false;
  }
}
