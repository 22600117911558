import { Component, Input } from '@angular/core';
import { UnauthorizedContent } from '../../intarfaces';

@Component({
  selector: 'app-unauthorized-content',
  templateUrl: './unauthorized-content.component.html',
  styleUrls: ['./unauthorized-content.component.scss']
})
export class UnauthorizedContentComponent {
  @Input() content!: UnauthorizedContent;
  @Input() isAccessRestricted: boolean = false;
  
  constructor() {}
}
