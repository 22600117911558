<div class="edit-avatar">
  <div class="header">
    <h2>Profile photo</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>
  <div class="modal-content">
    <h3>Upload image to change the profile photo</h3>
    <div class="edit-avatar__upload">
      <div class="edit-avatar__upload-button" (click)="fileInput.click()">
        <span class="icon-Upload"></span> Upload Image
      </div>
      <div *ngIf="imageUrl" class="edit-avatar__upload-file" [title]="imageUrl">
        <div class="edit-avatar__upload-file-name">
          <span class="icon-Attachment"></span>
          <p>{{ imageUrl }}</p>
        </div>
        <span
          class="icon-Close-Bold edit-avatar__upload-file-remove"
          title="Remove an uploaded image"
          (click)="onRemove()"></span>
      </div>
      <input style="display: none" type="file" accept="image/jpeg,image/png" (change)="onChange($event)" #fileInput />
    </div>
    <div class="modal-content__buttons-list">
      <app-button [buttonData]="cancelButton" (click)="onCancel()"></app-button>
      <app-button [attr.disabled]="!this.avatarUpdated" [buttonData]="saveButton" (click)="onSubmit()"></app-button>
    </div>
  </div>
</div>
