import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmojiData, EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { reactionsList } from '../../reactions';
import { POPOVER_TYPE } from '../../enums';

@Component({
  selector: 'app-reactions-popover',
  templateUrl: './reactions-popover.component.html',
  styleUrls: ['./reactions-popover.component.scss']
})
export class ReactionsPopoverComponent implements OnInit {
  @Input() position: POPOVER_TYPE = POPOVER_TYPE.CENTER;
  @Output() selectReactionEvent = new EventEmitter<EmojiData>();

  reactions = reactionsList;
  reactionSize: number = 23;
  isReactionsPopoverVisible: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  showReactionsPopover() {
    this.isReactionsPopoverVisible = !this.isReactionsPopoverVisible;
  }

  closeReactionsPopover() {
    this.isReactionsPopoverVisible = false;
  }

  onSelectReaction(event: EmojiEvent) {
    this.selectReactionEvent.emit(event.emoji);
  }
}
