import { Component, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-socials-login',
  templateUrl: './socials-login.component.html',
  styleUrls: ['./socials-login.component.scss']
})
export class SocialsLoginComponent {
  @Input() isRegisterForm: boolean = false;

  constructor(private authService: AuthService) {}

  login() {
    this.authService.loginWithOkta();
  }
}
