import { Component, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { BehaviorSubject } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { SortingPageFilter } from '../../intarfaces';

@Component({
  selector: 'app-filter-authors',
  templateUrl: './filter-authors.component.html',
  styleUrls: ['./filter-authors.component.scss']
})
export class FilterAuthorsComponent implements AfterViewChecked {
  @Output() selectAuthors = new EventEmitter<any>();
  @Input() params: SortingPageFilter = {} as SortingPageFilter;
  @Input() selectedAuthorsIds: number[] = [];
  isAuthorsDropdownOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public contentService: ContentService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  openAuthorsList() {
    this.isAuthorsDropdownOpen$.next(!this.isAuthorsDropdownOpen$.getValue());
  }

  closeAuthorsList() {
    this.isAuthorsDropdownOpen$.next(false);
  }

  onAuthorsSelectionChange(event: MatSelectionListChange) {
    this.selectAuthors.emit(event);
    this.params.page = 1;
    this.closeAuthorsList();
  }
}
