import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpContext, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { SHOW_LOADER } from '../../interceptors/loader.interceptor';
import {
  Response,
  StoriesData,
  StoriesListData,
  StoryDetailsData,
  SortingPageFilter,
  AuthorsFilter,
  CommentsData
} from '../intarfaces';
import { SORTING_ORDER } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class StoriesService {
  public storiesSubs: Subscription | undefined;
  stories$: BehaviorSubject<StoriesData[] | null> = new BehaviorSubject<StoriesData[] | null>(null);
  selectedAuthors: number[] = [];

  params: SortingPageFilter = {
    page: 1,
    size: 15,
    sort: SORTING_ORDER.DESC
  };
  totalPages: number = 0;

  loading: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  clearFilters() {
    this.totalPages = 0;
    this.selectedAuthors = [];
    this.params = {
      page: 1,
      size: 15,
      sort: SORTING_ORDER.DESC
    };
  }

  getData(params: SortingPageFilter, ids: AuthorsFilter, emptyList = false) {
    this.loading = true;
    this.storiesSubs = this.getStoriesRequest(params, ids).subscribe((res) => {
      if (res.success && res.data) {
        if (emptyList) {
          this.stories$.next(res.data.stories);
        } else {
          const currentStories = this.stories$.getValue() || [];
          this.stories$.next([...currentStories, ...res.data.stories]);
        }
        this.totalPages = res.data.pagination.totalPages;
      }
      this.loading = false;
    });
  }

  getDataByPlayerId(playerId: number, ids: AuthorsFilter, sort: SORTING_ORDER) {
    this.loading = true;
    this.storiesSubs = this.getStoriesByPlayerIdRequest(playerId, ids, sort).subscribe((res) => {
      if (res.success && res.data) {
        this.stories$.next(res.data);
      }
      this.loading = false;
    });
  }

  handleReachesRightBound(isRightBound: boolean) {
    if (isRightBound && this.totalPages > this.params.page) {
      if (!this.loading) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    this.params.page++;
    this.getData(this.params, { ids: this.selectedAuthors });
  }

  getStoriesRequest(params: SortingPageFilter, ids: AuthorsFilter): Observable<Response<StoriesListData>> {
    const queryParams = new HttpParams()
      .set('page', params.page)
      .set('size', params.size)
      .set('sort', 'publishDate,' + params.sort);

    return this.http.post<Response<StoriesListData>>(`${environment.gaiminApi}/content/stories`, ids, {
      headers: this.authService.authorizationHeader(),
      params: queryParams
    });
  }

  getStoriesByPlayerIdRequest(
    playerId: number,
    ids: AuthorsFilter,
    sort: SORTING_ORDER
  ): Observable<Response<StoriesData[]>> {
    const queryParams = new HttpParams().set('sort', sort);

    return this.http.post<Response<StoriesData[]>>(
      `${environment.gaiminApi}/content/stories/players/${playerId}`,
      ids,
      {
        headers: this.authService.authorizationHeader(),
        params: queryParams
      }
    );
  }

  updateStoriesRequest(id: number): Observable<Response<any>> {
    return this.http.put<Response<any>>(
      `${environment.gaiminApi}/content/stories/${id}/view`,
      {},
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  getStoriesDetailsRequest(id: number, sort: SORTING_ORDER): Observable<Response<StoryDetailsData>> {
    const queryParams = new HttpParams().set('sort', sort);

    return this.http.get<Response<StoryDetailsData>>(`${environment.gaiminApi}/content/stories/${id}/details`, {
      headers: this.authService.authorizationHeader(),
      params: queryParams
    });
  }

  handleStoriesReactionRequest(id: number, reaction: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.gaiminApi}/content/stories/${id}/reactions`,
      { name: reaction },
      {
        headers: this.authService.authorizationHeader(),
        context: new HttpContext().set(SHOW_LOADER, false)
      }
    );
  }

  addCommentRequest(id: number, message: string): Observable<Response<CommentsData>> {
    return this.http.post<Response<CommentsData>>(
      `${environment.gaiminApi}/content/stories/${id}/comments`,
      { message: message },
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  updateCommentRequest(id: number, commentId: number, message: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `${environment.gaiminApi}/content/stories/${id}/comments/${commentId}`,
      { message: message },
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  deleteCommentRequest(id: number, commentId: number): Observable<Response<any>> {
    return this.http.delete<Response<any>>(`${environment.gaiminApi}/content/stories/${id}/comments/${commentId}`, {
      headers: this.authService.authorizationHeader()
    });
  }

  handleCommentReactionRequest(id: number, commentId: number, reaction: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.gaiminApi}/content/stories/${id}/comments/${commentId}/reactions`,
      { name: reaction },
      {
        headers: this.authService.authorizationHeader(),
        context: new HttpContext().set(SHOW_LOADER, false)
      }
    );
  }

  deleteCommentRequestByAdmin(storyId: number, commentId: number, credential: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `${environment.gaiminApi}/content/admin/stories/${storyId}/comments/${commentId}`,
      {
        headers: new HttpHeaders({
          Authorization: `Basic ${credential}`
        })
      }
    );
  }
}
