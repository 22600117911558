<div class="donate-menu">
  <span class="icon-Close-Bold donate-menu__close" (click)="close()"></span>
  <h3>{{ messagePlayerId ? 'Send Message' : 'Donate' }}</h3>
  <form [formGroup]="form">
    <div class="donate-menu__amount">
      <div class="donate-menu__amount-field">
        <input formControlName="amount" (input)="onChangeAmount($event)" />
        <img src="assets/icons/GMRX.svg" alt="GMRX" />
      </div>
      <app-button [buttonData]="donateButtonData" [attr.disabled]="form.invalid" (click)="donate()"></app-button>
    </div>

    <div class="donate-menu__numbers">
      <div
        *ngFor="let selectableDonationAmount of selectableDonationAmounts"
        [ngClass]="{ selected: selectableDonationAmount.isSelected }"
        (click)="selectDonateAmount(selectableDonationAmount.value)"
        class="donate-menu__numbers-item">
        {{ selectableDonationAmount.value }}
      </div>
    </div>

    <textarea *ngIf="messagePlayerId" rows="3" formControlName="message" placeholder="Write a message"></textarea>

    <span
      *ngIf="form.get('message')?.hasError('minlength') || form.get('message')?.hasError('maxlength')"
      class="donate-menu__error">
      Message should have
      <mark>{{ messageMinLength }} - {{ messageMaxLength }}</mark>
      characters. You currently typed
      <mark>{{ (form.get('message')?.value).length }}</mark>
    </span>
    <span
      *ngIf="form.get('amount')?.invalid && form.get('amount')?.dirty && form.get('amount')?.hasError('min')"
      class="donate-menu__error">
      <ng-container *ngIf="messagePlayerId">
        Please donate at least {{ minAmountToDonate }} GMRX to be able to send a private message to the player.
      </ng-container>
      <ng-container *ngIf="!messagePlayerId">
        The minimum donation amount is {{ minAmountToDonate }} GMRX.
      </ng-container>
    </span>
    <span
      *ngIf="form.get('amount')?.invalid && form.get('amount')?.dirty && form.get('amount')?.hasError('max')"
      class="donate-menu__error">
      There are insufficient tokens in your wallet.
    </span>
  </form>
</div>
