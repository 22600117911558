import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { NOTIFICATION_SUBJECT_TYPE } from '../../../../shared/enums';
import { FloatingMenuService } from '../../../../shared/services/floating-menu.service';
import { NotificationService } from '../../../../shared/services/notification.service';

@Component({
  selector: 'notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss']
})
export class NotificationToastComponent extends Toast {
  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    public notificationService: NotificationService,
    public floatingMenuService: FloatingMenuService
  ) {
    super(toastrService, toastPackage);
  }

  link = this.notificationService.getLink(this.toastPackage.config.payload);

  NOTIFICATION_SUBJECT_TYPE = NOTIFICATION_SUBJECT_TYPE;

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  closeDrawer() {
    this.floatingMenuService.closeDrawer();
    this.floatingMenuService.reset();
  }
}
