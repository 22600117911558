import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import {
  ChangePasswordModalComponent
} from '../../shared/components/modals/change-password-modal/change-password-modal.component';
import { EditAvatarModalComponent } from '../../shared/components/modals/edit-avatar-modal/edit-avatar-modal.component';
import { UserService } from '../../shared/services/user.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { WalletConnectService } from '../../shared/services/wallet-connect.service';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS } from '../../shared/enums';
import { Button, DiscordTokenResponse, DiscordUserResponse, UserInfo } from '../../shared/intarfaces';
import { environment } from '../../../environments/environment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { PackagesModalComponent } from '../../shared/components/modals/packages-modal/packages-modal.component';
import { SubscriptionService } from '../../shared/services/subscription.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  protected readonly COLOSSEUM_TIERS = COLOSSEUM_TIERS;

  form: FormGroup = new FormGroup({});
  readonly saveButton: Button = { name: 'Save', size: BUTTON_SIZE.MD };
  readonly changePasswordButton: Button = {
    name: 'Change Password',
    type: BUTTON_TYPE.INPUT,
    size: BUTTON_SIZE.MD
  };
  nameMinLength: number = 2;
  nameMaxLength: number = 50;
  phoneMinLength: number = 7;
  phoneMaxLength: number = 15;
  activeTab: number = 0;

  @ViewChild('mobilePhone') mobilePhoneField: ElementRef | null = null;

  @AutoUnsubscribe()
  userInfoSub: Subscription | undefined;

  constructor(
    public additionalService: AdditionalService,
    public userService: UserService,
    public authService: AuthService,
    public walletConnectService: WalletConnectService,
    private toastrService: ToastrService,
    private dialogService: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    public subsService: SubscriptionService
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  async ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('code')) {
      const code = this.route.snapshot.queryParamMap.get('code');
      this.userService.getDiscordToken(code!).subscribe({
        next: (tokenResponse: DiscordTokenResponse) => {
          this.location.replaceState('/profile');
          if (tokenResponse.access_token) {
            this.userService.getDiscordUserData(tokenResponse.access_token).subscribe({
              next: (userResponse: DiscordUserResponse) => {
                if (userResponse.username) {
                  const avatarUrl = userResponse.avatar
                    ? `https://cdn.discordapp.com/avatars/${userResponse.id}/${userResponse.avatar}.png`
                    : '';

                  this.userService.userInfo$.pipe(takeWhile((data) => !data.id, true)).subscribe((data) => {
                    if (data.id) {
                      const tempProfile = {
                        ...data,
                        discordId: userResponse.id,
                        discordUsername: userResponse.username,
                        discordAvatarUrl: avatarUrl
                      };

                      this.updateUserProfile(tempProfile, true);
                    }
                  });
                } else {
                  this.toastrService.error('Can\'t connect to Discord.');
                }
              }
            });
          } else {
            this.toastrService.error('Can\'t connect to Discord.');
          }
        },
        error: () => {
          this.location.replaceState('/profile');
        }
      });
    }

    this.userInfoSub = this.userService.userInfo$.subscribe((data) => {
      this.form = new FormGroup({
        id: new FormControl(data.id, [Validators.required]),
        firstName: new FormControl(data.firstName, [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z- ]*$'),
          this.additionalService.regexValidatorV2(/\s{2,}/, { complexity: true })
        ]),
        lastName: new FormControl(data.lastName, [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z- ]*$'),
          this.additionalService.regexValidatorV2(/\s{2,}/, { complexity: true })
        ]),
        email: new FormControl(data.email, [Validators.required]),
        nickName: new FormControl(data.nickName, [
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z0-9%!&-_,]*$')
        ]),
        mobilePhone: new FormControl(data.mobilePhone, [
          Validators.minLength(this.phoneMinLength),
          Validators.maxLength(this.phoneMaxLength),
          Validators.pattern('^\\+[0-9]*$')
        ])
      });
      this.form.get('id')!.disable();
      this.form.get('email')!.disable();
      this.form.markAllAsTouched();
    });

    if (this.walletConnectService.isWalletConnected$.getValue()) {
      await this.subsService.getRemainingLockDays(this.walletConnectService.walletAddress!);
      await this.subsService.getStakeTier(this.walletConnectService.walletAddress!);
      this.subsService.userCurrentTier
        ? this.authService.acquireAccount(false, this.subsService.userCurrentTier! as COLOSSEUM_TIERS)
        : null;
    }
  }

  onKeyup(value?: unknown) {
    const previousValue = this.form.value.mobilePhone;
    if (previousValue.length > 0 && previousValue[0] !== '+' && previousValue.indexOf('+') < 0) {
      this.form.get('mobilePhone')?.setValue('+' + previousValue);
    }
  }

  onMobilePhoneClick() {
    if (!this.form.value.mobilePhone || this.form.value.mobilePhone === '') {
      this.form.get('mobilePhone')?.setValue('+');
    }
  }

  onSubmit() {
    if (this.form.valid) {
      let nickNameValue = this.form.value.nickName;
      if (this.userService.userInfo$.getValue().nickName == null && nickNameValue === '') {
        nickNameValue = null;
      }
      const tempProfile = { ...this.userService.userInfo$.getValue(), ...this.form.value };
      tempProfile.nickName = nickNameValue;
      delete tempProfile.avatarUrl;
      this.updateUserProfile(tempProfile);
    }
  }

  updateUserProfile(userData: UserInfo, isDiscord: boolean = false) {
    this.userService.updateUserInfo(userData).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.userService.userInfo$.next(response.data);
          if (!isDiscord) {
            this.toastrService.success('Profile updated successfully.');
          }
        } else if (response?.error?.description === 'Nickname is not unique') {
          this.form?.get?.('nickName')?.setErrors({
            notUnique: true
          });
        } else {
          this.handleError();
        }
      },
      error: () => {
        this.handleError();
      }
    });
  }

  handleError() {
    this.toastrService.error('Unable to update the profile data.');
  }

  onAvatarEdit() {
    this.dialogService.open(EditAvatarModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }

  changePassword(event: any) {
    event.preventDefault();
    this.dialogService.open(ChangePasswordModalComponent, {
      panelClass: 'dialog-overlay-pane',
      data: { oktaId: this.userService.userInfo$.getValue().oktaId }
    });
  }

  connectToDiscord() {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${environment.discordClientId}&redirect_uri=${environment.discordRedirectUri}&response_type=code&scope=identify&prompt=none`;
  }

  disconnectToDiscord() {
    const tempProfile = {
      ...this.userService.userInfo$.getValue(),
      discordId: 0,
      discordUsername: '',
      discordAvatarUrl: ''
    };
    this.updateUserProfile(tempProfile, true);
  }

  setActiveTab(tabIndex: number): void {
    this.activeTab = tabIndex;
  }

  @HostListener('document:click', ['$event']) onClick(event: any) {
    if (!this.mobilePhoneField?.nativeElement.contains(event.target)) {
      if (this.form.value.mobilePhone === '+') {
        this.form.get('mobilePhone')?.setValue('');
      }
    }
  }

  packagesModalOpen() {
    this.dialogService.open(PackagesModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-packages']
    });
  }
}
