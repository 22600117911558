<div class="packages-modal">
  <div class="header">
    <h2>Subscription management</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>
  <div class="grid">
    <div class="grid__col" *ngFor="let tier of tiers; trackBy: trackByFn">
      <div class="grid__col__data">
        <h2>{{ tier.name }} <span *ngIf="tier.name === 'Silver'">(Free)</span></h2>
        <div class="grid__col__data-price" [ngClass]="{ line: tier.name.includes('Silver') }">
          <span class="currency" *ngIf="tier.currency">{{ tier.currency }}</span>
          <span class="price">{{ tier.price }}</span>
          <span class="period" *ngIf="tier.currency">/worth of GMRX</span>
        </div>
        <p class="price-description">{{ tier.priceDesciption }}</p>
        <img [src]="'assets/icons/packages/' + tier.name.toLowerCase() + '.png'" alt="" />
        <app-button (click)="tier.action()" [buttonData]="tier.buttonData"></app-button>
      </div>

      <div class="grid__col__description">{{ tier.description }}</div>
    </div>
  </div>
</div>
