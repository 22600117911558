import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Input, Output } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ContentService } from '../../services/content.service';
import { PlayerConversation, Button } from '../../intarfaces';
import { BUTTON_SIZE } from '../../enums';

@Component({
  selector: 'app-player-messages-list',
  templateUrl: './player-messages-list.component.html',
  styleUrls: ['./player-messages-list.component.scss']
})
export class PlayerMessagesListComponent implements OnInit {
  @Output() openDonate = new EventEmitter<any>();
  @Output() closeMessages = new EventEmitter<any>();
  @Input() playerId!: number;

  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;

  @AutoUnsubscribe()
  private playerSub: Subscription = {} as Subscription;

  messagesList$: BehaviorSubject<PlayerConversation[] | null> = new BehaviorSubject<PlayerConversation[] | null>(null);

  readonly openDonatePopoverBtn: Button = {
    name: 'Send Message',
    size: BUTTON_SIZE.MD
  };

  constructor(public contentService: ContentService) {}

  ngOnInit(): void {
    this.playerSub = this.contentService.getPlayerMessagesByIdRequest(this.playerId).subscribe((res) => {
      if (res.success && res.data) {
        this.messagesList$.next(res.data);
        this.scrollToBottom();
      }
    });
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    }, 0);
  }

  close() {
    this.closeMessages.emit();
  }

  onOpenDonate(event: any) {
    this.openDonate.emit(event);
  }
}
