<div class="channel">
  <h2 class="back-title">
    <div (click)="additionalService.backClicked()">
      <span class="icon icon-Arrow-Left"></span> {{ channelData.channelName }} stream
    </div>
  </h2>
  <div class="channel-header">
    <div class="channel-header__name">
      <img *ngIf="channelData.iconUrl" [src]="channelData.iconUrl" alt="" />
      <h3>{{ channelData.streamerName }}</h3>
    </div>
    <app-streams-claim-reward></app-streams-claim-reward>
    <div class="channel-header__donate" appClickedOutside (clickOutside)="closeDonatePopover()">
      <app-button
        (click)="openDonatePopover()"
        [buttonData]="openDonatePopoverBtn"
        *ngIf="channelData.wallets"></app-button>
      <app-donate-popover
        [wallets]="channelData.wallets"
        *ngIf="walletConnectService.isDonatePopoverOpen"></app-donate-popover>
    </div>
  </div>

  <div class="channel-twitch">
    <div class="channel-twitch__player" [ngClass]="{ collapsed: !isChatOpen }">
      <div id="twitchPlayer"></div>
      <span
        class="toggle-icon icon-Chat-Collapse"
        [ngClass]="{ collapsed: !isChatOpen }"
        (click)="toggleChatOpen()"></span>
    </div>

    <div class="channel-twitch__chat" *ngIf="channelName" [hidden]="!isChatOpen">
      <iframe
        height="100%"
        title=""
        [src]="
          'https://www.twitch.tv/embed/' +
            channelName +
            '/chat?parent=localhost&parent=colosseum.qa.gaimingladiators.gg&parent=colosseum.gaimingladiators.gg&darkpopout'
            | safeUrl
        "></iframe>
    </div>
  </div>
</div>
<turnstile-captcha
  (token)="turnstileService.handleToken($event)"
  (error)="turnstileService.handleError($event)"
  (expired)="turnstileService.handleExpired()"></turnstile-captcha>
