import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Button } from '../../../intarfaces';
import { BUTTON_SIZE } from '../../../enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionService } from '../../../services/subscription.service';

@Component({
  selector: 'subscription-confirmation-modal',
  templateUrl: './subscription-confirmation-modal.component.html',
  styleUrls: ['./subscription-confirmation-modal.component.scss']
})
export class SubscriptionConfirmationModalComponent {
  @Output() confirmAction: EventEmitter<any> = new EventEmitter<any>();

  readonly confirmBtn: Button = { size: BUTTON_SIZE.LG };
  public headerMove: string;
  public tierName: string;

  constructor(
    private dialogRef: MatDialogRef<SubscriptionConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public susbsService: SubscriptionService
  ) {
    this.headerMove = data[1] ?? 'upgrade';
    this.confirmBtn.name = data[1] ? `Confirm ${data[1]}` : 'Confirm Upgrade';
    this.tierName = data[0];
  }

  closeModal() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close();
    this.confirmAction.emit();
  }
}
