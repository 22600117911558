import { Component } from '@angular/core';
import { NotificationService } from '../../../../shared/services/notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent {
  constructor(public notificationService: NotificationService) { }
}
