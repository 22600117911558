import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { EditCommentModalComponent } from '../modals/edit-comment-modal/edit-comment-modal.component';
import { UserService } from '../../services/user.service';
import { Button, CommentsData, ReactionsListData, Response } from '../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE, POPOVER_TYPE, SORTING_ORDER, USER_ROLES } from '../../enums';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent {
  @ViewChild('messageInput') messageInput!: ElementRef;
  @Output() changeSorting = new EventEmitter<any>();
  @Input() comments: CommentsData[] = [];
  @Input() id!: number;
  @Input() service: any;

  @AutoUnsubscribe()
  private commentSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private reactionsSub: Subscription = {} as Subscription;

  form = new FormGroup({
    message: new FormControl('', [Validators.max(2500), Validators.required])
  });
  sortingOrder: SORTING_ORDER = SORTING_ORDER.DESC;
  popoverPosition: POPOVER_TYPE = POPOVER_TYPE.CENTER;
  popoverListPosition: POPOVER_TYPE = POPOVER_TYPE.CENTER_RIGHT;

  readonly submitDesktopBtn: Button = {
    name: 'Send',
    size: BUTTON_SIZE.AUTO,
    type: BUTTON_TYPE.GREY
  };

  readonly submitMobileBtn: Button = {
    iconLeft: 'icon-Send',
    size: BUTTON_SIZE.AUTO,
    type: BUTTON_TYPE.GREY
  };

  protected readonly USER_ROLES = USER_ROLES;

  constructor(
    public userService: UserService,
    private dialogService: MatDialog,
    public authService: AuthService
  ) {}

  onChangeSortingOrder(event: MatSelectChange) {
    this.sortingOrder = event.value;
    this.changeSorting.emit(this.sortingOrder);
  }

  onSelectReaction(reaction: ReactionsListData, commentId: number) {
    this.reactionsSub = this.service.handleCommentReactionRequest(this.id!, commentId, reaction.name).subscribe();
  }

  onAddReactionToMessage(reaction: EmojiData) {
    let message = this.form.value.message || '';
    const inputElement = this.messageInput.nativeElement as HTMLInputElement;
    const startPos = inputElement.selectionStart || 0;
    const endPos = inputElement.selectionEnd || 0;
    message = message.slice(0, startPos) + reaction.native + message.slice(endPos);
    this.form.get('message')?.setValue(message);
  }

  onAddComment() {
    const { message } = this.form.value;

    this.commentSub = this.service.addCommentRequest(this.id, message!).subscribe((res: Response<CommentsData>) => {
      if (res.success && res.data) {
        if (this.sortingOrder == SORTING_ORDER.ASC) {
          this.comments.push(res.data);
        } else {
          this.comments.unshift(res.data);
        }
      }
    });

    this.form.reset();
  }

  onEditComment(comment: CommentsData) {
    const dialogRef = this.dialogService.open(EditCommentModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-form-edit'],
      data: { message: comment.message }
    });

    dialogRef.componentRef?.instance.confirmRequest.subscribe((message: string) => {
      if (comment.commentId) {
        this.service.updateCommentRequest(this.id, comment.commentId, message).subscribe({
          next: (response: Response<any>) => {
            if (response.success) {
              const index = this.comments.findIndex((item) => item.commentId === comment.commentId);
              if (index !== -1) {
                const updatedComment = { ...this.comments[index], message: message };
                this.comments.splice(index, 1, updatedComment);
              }
            }
          }
        });
      }
    });
  }

  onRemoveComment(comment: CommentsData) {
    const dialogRef = this.dialogService.open(ConfirmationModalComponent, {
      panelClass: 'dialog-overlay-pane',
      data: { itemName: 'comment' }
    });

    dialogRef.componentRef?.instance.confirmRequest.subscribe(() => {
      if (comment.commentId) {
        const isManager = this.authService.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER]);
        const credential = isManager
          ? this.authService.managerEncodedCreds$.getValue()! || this.authService.managerToken
          : null;
        this.deleteComment(isManager, credential, comment);
      }
    });
  }

  deleteComment(isManager: boolean, credential: string | null, comment: CommentsData) {
    const deleteCommentObservable = isManager
      ? this.service.deleteCommentRequestByAdmin(this.id, comment.commentId, credential!)
      : this.service.deleteCommentRequest(this.id, comment.commentId);

    deleteCommentObservable.subscribe({
      next: (response: Response<any>) => {
        if (response.success) {
          const index = this.comments.findIndex((item) => item.commentId === comment.commentId);
          if (index !== -1) {
            this.comments.splice(index, 1);
          }
        }
      }
    });
  }
}
