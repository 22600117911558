import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ContentPageComponent } from './pages/content-page/content-page.component';
import { StorePageComponent } from './pages/store-page/store-page.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { NftMarketPageComponent } from './pages/nft-market-page/nft-market-page.component';
import { FanAreaPageComponent } from './pages/fan-area-page/fan-area-page.component';
import { AuctionPageComponent } from './pages/auction-page/auction-page.component';
import { PlayersPageComponent } from './pages/players-page/players-page.component';
import { PlayersListComponent } from './pages/players-page/players-list/players-list.component';
import { PlayerPageComponent } from './pages/players-page/players-list/player-page/player-page.component';
import { StreamsPageComponent } from './pages/streams-page/streams-page.component';
import { ChannelListComponent } from './pages/streams-page/channel-list/channel-list.component';
import { ChannelPageComponent } from './pages/streams-page/channel-list/channel-page/channel-page.component';
import { VideosPageComponent } from './pages/videos-page/videos-page.component';
import { VideosListComponent } from './pages/videos-page/videos-list/videos-list.component';
import { VideoPageComponent } from './pages/videos-page/videos-list/video-page/video-page.component';
import { CharityPageComponent } from './pages/charity-page/charity-page.component';
import { ArticlePageComponent } from './pages/article-page/article-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';
import { FaqsPageComponent } from './pages/faqs-page/faqs-page.component';
import { PackagesPageComponent } from './pages/packages-page/packages-page.component';
import { EventsClaimPageComponent } from './pages/events-claim-page/events-claim-page.component';

import { AuthGuard } from './guards/auth.guard';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HomePageComponent, pathMatch: 'full' },
      {
        path: 'login/callback',
        component: OktaCallbackComponent
      },
      {
        path: 'discord/callback',
        component: ProfilePageComponent
      },
      {
        path: 'signin',
        component: HomePageComponent
      },
      {
        path: 'signup',
        component: HomePageComponent
      },
      {
        path: 'content',
        component: ContentPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfilePageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'store',
        component: StorePageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'rewards',
        component: RewardsPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nft-market',
        component: NftMarketPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'fan-area',
        component: FanAreaPageComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'auction',
      //   component: AuctionPageComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'players',
        component: PlayersPageComponent,
        canActivate: [AuthGuard],
        pathMatch: 'prefix',
        children: [
          { path: '', pathMatch: 'prefix', component: PlayersListComponent },
          { path: ':id', component: PlayerPageComponent }
        ]
      },
      {
        path: 'notifications',
        component: NotificationsPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'streams',
        component: StreamsPageComponent,
        canActivate: [AuthGuard],
        pathMatch: 'prefix',
        children: [
          { path: '', pathMatch: 'prefix', component: ChannelListComponent },
          { path: ':channel', component: ChannelPageComponent }
        ]
      },
      {
        path: 'videos',
        component: VideosPageComponent,
        pathMatch: 'prefix',
        children: [
          { path: '', pathMatch: 'prefix', component: VideosListComponent, canActivate: [AuthGuard] },
          { path: ':id', component: VideoPageComponent }
        ]
      },
      {
        path: 'article/:id',
        component: ArticlePageComponent
      },
      {
        path: 'charity',
        component: CharityPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'terms',
        component: TermsPageComponent
      },
      {
        path: 'policy',
        component: PolicyPageComponent
      },
      {
        path: 'faqs',
        component: FaqsPageComponent
      },
      {
        path: 'packages',
        component: PackagesPageComponent
      },
      {
        path: 'events/dpa-claim',
        component: EventsClaimPageComponent
      },
      { path: '**', redirectTo: '/content', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
