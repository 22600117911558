import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { filter, Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AdditionalService } from '../../shared/services/additional.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent implements OnInit {
  @AutoUnsubscribe()
  private notificationsSub: Subscription = {} as Subscription;

  constructor(private additionalService: AdditionalService, private notificationService: NotificationService, private toastrService: ToastrService) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.toastrService.clear();
    this.notificationsSub = this.notificationService.hasNewNotifications$.pipe(filter(value => value)).subscribe(() => {
      timer(5000).subscribe(() => {
        this.notificationService.readNotifications().subscribe(() => {
          this.notificationService.hasNewNotifications$.next(false);

          this.notificationService.getNotifications().subscribe();
        })
      });
    });
  }
}
