import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string): string {
    const currentDate = new Date();
    const publishDate = new Date(value + 'Z');

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - publishDate.getTime();

    // If less than 1 hour ago
    if (timeDifference < 60 * 60 * 1000) {
      const minutesAgo = Math.floor(timeDifference / (60 * 1000));
      return minutesAgo == 1 ? `${minutesAgo} Minute ago` : `${minutesAgo} Minutes ago`;
    }
    // If less than 1 day ago
    else if (timeDifference < 24 * 60 * 60 * 1000) {
      const hoursAgo = Math.floor(timeDifference / (60 * 60 * 1000));
      return hoursAgo == 1 ? `${hoursAgo} Hour ago` : `${hoursAgo} Hours ago`;
    } else {
      // Format the date as "DD.MM.YY"
      return new DatePipe('en-US').transform(publishDate, 'dd.MM.yy') ?? '';
    }
  }
}
