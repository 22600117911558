import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BannerData } from '../../intarfaces';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input() banners: BannerData[] = [];

  customOptions: OwlOptions = {
    items: 1,
    loop: true,
    autoWidth: true,
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 12,
    navSpeed: 700,
    navText: ['<span class="icon-Arrow-Left"></span>', '<span class="icon-Arrow-Right"></span>'],
    startPosition: 1,
    responsive: {
      // it's slider container width, not the screen width
      0: {
        items: 1.08,
        nav: false
      },
      441: {
        items: 1,
        nav: true
      }
    }
  };

  constructor() {}

  ngOnInit(): void {}
}
