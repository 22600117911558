<div class="reactions" appClickedOutside (clickOutside)="closeReactionsPopover()">
  <span
    class="reactions__icon icon-Add-Reaction"
    [ngClass]="{ active: isReactionsPopoverVisible }"
    (click)="showReactionsPopover()"></span>
  <div class="reactions__selected">
    <ng-container *ngFor="let item of sortedReactions">
      <div
        *ngIf="item.count > 0"
        class="reactions__selected-item"
        [ngClass]="{ selected: item.selected }"
        (click)="onSelectReaction(item)">
        <ngx-emoji [emoji]="item.name" [size]="20" [set]="reactionSet"></ngx-emoji>
        <span>{{ item.count }}</span>
      </div>
    </ng-container>
  </div>
  <div class="reactions__popover" [ngClass]="[isReactionsPopoverVisible ? 'visible' : '', 'position-' + position]">
    <div class="reactions__list">
      <ngx-emoji
        *ngFor="let item of reactions"
        [emoji]="item.name"
        [size]="reactionSize"
        [set]="reactionSet"
        (emojiClick)="onSelectReaction(item)"></ngx-emoji>
    </div>
  </div>
</div>
