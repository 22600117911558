<div class="share">
  <div class="share__header">
    <h2>Share</h2>
    <span class="icon-Close close-icon" title="Close" (click)="onClose()"></span>
  </div>
  <div class="share__content">
    <div class="share__content-btns">
      <ng-container *ngFor="let item of buttons">
        <div
          class="share__content-btns__item"
          [shareButton]="item.name"
          [title]="metaTags?.title || ''"
          [description]="metaTags?.description || ''"
          [image]="metaTags?.image || ''"
          (opened)="sendUserActivity()">
          <span [ngClass]="item.icon" [ngStyle]="{ '--hover-color': item.color }"></span> {{ item.name }}
        </div>
      </ng-container>
    </div>
    <div class="share__content-form">
      <div class="share__content-form__field">
        <input [value]="shareLinkUrl" readonly />
      </div>
      <app-button
        [buttonData]="copyShareLinkBtn"
        shareButton="copy"
        #copyBtn="shareButton"
        (click)="onCopy()"></app-button>
    </div>
  </div>
</div>
