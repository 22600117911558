import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TwitchService } from '../../../services/twitch.service';
import { WalletConnectService } from '../../../services/wallet-connect.service';
import { StreamingClaimReward, Button } from '../../../intarfaces';
import { BUTTON_SIZE } from '../../../enums';

@Component({
  selector: 'claim-reward-modal',
  templateUrl: './claim-reward-modal.component.html',
  styleUrls: ['./claim-reward-modal.component.scss']
})
export class ClaimRewardModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ClaimRewardModalComponent>,
    private twitchService: TwitchService,
    public walletConnectService: WalletConnectService
  ) {}

  readonly submitBtn: Button = { name: 'Claim', size: BUTTON_SIZE.LG };

  form = new FormGroup({
    wallet: new FormControl(this.walletConnectService.walletAddress, [
      Validators.required,
      Validators.pattern(/^0x[0-9A-Fa-f]{40}$/)
    ])
  });

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.valid) {
      const data: StreamingClaimReward = {
        wallet: this.form.get('wallet')?.value as string,
        chain: this.twitchService.withdrawalOptions$.getValue().chain
      };
      this.twitchService.claimGMRX(data);
      this.closeModal();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
