import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { OrderData, Product, Response } from '../intarfaces';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { COLOSSEUM_TIERS } from '../enums';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  private userSubscriptionTypeSubs: Subscription | undefined;

  /**
   * Sends a GET request to fetch products.
   *
   * @return {Observable<Response<Product[]>>} - An Observable of type Response<Product[]>.
   */
  getProducts(): Observable<Response<Product[]>> {
    return this.http.get<Response<Product[]>>(`${environment.shopifyApi}/shopify/getProducts`, {});
  }

  /**
   * Sends a POST request to create an order.
   *
   * @param {OrderData} orderData - The order data to be sent in the body of the request.
   *
   * @return {Observable<OrderData>} - An Observable of type OrderData.
   */
  createOrder(orderData: OrderData): Observable<OrderData> {
    return this.http.post<OrderData>(`${environment.shopifyApi}/shopify/order`, { order: orderData });
  }

  /**
   * Sends a POST request to send an invoice.
   *
   * @param {string | undefined} orderId - The order ID to be sent in the body of the request.
   * @param {string} userEmail - The user email to be sent in the body of the request.
   *
   * @return {Observable<Response<any>>} - An Observable of type Response<any>.
   */
  sendInvoice(orderId: string | undefined, userEmail: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${environment.shopifyApi}/shopify/orderInvoice`, { orderId, userEmail });
  }

  /**
   * Calculates a new price based on the user's status.
   *
   * @param {string} originalPrice - The original price.
   * @param {string} memberStatus - The user's status.
   *
   * @return {number} - The new price considering the discount.
   */
  calculateNewPrice(originalPrice: string, memberStatus: string): number {
    let amountPercent = 0;
    switch (memberStatus) {
      case COLOSSEUM_TIERS.GOLD:
        amountPercent = 0.1;
        break;
      case COLOSSEUM_TIERS.PLATINUM:
        amountPercent = 0.25;
        break;
    }
    const discountAmount = Number(originalPrice) * amountPercent;
    return Number(originalPrice) - discountAmount;
  }

  /**
   * Updates prices based on the user's subscription type.
   * Sorts the array of prices and puts the active one at the first place.
   *
   * @param {any[]} prices - Array of prices.
   */
  updatePrices(prices: any[]) {
    this.userSubscriptionTypeSubs = this.userService.userSubscriptionData$.subscribe((userSubscriptionData) => {
      prices.forEach((price) => {
        price.active = price.type === userSubscriptionData.type;
      });
      prices.sort((a, b) => (b.active ? 1 : 0) - (a.active ? 1 : 0));
    });
  }

  /**
   * Unsubscribes from subscriptions.
   */
  unsubscribe() {
    this.userSubscriptionTypeSubs?.unsubscribe();
  }
}
