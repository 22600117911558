import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdditionalService } from '../../shared/services/additional.service';

@Component({
  selector: 'terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent implements OnInit, OnDestroy {
  constructor(private additionalService: AdditionalService) {
    document.body.className = 'home-page-template';
    this.additionalService.isHomePageTemplate$.next(true);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    document.body.className = '';
    this.additionalService.isHomePageTemplate$.next(false);
  }
}
