import { Component, OnInit } from '@angular/core';
import { Button, SubscriptionTypeData, TierPackages } from '../../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS } from '../../../enums';
import { AuthService } from '../../../services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { UserService } from '../../../services/user.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { WalletConnectService } from '../../../services/wallet-connect.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'packages-modal',
  templateUrl: './packages-modal.component.html',
  styleUrls: ['./packages-modal.component.scss']
})
export class PackagesModalComponent implements OnInit {
  @AutoUnsubscribe()
  private userSubscriptionDataSubs: Subscription | undefined;

  protected userSubscriptionData: SubscriptionTypeData = {} as SubscriptionTypeData;

  readonly signUpBtn: Button = {
    name: 'Upgrade',
    size: BUTTON_SIZE.MD
  };

  readonly downgradeBtn: Button = {
    name: 'Downgrade',
    size: BUTTON_SIZE.MD
  };

  readonly unsubscribeBtn: Button = {
    name: 'Unsubscribe',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK_FILLED
  };

  protected tiers: TierPackages[] = [];
  protected readonly COLOSSEUM_TIERS = COLOSSEUM_TIERS;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public walletConnectService: WalletConnectService,
    private dialogRef: MatDialogRef<PackagesModalComponent>,
    private subsService: SubscriptionService,
    private dialogService: MatDialog,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.userSubscriptionDataSubs = this.userService.userSubscriptionData$.subscribe({
      next: (response) => {
        this.userSubscriptionData = response;
      }
    });

    this.tiers = [
      {
        name: 'Bronze',
        currency: null,
        price: 'Free',
        priceDesciption: '',
        action: () => this.unsubscribe(COLOSSEUM_TIERS.BRONZE),
        buttonData: this.downgradeBtn,
        description: 'Sign up now and access essential features & benefits for FREE.'
      },
      {
        name: 'Silver',
        currency: '$',
        price: '5',
        priceDesciption: '+ $30 worth of GMRX Yearly Activation Fee (First Year is FREE)',
        action: () =>
          this.userSubscriptionData.type === COLOSSEUM_TIERS.SILVER
            ? this.unsubscribe(COLOSSEUM_TIERS.SILVER)
            : this.upgradeSubscription(COLOSSEUM_TIERS.SILVER),
        buttonData:
          this.userSubscriptionData.type === COLOSSEUM_TIERS.GOLD ||
          this.userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM
            ? this.downgradeBtn
            : this.userSubscriptionData.type === COLOSSEUM_TIERS.SILVER
              ? this.unsubscribeBtn
              : this.signUpBtn,
        description:
          'Unlock Silver-Tier Experiences – View exclusive content, donate & earn GMRX tokens, and many more.'
      },
      {
        name: 'Gold',
        currency: '$',
        price: '25',
        priceDesciption: '+ $30 worth of GMRX Yearly Activation Fee (First Year is FREE)',
        action: () =>
          this.userSubscriptionData.type === COLOSSEUM_TIERS.GOLD
            ? this.unsubscribe()
            : this.upgradeSubscription(COLOSSEUM_TIERS.GOLD),
        buttonData:
          this.userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM
            ? this.downgradeBtn
            : this.userSubscriptionData.type === COLOSSEUM_TIERS.GOLD
              ? this.unsubscribeBtn
              : this.signUpBtn,
        description:
          'Unlock Gold-Tier Experiences - Exclusive content, GMRX rewards, 10% shop discount & other unique perks.'
      },
      {
        name: 'Platinum',
        currency: '$',
        price: '100',
        priceDesciption: '+ $30 worth of GMRX Yearly Activation Fee (First Year is FREE)',
        action: () =>
          this.userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM
            ? this.unsubscribe()
            : this.upgradeSubscription(COLOSSEUM_TIERS.PLATINUM),
        buttonData: this.userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM ? this.unsubscribeBtn : this.signUpBtn,
        description:
          'Unlock Platinum-Tier Experiences – The Ultimate perk list, 25% shop discount, exclusive experiences, direct communication, and many more.'
      }
    ];
  }

  trackByFn(index: number, item?: TierPackages) {
    return index;
  }

  async upgradeSubscription(tierType: COLOSSEUM_TIERS) {
    const walletAddress = this.walletConnectService.walletAddress;

    try {
      if (!this.walletConnectService.isWalletConnected$.getValue()) {
        return this.openWalletConnect();
      }

      if (tierType === COLOSSEUM_TIERS.SILVER) {
        return this.authService.acquireAccount(true, tierType);
      }

      await this.subsService.getRemainingLockDays(walletAddress!);
      const unlockedDays = this.subsService.unlockedDays as number;

      if (unlockedDays > 0) {
        const tier: TierPackages | undefined = this.tiers.find(
          (tier) => tier.name.toLowerCase() === tierType.toLowerCase()
        );
        await this.subsService.openUpgradePopup(tierType, walletAddress!, tier?.buttonData.name);
      } else {
        await this.subsService.subscribeProcess(tierType, walletAddress!);
      }
    } finally {
      this.closeModal();
      this.walletConnectService.updateWalletBalances();
    }
  }

  async unsubscribe(tierType?: COLOSSEUM_TIERS) {
    try {
      if (!this.walletConnectService.isWalletConnected$.getValue()) {
        return this.openWalletConnect();
      }

      if (tierType === COLOSSEUM_TIERS.SILVER) {
        if (this.subsService.unlockedDays === 0n) {
          return await this.handleUnsubscribeRequest();
        }
      }

      if (tierType === COLOSSEUM_TIERS.BRONZE) {
        await this.subsService.getStakeBalance(this.walletConnectService.walletAddress!);
        if (this.subsService.unlockedDays === 0n) {
          return await this.subsService.unsubscribeFrom();
        } else {
          return this.toastrService.warning(
            'You can not downgrade to BRONZE subscription  because the lock days is not expired'
          );
        }
      }

      return await this.subsService.unsubscribeFrom();
    } finally {
      this.closeModal();
      this.walletConnectService.updateWalletBalances();
    }
  }

  private async handleUnsubscribeRequest() {
    await this.subsService.unsubscribeRequest();
  }

  closeModal() {
    this.dialogRef.close();
  }

  openWalletConnect() {
    this.walletConnectService.openModal();
    this.walletConnectService.subscribeModalSate().subscribe((open) => {
      if (!open) {
        this.dialogService.open(PackagesModalComponent, {
          panelClass: ['dialog-overlay-pane', 'dialog-packages']
        });
      }
    });
  }
}
