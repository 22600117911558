<div class="videos__wrapper" *ngIf="videosService.videos$ | async as videosSub">
  <h2 class="back-title">
    <div (click)="goToContent()"><span class="icon icon-Arrow-Left"></span> Videos</div>
  </h2>

  <div class="actions">
    <app-filter-authors
      [params]="videosService.params"
      (selectAuthors)="onAuthorsSelectionChange($event)"></app-filter-authors>
    <app-filter-sorting
      [selectedSorting]="videosService.params.sort"
      (changeSorting)="onChangeSortingOrder($event)"></app-filter-sorting>
  </div>

  <div class="videos" *ngIf="videosSub.length > 0">
    <div
      class="videos__item"
      [ngClass]="{ locked: !contentService.isContentVisible(item.tier) }"
      *ngFor="let item of videosSub; let i = index"
      (click)="contentService.isContentVisible(item.tier) ? navigateTo(item.id) : false"
      (auxclick)="contentService.isContentVisible(item.tier) ? openInNewTab(item.id) : false">
      <div class="videos__item-wrapper">
        <div class="video-wrapper">
          <img *ngIf="item.thumbnailUrl" class="thumbnail" [src]="item.thumbnailUrl" alt="" />
          <span *ngIf="item.videoUrl" class="play icon-Play"></span>
          <div *ngIf="!contentService.isContentVisible(item.tier)" (click)="goToPackages()" class="unlock">
            <span>Unlock</span>
            <span class="icon icon-Arrow-Down-Left"></span>
          </div>
          <div class="author">
            <img [src]="item.author.avatarUrl" alt="" />
            <p>{{ item.author.name }}</p>
          </div>
        </div>
        <div class="info">
          <span class="name">{{ item.name }}</span>
          <span class="description">{{ item.description }}</span>
        </div>
      </div>
    </div>
  </div>
  <app-button
    *ngIf="videosService.totalPages > videosService.params.page"
    (click)="loadMore()"
    [buttonData]="loadMoreBtn"></app-button>
  <app-empty *ngIf="videosSub.length == 0"></app-empty>
</div>
