<div class="change-password">
  <div class="header">
    <h2>Change your password</h2>
    <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
  </div>

  <div class="modal-content">
    <form [formGroup]="form">
      <label class="change-password__label" for="oldPassword">
        Old Password

        <password-input formControlName="oldPassword" [id]="'oldPassword'"></password-input>

        <div>
          <ng-container *ngIf="form.get('oldPassword')!.invalid && form.get('oldPassword')!.dirty">
            <span *ngIf="form.get('oldPassword')!.hasError('required')" class="change-password__field__error">
              Old Password should be present
            </span>
          </ng-container>
        </div>
      </label>

      <label class="change-password__label" for="newPassword">
        New Password

        <password-input formControlName="newPassword" [id]="'newPassword'"></password-input>

        <div>
          <ng-container *ngIf="form.get('newPassword')!.invalid && form.get('newPassword')!.dirty">
            <span *ngIf="form.get('newPassword')!.hasError('required')" class="change-password__field__error">
              New Password should be present
            </span>

            <ng-container
              *ngIf="
                form.get('newPassword')!.hasError('minlength') || form.get('newPassword')!.hasError('maxlength');
                else password_complexity
              ">
              <span class="change-password__field__error">
                New Password should have <mark>{{ passwordMinLength }} - {{ passwordMaxLength }}</mark> characters. You
                currently typed
                <mark>{{ form.get('newPassword')!.value!.length }}</mark>
              </span>
            </ng-container>

            <ng-template #password_complexity>
              <ng-container *ngIf="form.get('newPassword')!.hasError('complexity'); else password_pattern">
                <span class="change-password__field__error"
                  >New Password should contain at least 1 lowercase character, 1 uppercase character, 1 digit, 1
                  symbol</span
                >
              </ng-container>
            </ng-template>

            <ng-template #password_pattern>
              <ng-container *ngIf="form.get('newPassword')!.hasError('pattern')">
                <span class="change-password__field__error"
                  >New Password can have latin characters, digits and symbols</span
                >
              </ng-container>
            </ng-template>
          </ng-container>
        </div>
      </label>

      <app-button (click)="onSubmit()" [attr.disabled]="form.invalid" [buttonData]="submitBtn"></app-button>
    </form>
  </div>
</div>
