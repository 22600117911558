import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { ScrollService } from '../../services/scroll.service';
import { ContentService } from '../../services/content.service';
import { VideosService } from '../../services/videos.service';
import { SortingPageFilter, AuthorsFilter } from '../../intarfaces';
import { SORTING_ORDER } from '../../enums';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit, OnDestroy {
  @Input() playerId: number | null = null;

  params: SortingPageFilter = {
    page: 1,
    size: 4,
    sort: SORTING_ORDER.DESC
  };

  contentPage: boolean = false;
  isMobile = false;

  constructor(
    private scrollService: ScrollService,
    public contentService: ContentService,
    public videosService: VideosService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
  }

  ngOnInit(): void {
    this.contentPage = !this.playerId;
    this.getData(this.params, { ids: [] });
  }

  getData(params: SortingPageFilter, ids: AuthorsFilter, emptyList = false) {
    if (this.contentPage) {
      this.videosService.getData(params, ids, emptyList);
    } else {
      this.videosService.getDataByPlayerId(this.playerId!, ids, this.params.sort);
    }
  }

  onAuthorsSelectionChange(event: MatSelectionListChange) {
    this.videosService.selectedAuthors = event.source.selectedOptions.selected.map((option) => option.value.id);
    this.params.page = 1;
    this.getData(this.params, { ids: this.videosService.selectedAuthors }, true);
  }

  onChangeSortingOrder(event: MatSelectChange) {
    this.params.sort = event.value;
    this.params.page = 1;
    this.getData(this.params, { ids: this.videosService.selectedAuthors }, true);
  }

  navigateTo(id: number) {
    this.router.navigateByUrl(`/videos/${id}`);
  }

  openInNewTab(id: number) {
    window.open(`/videos/${id}`, '_blank');
  }

  goToPackages() {
    this.scrollService.scrollTo('packagesSection');
    this.router.navigateByUrl('/');
  }

  ngOnDestroy(): void {
    this.videosService.videos$.next(null);
    this.videosService.videosSubs?.unsubscribe();
  }
}
