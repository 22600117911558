import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../../services/subscription.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ScrollService } from '../../services/scroll.service';
import { WalletConnectService } from '../../services/wallet-connect.service';
import { Button, PackageTypes, SubscriptionTypeData } from '../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS } from '../../enums';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit, AfterViewInit {
  @Input() isLevelsVisible: boolean = false;

  @AutoUnsubscribe()
  private scrollSubs: Subscription = {} as Subscription;

  @AutoUnsubscribe()
  private userSubs: Subscription = {} as Subscription;

  userSubscriptionData: SubscriptionTypeData = {} as SubscriptionTypeData;

  readonly upgradeBtn: Button = {
    name: 'Upgrade',
    size: BUTTON_SIZE.MD
  };

  readonly startBtn: Button = {
    name: 'Start',
    size: BUTTON_SIZE.MD
  };

  readonly downgradeBtn: Button = {
    name: 'Downgrade',
    size: BUTTON_SIZE.MD
  };

  readonly activeBtn: Button = {
    name: 'Active',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK_FILLED
  };

  readonly downgradeBtnDisabled: Button = {
    name: 'Downgrade',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK_FILLED
  };

  protected readonly COLOSSEUM_TIERS = COLOSSEUM_TIERS;

  showAll: boolean = false;
  visibleFeaturesCount: number = 6;

  levelsVisibility: any = {
    level0: false,
    level1: false,
    level2: false,
    level3: false
  };

  tableData: PackageTypes[] = [
    {
      name: 'Monetized Streaming',
      description: 'Donate and earn while streaming live games.',
      level0: {
        available: true,
        description: `<ul>
        <li>View streams</li>
        <li>Earn GMRX while viewing</li>
        <li>Donate GMRX during streams</li>
        </ul>
        `
      },
      level1: {
        available: true,
        description: `<ul>
        <li>View streams</li>
        <li>Earn GMRX while viewing</li>
        <li>Donate GMRX during streams</li>
        </ul>
        `
      },
      level2: {
        available: true,
        description: `<ul>
        <li>View streams</li>
        <li>Earn GMRX while viewing</li>
        <li>Donate GMRX during streams</li>
        </ul>
        `
      },
      level3: {
        available: true,
        description: `<ul>
        <li>View streams</li>
        <li>Earn GMRX while viewing</li>
        <li>Donate GMRX during streams</li>
        </ul>
        `
      }
    },
    {
      name: 'Premium Content',
      description: 'Access exclusive premium content from the Gladiators.',
      level0: {
        available: false,
        description: null
      },
      level1: {
        available: true,
        description: `<ul>
        <li>View Moments (short-form videos)</li>
        <li>View long-form video</li>
        <li>View exclusive news posts</li>
        </ul>`
      },
      level2: {
        available: true,
        description: `<ul>
        <li>View Moments (short-form videos)</li>
        <li>View long-form video</li>
        <li>View exclusive news posts</li>
        </ul>`
      },
      level3: {
        available: true,
        description: `<ul>
        <li>View Moments (short-form videos)</li>
        <li>View long-form video</li>
        <li>View exclusive news posts</li>
        </ul>`
      }
    },
    {
      name: 'Exclusive VIP Chat ',
      description: 'Live chat, dive into the heart of the team talk',
      level0: {
        available: false,
        description: null
      },
      level1: {
        available: false,
        description: null
      },
      level2: {
        available: false,
        description: null
      },
      level3: {
        available: true,
        description: `<ul>
        <li>Access to exclusive group chat with the GG staff for direct and faster communication</li>
        <li>Access regular giveaways like tickets to live events and training sessions</li>
        <li>Access frequent AMAs with GG staff and occasional player attendance</li>
        </ul>`
      }
    },
    {
      name: 'Fan Engagement Area',
      description: 'Vote on certain decisions that involve the team and earn reward points.',
      level0: {
        available: true,
        description: `<ul>
        <li>Participate in quizzes</li>
        <li>Participate in tournament placement prediction</li>
        <li>Participate in player of the month votes</li>
        <li>Collect points in the leaderboard</li>
        </ul>`
      },
      level1: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Participate in voting polls</li>
        <li>Participate in quizzes</li>
        <li>Participate in tournament placement prediction</li>
        <li>Participate in player of the month votes</li>
        <li>Collect points in the leaderboard</li>
        <li>Earn monthly GMRX rewards based on leaderboard placement</li>
        </ul>`
      },
      level2: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Participate in voting polls</li>
        <li>Participate in quizzes</li>
        <li>Participate in tournament placement prediction</li>
        <li>Participate in player of the month votes</li>
        <li>Collect points in the leaderboard</li>
        <li>Earn monthly GMRX rewards based on leaderboard placement</li>
        </ul>`
      },
      level3: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Participate in voting polls</li>
        <li>Participate in quizzes</li>
        <li>Participate in tournament placement prediction</li>
        <li>Participate in player of the month votes</li>
        <li>Collect points in the leaderboard</li>
        <li>Earn monthly GMRX rewards based on leaderboard placement</li>
        </ul>`
      }
    },
    {
      name: 'Dedicated Fan Profile',
      description: 'View all Colosseum assets and activities in one exciting interface.',
      level0: {
        available: true,
        description: `<ul>
        <li>Publicly showcase the NFT collection</li>
        <li>View NFT collection from other members</li>
        <li>Donate GMRX to other members</li>
        <li>Generate and share referral links to earn GMRX for first-time subscribers</li>
        </ul>`
      },
      level1: {
        available: true,
        description: `<ul>
        <li>Publicly showcase the NFT collection</li>
        <li>View NFT collection from other members</li>
        <li>Donate GMRX to other members</li>
        <li>Generate and share referral links to earn GMRX for first-time subscribers</li>
        </ul>`
      },
      level2: {
        available: true,
        description: `<ul>
        <li>Publicly showcase the NFT collection</li>
        <li>View NFT collection from other members</li>
        <li>Donate GMRX to other members</li>
        <li>Generate and share referral links to earn GMRX for first-time subscribers</li>
        </ul>`
      },
      level3: {
        available: true,
        description: `<ul>
        <li>Publicly showcase the NFT collection</li>
        <li>View NFT collection from other members</li>
        <li>Donate GMRX to other members</li>
        <li>Generate and share referral links to earn GMRX for first-time subscribers</li>
        </ul>`
      }
    },
    {
      name: 'In-depth Player Profiles',
      description: 'Explore our Gaimin Gladiators, inside out.',
      level0: {
        available: true,
        description: `<ul>
        <li>View detailed profile of each player</li>
        <li>View individual content from the player</li>
        <li>Donate GMRX directly to each player</li>
        </ul>`
      },
      level1: {
        available: true,
        description: `<ul>
        <li>View detailed profile of each player</li>
        <li>View individual content from the player</li>
        <li>Donate GMRX directly to each player</li>
        </ul>`
      },
      level2: {
        available: true,
        description: `<ul>
        <li>View detailed profile of each player</li>
        <li>View individual content from the player</li>
        <li>Ability to donate GMRX directly to each player</li>
        </ul>`
      },
      level3: {
        available: true,
        description: `<ul>
        <li>View detailed profile of each player</li>
        <li>View individual content from the player</li>
        <li>Donate GMRX directly to each player</li>
        </ul>`
      }
    },
    {
      name: 'DPA NFTs',
      description: 'Claim FREE exclusive Digital Proof of Attendance (DPA) NFTs for attending Gaimin Gladiators events',
      level0: {
        available: true,
        description: `<ul>
        <li>Get free limited-edition (soon-tradeable) Digital Proof of Attendance (DPA)NFTs for GG live events.</li>
        </ul>`
      },
      level1: {
        available: true,
        description: `<ul>
        <li>Get free limited-edition (soon-tradeable) Digital Proof of Attendance (DPA)NFTs for GG live events.</li>
        </li>
        </ul>`
      },
      level2: {
        available: true,
        description: `<ul>
        <li>Get free limited-edition (soon-tradeable) Digital Proof of Attendance (DPA)NFTs for GG live events.</li>
        </ul>`
      },
      level3: {
        available: true,
        description: `<ul>
        <li>Get free limited-edition (soon-tradeable) Digital Proof of Attendance (DPA)NFTs for GG live events.</li>
        </ul>`
      }
    },
    {
      name: 'Store Discounts',
      description: 'Get special mouthwatering discount deals on your swag purchases.',
      level0: {
        available: false,
        description: null
      },
      level1: {
        available: false,
        description: null
      },
      level2: {
        available: true,
        description: `<ul>
        <li>10% unlimited discount on GG store</li>
        </ul>`
      },
      level3: {
        available: true,
        plus: true,
        description: `<ul>
        <li>25% unlimited discount on GG store</li>
        </ul>`
      }
    },
    {
      name: 'Rewards Claim',
      description: 'Claim exclusive money-cant-buy experiences and seize the Gladiators most-prized collectibles.',
      level0: {
        available: false,
        description: null
      },
      level1: {
        available: false,
        description: null
      },
      level2: {
        available: true,
        description: `<ul>
        <li>Claim Standard GG Items & Money Can’t Buy Experiences with GMRX via direct purchase or Auctions</li>
        <li>Signed jerseys</li>
        <li>Personalized video messages sent to you</li>
        <li>Online meet and greet</li>
        <li>Participation tickets to exclusive tournaments hosted on Gaimin.gg</li>
        <li>Ad hoc rewards and many more!</li>
        </ul>`
      },
      level3: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Claim Unique GG Items & Money Can’t Buy Experiences with GMRX via direct purchase or Auctions</li>
        <li>Signed game-worn jerseys and game-used items</li>
        <li>Personalized video messages</li>
        <li>Online & Physical meet and greet with players & staff</li>
        <li>Online gaming sessions with players with live coaching</li>
        <li>Participation tickets to exclusive tournaments hosted on Gaimin.gg</li>
        <li>Personal shoutouts on GG & Players’ channels</li>
        <li>Exclusive merchandise</li>
        <li>Ad hoc rewards and many more!</li>
        </ul>`
      }
    },
    {
      name: 'Tiered Fan Badges ',
      description: 'Flex special fan status with dedicated badges that show publicly with your name.',
      level0: {
        available: false,
        description: null
      },
      level1: {
        available: true,
        description: `<ul>
        <li>Silver Badge publicly visible with your Discord Username</li>
        <li>Point-based Badge publicly showing your overall fan rank</li>
        </ul>`
      },
      level2: {
        available: true,
        description: `<ul>
        <li>Gold Badge publicly visible with your Discord Username</li>
        <li>Point-based Badge publicly showing your overall fan rank</li>
        </ul>`
      },
      level3: {
        available: true,
        description: `<ul>
        <li>Platinum Badge publicly visible with your Discord Username</li>
        <li>Point-based Badge publicly showing your overall fan rank</li>
        </ul>`
      }
    },
    {
      name: 'Charity Feature',
      description: 'Donate to any available charity cause.',
      level0: {
        available: true,
        description: `<ul>
        <li>Donate GMRX to charity partner</li>
        </ul>`
      },
      level1: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Donate GMRX to charity partner</li>
        <li>Honourable mentions of your username and (optionally) the amount donated</li>
        </ul>`
      },
      level2: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Donate GMRX to charity partner</li>
        <li>Honourable mentions of your username and (optionally) the amount donated</li>
        </ul>`
      },
      level3: {
        available: true,
        plus: true,
        description: `<ul>
        <li>Donate GMRX to charity partner</li>
        <li>Honourable mentions of your username and (optionally) the amount donated</li>
        </ul>`
      }
    }
  ];

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private scrollService: ScrollService,
    private router: Router,
    private subsService: SubscriptionService,
    public walletConnectService: WalletConnectService,
    private toastrService: ToastrService
  ) {
    this.userSubs = this.userService.userSubscriptionData$.subscribe({
      next: (response) => {
        this.userSubscriptionData = response;
      }
    });
  }

  ngOnInit(): void {
    this.showAll = this.isLevelsVisible;

    this.levelsVisibility = {
      level0: this.isLevelsVisible,
      level1: this.isLevelsVisible,
      level2: this.isLevelsVisible,
      level3: this.isLevelsVisible
    };
  }

  async upgradeSubscription(tierType: COLOSSEUM_TIERS, buttonName?: string) {
    if (this.userService.isAccountActive()) {
      if (this.walletConnectService.isWalletConnected$.getValue()) {
        const walletAddress = this.walletConnectService.walletAddress;

        if (tierType === COLOSSEUM_TIERS.SILVER) {
          this.authService.acquireAccount(true, tierType);
          this.signUp();
        } else {
          await this.subsService.getRemainingLockDays(walletAddress!);
          if ((this.subsService.unlockedDays as number) > 0n) {
            return await this.subsService.openUpgradePopup(tierType, walletAddress!, buttonName);
          }
          await this.subsService.subscribeProcess(tierType, walletAddress!);
        }
        this.walletConnectService.updateWalletBalances();
      } else {
        this.walletConnectService.openModal();
      }
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  async unsubscribe(tierType: COLOSSEUM_TIERS, buttonName: string | undefined) {
    if (this.userService.isAccountActive()) {
      if (tierType === COLOSSEUM_TIERS.BRONZE) {
        await this.subsService.getStakeBalance(this.walletConnectService.walletAddress!);
        if (this.subsService.unlockedDays === 0n) {
          await this.subsService.unsubscribeFrom();
        } else {
          this.toastrService.warning(
            'You can not downgrade to BRONZE subscription  because the lock days is not expired'
          );
        }
      } else {
        await this.upgradeSubscription(tierType, buttonName);
      }
      this.walletConnectService.updateWalletBalances();
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }

  signUp() {
    if (!this.authService.isLoggedIn$.getValue()) {
      this.authService.openLoginPopup(true);
    } else {
      this.router.navigateByUrl('content');
    }
  }

  toggleShowAll() {
    this.showAll = !this.showAll;

    for (const level in this.levelsVisibility) {
      this.levelsVisibility[level] = this.showAll;
    }
  }

  toggleItemShowAll(level: string) {
    this.showAll = false;
    this.levelsVisibility[level] = !this.levelsVisibility[level];
  }

  ngAfterViewInit() {
    this.scrollSubs = this.scrollService.scrollSubject$.subscribe((section) => {
      setTimeout(() => {
        if (section) {
          const element = document.getElementById(section);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            this.scrollService.scrollSubject$.next(null);
          }
        }
      }, 100);
    });
  }
}
