<div class="header">
  <h2>Messages</h2>
  <span class="icon-Close close-icon" title="Close" (click)="close()"></span>
</div>
<div class="items-container">
  <div class="items" #messagesContainer>
    <div class="item" *ngFor="let data of messagesList$ | async" [ngClass]="{ 'from-user': data.fromUser }">
      <img [src]="data.author.avatarUrl" onError="this.src = 'assets/icons/default-user-image.png'" alt="" />
      <div class="content" [ngClass]="{ 'from-user': data.fromUser }">
        <span>{{ data.author.fullName }} {{ data.createdOn | date: 'dd.MM.YY HH:mm' }}</span>
        <div class="text" [ngClass]="{ 'from-user': data.fromUser }">{{ data.message }}</div>
      </div>
    </div>
    <app-empty
      *ngIf="(messagesList$ | async) && (messagesList$ | async)!.length <= 0"
      [message]="'It looks like there are no existing messages between you and player.'"></app-empty>
  </div>
</div>
<div class="footer">
  <app-button (click)="onOpenDonate($event)" [buttonData]="openDonatePopoverBtn"></app-button>
</div>
