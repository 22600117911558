import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { Button, UnauthorizedContent } from '../../intarfaces';
import { BUTTON_SIZE } from '../../enums';

@Component({
  selector: 'app-share-popover',
  templateUrl: './share-popover.component.html',
  styleUrls: ['./share-popover.component.scss']
})
export class SharePopoverComponent implements AfterViewInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('copyBtn', { static: false }) copyBtn: any;
  @Input() metaTags: UnauthorizedContent | null = null;

  @AutoUnsubscribe()
  private activitySub: Subscription = {} as Subscription;

  readonly copyShareLinkBtn: Button = { name: 'Copy', size: BUTTON_SIZE.MD };

  shareLinkUrl = '';

  buttons = [
    {
      name: 'facebook',
      color: '#0866FF',
      icon: 'icon-Soc-Facebook'
    },
    {
      name: 'twitter',
      color: '#fff',
      icon: 'icon-Soc-Twitter'
    },
    {
      name: 'whatsapp',
      color: '#32D951',
      icon: 'icon-Soc-Whatsapp'
    },
    {
      name: 'telegram',
      color: '#00ADEC',
      icon: 'icon-Soc-Telegram'
    },
    {
      name: 'reddit',
      color: '#FF4500',
      icon: 'icon-Soc-Reddit'
    }
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private userService: UserService
  ) {}

  ngAfterViewInit() {
    this.shareLinkUrl = this.copyBtn.url;
    this.cdr.detectChanges();
  }

  onClose() {
    this.closeEvent.emit();
  }

  onCopy() {
    this.toastrService.success('The links has been copied.');
    this.sendUserActivity();
  }

  sendUserActivity() {
    this.activitySub = this.userService.sendUserActivityRequest().subscribe();
  }
}
