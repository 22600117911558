import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { NgxGridModule } from '@egjs/ngx-grid';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ShareModule } from 'ngx-sharebuttons';

import oktaConfig from './app.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HomeHeaderComponent } from './pages/home-page/home-header/home-header.component';
import { HomeFooterComponent } from './pages/home-page/home-footer/home-footer.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ContentPageComponent } from './pages/content-page/content-page.component';
import { StorePageComponent } from './pages/store-page/store-page.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { NftMarketPageComponent } from './pages/nft-market-page/nft-market-page.component';
import { FanAreaPageComponent } from './pages/fan-area-page/fan-area-page.component';
import { AuctionPageComponent } from './pages/auction-page/auction-page.component';
import { PlayersPageComponent } from './pages/players-page/players-page.component';
import { PlayersListComponent } from './pages/players-page/players-list/players-list.component';
import { PlayerPageComponent } from './pages/players-page/players-list/player-page/player-page.component';
import { StreamsPageComponent } from './pages/streams-page/streams-page.component';
import { ChannelListComponent } from './pages/streams-page/channel-list/channel-list.component';
import { ChannelPageComponent } from './pages/streams-page/channel-list/channel-page/channel-page.component';
import { VideosPageComponent } from './pages/videos-page/videos-page.component';
import { VideosListComponent } from './pages/videos-page/videos-list/videos-list.component';
import { VideoPageComponent } from './pages/videos-page/videos-list/video-page/video-page.component';
import { CharityPageComponent } from './pages/charity-page/charity-page.component';
import { ArticlePageComponent } from './pages/article-page/article-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';
import { FaqsPageComponent } from './pages/faqs-page/faqs-page.component';
import { PackagesPageComponent } from './pages/packages-page/packages-page.component';
import { EventsClaimPageComponent } from './pages/events-claim-page/events-claim-page.component';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { SearchComponent } from './shared/components/search/search.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { HeaderProfileComponent } from './shared/components/header-profile/header-profile.component';
import { EmptyComponent } from './shared/components/empty/empty.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { SocialsLoginComponent } from './shared/components/socials-login/socials-login.component';
import { YoutubeComponent } from './shared/components/youtube/youtube.component';
import { PackagesComponent } from './shared/components/packages/packages.component';
import { TickerComponent } from './shared/components/ticker/ticker.component';
import { FaqsGuidesComponent } from './shared/components/faqs-guides/faqs-guides.component';
import { FaqsQueriesComponent } from './shared/components/faqs-queries/faqs-queries.component';
import { ArticlesComponent } from './shared/components/articles/articles.component';
import { StoriesComponent } from './shared/components/stories/stories.component';
import { VideosComponent } from './shared/components/videos/videos.component';
import { DonatePopoverComponent } from './shared/components/donate-popover/donate-popover.component';
import { StreamsClaimRewardComponent } from './shared/components/streams-claim-reward/streams-claim-reward.component';
import { BannersComponent } from './shared/components/banners/banners.component';
import { FilterAuthorsComponent } from './shared/components/filter-authors/filter-authors.component';
import { FilterSortingComponent } from './shared/components/filter-sorting/filter-sorting.component';
import { ReactionsListComponent } from './shared/components/reactions-list/reactions-list.component';
import { ReactionsPopoverComponent } from './shared/components/reactions-popover/reactions-popover.component';
import { CommentsListComponent } from './shared/components/comments-list/comments-list.component';
import { StoriesCommentsListComponent } from './shared/components/stories-comments-list/stories-comments-list.component';
import { PlayerMessagesListComponent } from './shared/components/player-messages-list/player-messages-list.component';
import { SharePopoverComponent } from './shared/components/share-popover/share-popover.component';
import { InfographicsComponent } from './shared/components/infographics/infographics.component';
import { UnauthorizedContentComponent } from './shared/components/unauthorized-content/unauthorized-content.component';
import { SocialLinksComponent } from './shared/components/social-links/social-links.component';
import { EmailConfirmationComponent } from './shared/components/email-confirmation/email-confirmation.component';
import { LoginModalComponent } from './shared/components/modals/login-modal/login-modal.component';
import { ChangePasswordModalComponent } from './shared/components/modals/change-password-modal/change-password-modal.component';
import { BuyGmrxModalComponent } from './shared/components/modals/buy-gmrx-modal/buy-gmrx-modal.component';
import { ClaimRewardModalComponent } from './shared/components/modals/claim-reward-modal/claim-reward-modal.component';
import { EditAvatarModalComponent } from './shared/components/modals/edit-avatar-modal/edit-avatar-modal.component';
import { StoriesModalComponent } from './shared/components/modals/stories-modal/stories-modal.component';
import { ConfirmationModalComponent } from './shared/components/modals/confirmation-modal/confirmation-modal.component';
import { EditCommentModalComponent } from './shared/components/modals/edit-comment-modal/edit-comment-modal.component';
import { InfographicsModalComponent } from './shared/components/modals/infographics-modal/infographics-modal.component';
import { EmailConfirmationModalComponent } from './shared/components/modals/email-confirmation-modal/email-confirmation-modal.component';
import { OnboardingModalComponent } from './shared/components/modals/onboarding-modal/onboarding-modal.component';

import { ClickOutsideDirective } from './shared/directives/click-outside.directive';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

import { TooltipModule } from './shared/directives/tooltip-module/tooltip.module';

import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { PackagesModalComponent } from './shared/components/modals/packages-modal/packages-modal.component';
import { SubscriptionConfirmationModalComponent } from './shared/components/modals/subscription-confirmation-modal/subscription-confirmation-modal.component';
import { StoreShippingModalComponent } from './shared/components/modals/store-shipping-modal/store-shipping-modal.component';
import { StoreProductItemModalComponent } from './shared/components/modals/store-product-item-modal/store-product-item-modal.component';
import { StoreCarouselComponent } from './pages/store-page/store-carousel/store-carousel.component';
import { TurnstileCaptchaComponent } from './shared/components/turnstile-captcha/turnstile-captcha.component';
import { SubscriptionPaymentModalComponent } from './shared/components/modals/subscription-payment-modal/subscription-payment-modal.component';
import { FloatingMenuComponent } from './shared/components/floating-menu/floating-menu.component';
import { NotificationListComponent } from './shared/components/notification/notification-list/notification-list.component';
import { NotificationItemComponent } from './shared/components/notification/notification-item/notification-item.component';
import { FanAreaMenuComponent } from './shared/components/fan-area-menu/fan-area-menu.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { NotificationToastComponent } from './shared/components/notification/notification-toast/notification-toast.component';
import { BellIconComponent } from './shared/icons/bell-icon/bell-icon.component';
import { PasswordInputComponent } from './shared/components/password-input/password-input.component';
import { MeldModalComponent } from './shared/components/modals/meld-modal/meld-modal.component';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    LoaderComponent,
    ProfilePageComponent,
    SidebarComponent,
    SearchComponent,
    EmptyComponent,
    ContentPageComponent,
    StorePageComponent,
    RewardsPageComponent,
    NftMarketPageComponent,
    FanAreaPageComponent,
    AuctionPageComponent,
    PlayersPageComponent,
    PlayersListComponent,
    PlayerPageComponent,
    StreamsPageComponent,
    ChannelListComponent,
    ChannelPageComponent,
    VideosPageComponent,
    VideosListComponent,
    VideoPageComponent,
    CharityPageComponent,
    ArticlePageComponent,
    TermsPageComponent,
    PolicyPageComponent,
    FaqsPageComponent,
    PackagesPageComponent,
    EventsClaimPageComponent,
    HeaderComponent,
    HeaderProfileComponent,
    ArticlesComponent,
    StoriesComponent,
    VideosComponent,
    LoginModalComponent,
    ChangePasswordModalComponent,
    PasswordInputComponent,
    BuyGmrxModalComponent,
    ClaimRewardModalComponent,
    EditAvatarModalComponent,
    StoriesModalComponent,
    ConfirmationModalComponent,
    EmailConfirmationModalComponent,
    OnboardingModalComponent,
    EditCommentModalComponent,
    InfographicsModalComponent,
    PackagesModalComponent,
    ButtonComponent,
    FilterAuthorsComponent,
    FilterSortingComponent,
    SocialsLoginComponent,
    ClickOutsideDirective,
    YoutubeComponent,
    PackagesComponent,
    TickerComponent,
    FaqsGuidesComponent,
    FaqsQueriesComponent,
    DonatePopoverComponent,
    StreamsClaimRewardComponent,
    BannersComponent,
    ReactionsListComponent,
    ReactionsPopoverComponent,
    CommentsListComponent,
    StoriesCommentsListComponent,
    PlayerMessagesListComponent,
    SharePopoverComponent,
    InfographicsComponent,
    StoreShippingModalComponent,
    StoreProductItemModalComponent,
    StoreCarouselComponent,
    SubscriptionConfirmationModalComponent,
    UnauthorizedContentComponent,
    SocialLinksComponent,
    EmailConfirmationComponent,
    CustomDatePipe,
    StripHtmlPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    MeldModalComponent,
    ThousandSeparatorPipe,
    TurnstileCaptchaComponent,
    SubscriptionPaymentModalComponent,
    FloatingMenuComponent,
    NotificationListComponent,
    NotificationItemComponent,
    FanAreaMenuComponent,
    NotificationsPageComponent,
    NotificationToastComponent,
    BellIconComponent,
    PasswordInputComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatBottomSheetModule,
    ReactiveFormsModule,
    CarouselModule,
    DragScrollComponent,
    DragScrollItemDirective,
    NgxGridModule,
    PickerComponent,
    EmojiModule,
    ShareModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    TooltipModule,
    NgOptimizedImage,
    FormsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class AppModule {}
