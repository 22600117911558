import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { UserInfo, Button } from '../../../intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../enums';

@Component({
  selector: 'app-edit-avatar-modal',
  templateUrl: './edit-avatar-modal.component.html',
  styleUrls: ['./edit-avatar-modal.component.scss']
})
export class EditAvatarModalComponent implements OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;
  profile: UserInfo;
  avatarPath: string;
  imageUrl: string = '';
  avatarUpdated: boolean = false;
  readonly saveButton: Button = { name: 'Save', size: BUTTON_SIZE.LG };
  readonly cancelButton: Button = { name: 'Cancel', size: BUTTON_SIZE.LG, type: BUTTON_TYPE.DARK };
  isSaveButtonPressed: boolean = false;
  image: File = {} as File;
  private maxFileSize = 10485760; // 10mb in bytes

  constructor(
    private toastrService: ToastrService,
    public userService: UserService,
    private dialogRef: MatDialogRef<EditAvatarModalComponent>
  ) {
    this.profile = this.userService.userInfo$.getValue();
    this.avatarPath = this.profile.avatarUrl ? this.profile.avatarUrl : '';
  }

  onChange(event: any) {
    if (event.target.files.length === 0) {
      return;
    }

    this.image = event.target.files[0];

    if (this.image.type.match('image/jpeg|image/png') == null) {
      this.avatarUpdated = false;
      this.toastrService.error('Attached file type is incorrect');
    } else if (this.image.size > this.maxFileSize) {
      this.avatarUpdated = false;
      this.toastrService.error('Attached file max-size is 10MB');
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(this.image);
      fileReader.onload = () => {
        this.avatarUpdated = true;
        this.imageUrl = this.image.name;
        this.profile.avatarUrl = fileReader.result?.toString();
      };
    }
  }

  onSubmit() {
    this.closeModal();
    if (this.avatarUpdated) {
      this.isSaveButtonPressed = true;

      const formData = new FormData();
      formData.append('image', this.image);

      this.userService.uploadAvatarImage(formData).subscribe({
        next: (response) => {
          if (response.success && response.data) {
            this.profile.avatarUrl = response.data;
            this.userService.updateUserAvatar({ avatarUrl: this.profile.avatarUrl }).subscribe({
              next: (responseUserAvatar) => {
                if (responseUserAvatar.success && responseUserAvatar.data) {
                  this.userService.userInfo$.next(responseUserAvatar.data);
                  this.toastrService.success('Profile photo updated successfully.');
                } else {
                  this.handleError();
                }
              },
              error: () => {
                this.handleError();
              }
            });
          } else {
            this.handleError();
          }
        },
        error: () => {
          this.handleError();
        }
      });
    }
    this.avatarUpdated = false;
  }

  onRemove() {
    this.fileInput.nativeElement.value = '';
    this.avatarUpdated = false;
    this.imageUrl = '';
    this.profile.avatarUrl = this.avatarPath;
  }

  onCancel() {
    this.fileInput.nativeElement.value = '';
    this.profile.avatarUrl = this.avatarPath;
    this.closeModal();
  }

  handleError() {
    this.onRemove();
    this.toastrService.error('Unable to update the profile photo.');
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      if (!this.isSaveButtonPressed) {
        this.profile.avatarUrl = this.avatarPath;
      }
    }, 500);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
