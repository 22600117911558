import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AdditionalService } from '../../shared/services/additional.service';
import { AuthService } from '../../shared/services/auth.service';
import { ContentService } from '../../shared/services/content.service';
import { ArticlesService } from '../../shared/services/articles.service';
import { ArticleData, ReactionsListData, CommentsData, UnauthorizedContent } from '../../shared/intarfaces';
import { POPOVER_TYPE } from '../../shared/enums';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
  providers: [ArticlesService]
})
export class ArticlePageComponent implements OnInit {
  @AutoUnsubscribe()
  private articleSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private reactionsSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private metaSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private routeSub: Subscription = {} as Subscription;

  id: number | null = null;
  data: ArticleData = {} as ArticleData;
  reactions: ReactionsListData[] = [];
  comments: CommentsData[] = [];
  popoverListPosition: POPOVER_TYPE = POPOVER_TYPE.CENTER_RIGHT;
  mediaVideoUrl: SafeResourceUrl | null = null;
  isSharePopoverOpen: boolean = false;
  unauthorizedContent: UnauthorizedContent | null = null;
  isAccessRestricted: boolean = false;
  loading: boolean = false;

  constructor(
    public additionalService: AdditionalService,
    public contentService: ContentService,
    public articlesService: ArticlesService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.id = parseInt(id);
        this.getArticleById(this.id);
      } else {
        this.goToArticles();
      }
    });
  }

  getArticleById(id: number, sortingOrder?: string) {
    this.metaSub = this.articlesService.getArticleMetaByIdRequest(id).subscribe((res) => {
      if (res.success && res.data) {
        this.unauthorizedContent = {
          image: res.data.imageUrl,
          title: res.data.title,
          description: res.data.text
        };

        this.additionalService.updateMetaTags({
          ...this.unauthorizedContent,
          url: window.location.href
        });
      }
    });

    if (this.authService.isLoggedIn$.getValue()) {
      this.loading = true;
      this.articleSub = this.articlesService.getArticleByIdRequest(id, sortingOrder).subscribe((res) => {
        if (res.success && res.data) {
          this.data = res.data.article;
          this.reactions = res.data.reactions;
          this.comments = res.data.comments;


          if (this.data.videoUrl) {
            this.mediaVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.videoUrl);
          }
        } else if (res.error && res.error.type == 'UNAVAILABLE') {
          this.isAccessRestricted = true;
        }
        this.loading = false;
      });
    }
  }

  onSelectReaction(reaction: ReactionsListData) {
    this.reactionsSub = this.articlesService.handleArticleReactionRequest(this.id!, reaction.name).subscribe();
  }

  updateCommentsOrder(sortingOrder: string) {
    this.getArticleById(this.id!, sortingOrder);
  }

  goToArticles() {
    this.router.navigateByUrl(`/content`);
  }

  openSharePopover() {
    this.isSharePopoverOpen = !this.isSharePopoverOpen;
  }

  closeSharePopover() {
    this.isSharePopoverOpen = false;
  }
}
