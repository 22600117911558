import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value.replace(/<[^>]*>|&#[0-9]+;/g, '').replace(/&nbsp;/g, ' ');
  }
}
