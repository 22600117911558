<div class="comments" #commentsList>
  <div class="comments__list">
    <div class="comments__list-item" *ngFor="let item of comments">
      <button
        class="icon-Dots-2 comments__list-item__actions"
        mat-button
        [matMenuTriggerFor]="menu"
        aria-label="Comment actions menu"
        *ngIf="
          item.authorId === (userService.userInfo$ | async)?.id ||
          authService.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER])
        "></button>

      <mat-menu #menu="matMenu" class="comments-menu">
        <button
          mat-menu-item
          (click)="onEditComment(item)"
          *ngIf="item.authorId === (userService.userInfo$ | async)?.id">
          <span class="icon-Edit icon"></span>
          <span class="comments-menu__text">Edit</span>
        </button>
        <button mat-menu-item (click)="onRemoveComment(item)">
          <span class="icon-Delete icon"></span>
          <span class="comments-menu__text">Delete</span>
        </button>
      </mat-menu>
      
      <div class="comments__list-item__author">
        <img [src]="item.avatarUrl" alt="" onError="this.src = 'assets/icons/default-user-image.png'" />
        <p>
          <span class="name">
            {{
              item.authorNickname && item.authorNickname !== '' ? item.authorNickname : item.authorName + item.authorId
            }}
          </span>
          <span class="date">{{ item.createdOn | customDate }}</span>
        </p>
      </div>
      <div class="comments__list-item__message">
        {{ item.message }}
      </div>
      <app-reactions-list
        [reactions]="item.reactions"
        [position]="popoverListPosition"
        (selectReactionEvent)="onSelectReaction($event, item.commentId)"></app-reactions-list>
    </div>
  </div>
</div>

<form [formGroup]="form" class="comments__form">
  <div class="comments__form-field">
    <textarea formControlName="message" placeholder="Add a comment" rows="1" #messageInput autofocus></textarea>
    <div class="comments__form-field__reactions">
      <app-reactions-popover
        (selectReactionEvent)="onAddReactionToMessage($event)"
        [position]="popoverPosition"></app-reactions-popover>
    </div>
  </div>
  <app-button [buttonData]="submitBtn" [attr.disabled]="form.invalid" (click)="onAddComment()"></app-button>
</form>
