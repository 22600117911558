import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { SHOW_LOADER } from '../../interceptors/loader.interceptor';
import { environment } from '../../../environments/environment';
import {
  AuthorsFilter,
  Response,
  SortingPageFilter,
  ArticleData,
  ArticlesListData,
  ArticleDetailsData,
  CommentsData,
  MetaContentData
} from '../intarfaces';
import { SORTING_ORDER } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  public articlesSubs: Subscription | undefined;
  articles$: BehaviorSubject<ArticleData[] | null> = new BehaviorSubject<ArticleData[] | null>(null);
  selectedAuthors: number[] = [];

  params: SortingPageFilter = {
    page: 1,
    size: 16,
    sort: SORTING_ORDER.DESC
  };
  totalPages: number = 0;

  loading: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  clearFilters() {
    this.totalPages = 0;
    this.selectedAuthors = [];
    this.params = {
      page: 1,
      size: 16,
      sort: SORTING_ORDER.DESC
    };
  }

  getData(params: SortingPageFilter, ids: AuthorsFilter, emptyList = false) {
    this.loading = true;
    this.articlesSubs = this.getArticlesRequest(params, ids).subscribe((res) => {
      if (res.success && res.data) {
        if (emptyList) {
          this.articles$.next(res.data.articles);
        } else {
          const currentArticles = this.articles$.getValue() || [];
          this.articles$.next([...currentArticles, ...res.data.articles]);
        }
        this.totalPages = res.data.pagination.totalPages;
      }
      this.loading = false;
    });
  }

  loadMore() {
    this.params.page++;
    this.getData(this.params, { ids: this.selectedAuthors });
  }

  getArticlesRequest(params: SortingPageFilter, ids: AuthorsFilter): Observable<Response<ArticlesListData>> {
    const queryParams = new HttpParams()
      .set('page', params.page)
      .set('size', params.size)
      .set('sort', 'publishDate,' + params.sort);

    return this.http.post<Response<ArticlesListData>>(`${environment.gaiminApi}/content/articles`, ids, {
      headers: this.authService.authorizationHeader(),
      params: queryParams
    });
  }

  getArticlesByPlayerIdRequest(
    playerId: number,
    ids: AuthorsFilter,
    sort: SORTING_ORDER
  ): Observable<Response<ArticleData[]>> {
    const queryParams = new HttpParams().set('sort', sort);

    return this.http.post<Response<ArticleData[]>>(
      `${environment.gaiminApi}/content/articles/players/${playerId}`,
      ids,
      {
        headers: this.authService.authorizationHeader(),
        params: queryParams
      }
    );
  }

  getArticleByIdRequest(id: number, sort: string = SORTING_ORDER.DESC): Observable<Response<ArticleDetailsData>> {
    const queryParams = new HttpParams().set('sort', sort);

    return this.http.get<Response<ArticleDetailsData>>(`${environment.gaiminApi}/content/articles/${id}`, {
      headers: this.authService.authorizationHeader(),
      params: queryParams
    });
  }

  getArticleMetaByIdRequest(id: number): Observable<Response<MetaContentData>> {
    return this.http.get<Response<MetaContentData>>(`${environment.gaiminApi}/content/admin/articles/preview/${id}`);
  }

  handleArticleReactionRequest(itemId: number, reaction: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.gaiminApi}/content/articles/${itemId}/reactions`,
      { name: reaction },
      {
        headers: this.authService.authorizationHeader(),
        context: new HttpContext().set(SHOW_LOADER, false)
      }
    );
  }

  addCommentRequest(itemId: number, message: string): Observable<Response<CommentsData>> {
    return this.http.post<Response<CommentsData>>(
      `${environment.gaiminApi}/content/articles/${itemId}/comments`,
      { message: message },
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  updateCommentRequest(itemId: number, commentId: number, message: string): Observable<Response<any>> {
    return this.http.patch<Response<any>>(
      `${environment.gaiminApi}/content/articles/${itemId}/comments/${commentId}`,
      { message: message },
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  deleteCommentRequest(itemId: number, commentId: number): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `${environment.gaiminApi}/content/articles/${itemId}/comments/${commentId}`,
      {
        headers: this.authService.authorizationHeader()
      }
    );
  }

  deleteCommentRequestByAdmin(itemId: number, commentId: number, credential: string): Observable<Response<any>> {
    return this.http.delete<Response<any>>(
      `${environment.gaiminApi}/content/admin/articles/${itemId}/comments/${commentId}`,
      {
        headers: new HttpHeaders({
          Authorization: `Basic ${credential}`
        })
      }
    );
  }

  handleCommentReactionRequest(itemId: number, commentId: number, reaction: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.gaiminApi}/content/articles/${itemId}/comments/${commentId}/reactions`,
      { name: reaction },
      {
        headers: this.authService.authorizationHeader(),
        context: new HttpContext().set(SHOW_LOADER, false)
      }
    );
  }
}
