import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ContentService } from '../../services/content.service';
import { SORTING_ORDER } from '../../enums';

@Component({
  selector: 'app-filter-sorting',
  templateUrl: './filter-sorting.component.html',
  styleUrls: ['./filter-sorting.component.scss']
})
export class FilterSortingComponent implements OnInit {
  @Output() changeSorting = new EventEmitter<any>();
  @Input() selectedSorting: SORTING_ORDER = SORTING_ORDER.DESC;

  protected readonly SORTING_ORDER = SORTING_ORDER;

  constructor(public contentService: ContentService) {}

  ngOnInit(): void {}

  onChangeSortingOrder(event: MatSelectChange) {
    this.changeSorting.emit(event);
    this.selectedSorting = event.value;
  }
}
