<div class="streaming" [hidden]="loaderService.isLoading | async">
  <img class="streaming-bg" src="assets/icons/streams/bg.png" alt="" />
  <div class="streaming-container">
    <div class="streaming-header">
      <h2 class="title">Streams</h2>
      <app-streams-claim-reward></app-streams-claim-reward>
    </div>
    <div class="streaming-list">
      <div class="streaming-list__item" *ngFor="let channel of twitchService.channelsWithStatus$ | async">
        <img
          loading="lazy"
          [src]="channel.imageUrl"
          [alt]="channel.channelName"
          (click)="navigateTo(channel.channelName)" />
        <span *ngIf="channel.isLive" class="streaming-list__item-live">LIVE</span>
        <h2 class="streaming-list__item-name" (click)="navigateTo(channel.channelName)">{{ channel.channelName }}</h2>
        <div class="streaming-list__item-info">
          <h2 (click)="navigateTo(channel.channelName)">{{ channel.channelName }}</h2>
          <h3 (click)="navigateTo(channel.channelName)">{{ channel.streamerName }}</h3>
          <p>{{ channel.description }}</p>
          <app-social-links *ngIf="channel.socialLinks" [socialLinks]="channel.socialLinks"></app-social-links>
        </div>
      </div>
    </div>
  </div>
</div>

