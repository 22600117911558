import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '../../../shared/services/content.service';

@Component({
  selector: 'app-players-list',
  templateUrl: './players-list.component.html',
  styleUrls: ['./players-list.component.scss']
})
export class PlayersListComponent implements OnInit {
  constructor(
    private router: Router,
    public contentService: ContentService
  ) {}

  ngOnInit(): void {}

  goToContent() {
    this.router.navigateByUrl(`/content`);
  }

  navigateTo(id: number) {
    this.router.navigateByUrl(`/players/${id}`);
  }

  openInNewTab(id: number) {
    window.open(`/players/${id}`, '_blank');
  }
}
