import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { StoriesModalComponent } from '../modals/stories-modal/stories-modal.component';
import { ScrollService } from '../../services/scroll.service';
import { StoriesService } from '../../services/stories.service';
import { ContentService } from '../../services/content.service';
import { SortingPageFilter, AuthorsFilter } from '../../intarfaces';
import { SORTING_ORDER } from '../../enums';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {
  @AutoUnsubscribe()
  private dialogSubs: Subscription = {} as Subscription;

  @Input() playerId: number | null = null;

  params: SortingPageFilter = {
    page: 1,
    size: 5,
    sort: SORTING_ORDER.DESC
  };

  contentPage: boolean = false;
  isMobile = false;

  constructor(
    private scrollService: ScrollService,
    public contentService: ContentService,
    public storiesService: StoriesService,
    private dialogService: MatDialog,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
  }

  ngOnInit(): void {
    this.contentPage = !this.playerId;
    this.getData(this.params, { ids: [] });
  }

  getData(params: SortingPageFilter, ids: AuthorsFilter, emptyList = false) {
    if (this.contentPage) {
      this.storiesService.getData(params, ids, emptyList);
    } else {
      this.storiesService.getDataByPlayerId(this.playerId!, ids, this.params.sort);
    }
  }

  onAuthorsSelectionChange(event: MatSelectionListChange) {
    this.storiesService.selectedAuthors = event.source.selectedOptions.selected.map((option) => option.value.id);
    this.getData(this.storiesService.params, { ids: this.storiesService.selectedAuthors }, true);
  }

  onChangeSortingOrder(event: MatSelectChange) {
    this.params.sort = event.value;
    this.params.page = 1;
    this.getData(this.params, { ids: this.storiesService.selectedAuthors }, true);
  }

  openStoriesPopup(index: number) {
    const dialogRef = this.dialogService.open(StoriesModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-video-preview'],
      data: index
    });

    this.dialogSubs = dialogRef.afterClosed().subscribe(() => {
      this.storiesService.params.page = 1;
    });
  }

  goToPackages() {
    this.scrollService.scrollTo('packagesSection');
    this.router.navigateByUrl('/');
  }

  ngOnDestroy(): void {
    this.storiesService.stories$.next(null);
    this.storiesService.clearFilters();
    this.storiesService.storiesSubs?.unsubscribe();
  }
}
