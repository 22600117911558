<div class="package">
  <div class="grid">
    <div class="grid__header">
      <div class="grid__header-col hide-mobile"></div>
      <div>
        <div class="grid__header-col">
          <div class="grid__header-col__data">
            <h2>Bronze</h2>
            <div class="grid__header-col__data-price">
              <h3>Free</h3>
            </div>
            <img src="assets/icons/packages/bronze.png" alt="" />
          </div>
          <div class="grid__header-col__btn">
            <ng-container
              *ngIf="
                userSubscriptionData.type === COLOSSEUM_TIERS.BRONZE || !userSubscriptionData.type;
                then downgradeBtnDisabledTemp
              "></ng-container>

            <ng-container *ngIf="userSubscriptionData.type !== COLOSSEUM_TIERS.BRONZE && userSubscriptionData.type">
              <ng-container *ngTemplateOutlet="downgradeBtnTemp; context: { $implicit: COLOSSEUM_TIERS.BRONZE }">
              </ng-container>
            </ng-container>

            <div>Sign up now and access essential features & benefits for FREE.</div>
          </div>
        </div>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level0 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level0.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level0.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level0.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level0')">
            {{ levelsVisibility.level0 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level0 }"></span>
          </span>
        </div>
      </div>
      <div>
        <div class="grid__header-col" id="silverPackage">
          <div class="grid__header-col__data">
            <h2>Silver <span>(Free)</span></h2>
            <div class="grid__header-col__data-price">
              <h3>Lock</h3>
              <div class="price-wrapper line">
                <span class="currency">$</span><span class="price">5</span><span class="period">worth of GMRX</span>
              </div>
            </div>
            <div class="grid__header-col__data-hint">
              + $30 worth of GMRX Yearly Activation Fee (First Year is FREE)
            </div>
            <img src="assets/icons/packages/silver.png" alt="" />
          </div>
          <div class="grid__header-col__btn">
            <ng-container *ngIf="userSubscriptionData.type === COLOSSEUM_TIERS.SILVER">
              <ng-container *ngTemplateOutlet="activeBtnTemp"></ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                userSubscriptionData.type !== COLOSSEUM_TIERS.SILVER &&
                userSubscriptionData.type === COLOSSEUM_TIERS.BRONZE
              ">
              <ng-container
                *ngTemplateOutlet="upgradeBtnTemp; context: { $implicit: COLOSSEUM_TIERS.SILVER }"></ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                (userSubscriptionData.type !== COLOSSEUM_TIERS.SILVER &&
                  userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM) ||
                (userSubscriptionData.type !== COLOSSEUM_TIERS.SILVER &&
                  userSubscriptionData.type === COLOSSEUM_TIERS.GOLD)
              ">
              <ng-container
                *ngTemplateOutlet="downgradeBtnTemp; context: { $implicit: COLOSSEUM_TIERS.SILVER }"></ng-container>
            </ng-container>

            <ng-container *ngIf="!userSubscriptionData.type">
              <ng-container *ngTemplateOutlet="startBtnTemp"></ng-container>
            </ng-container>

            <div>
              Unlock Silver-Tier Experiences – View exclusive content, donate & earn GMRX tokens, and many more.
            </div>
          </div>
        </div>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level1 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level1.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level1.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level1.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level1')">
            {{ levelsVisibility.level1 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level1 }"></span>
          </span>
        </div>
      </div>
      <div>
        <div class="grid__header-col">
          <div class="grid__header-col__data">
            <h2>Gold</h2>
            <div class="grid__header-col__data-price">
              <h3>Lock</h3>
              <div class="price-wrapper">
                <span class="currency">$</span><span class="price">25</span><span class="period">worth of GMRX</span>
              </div>
            </div>
            <div class="grid__header-col__data-hint">
              + $30 worth of GMRX Yearly Activation Fee (First Year is FREE)
            </div>
            <img src="assets/icons/packages/gold.png" alt="" />
          </div>
          <div class="grid__header-col__btn">
            <ng-container *ngIf="userSubscriptionData.type === COLOSSEUM_TIERS.GOLD">
              <ng-container *ngTemplateOutlet="activeBtnTemp"></ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                (userSubscriptionData.type !== COLOSSEUM_TIERS.GOLD &&
                  userSubscriptionData.type === COLOSSEUM_TIERS.SILVER) ||
                userSubscriptionData.type === COLOSSEUM_TIERS.BRONZE
              ">
              <ng-container *ngTemplateOutlet="upgradeBtnTemp; context: { $implicit: COLOSSEUM_TIERS.GOLD }">
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                userSubscriptionData.type !== COLOSSEUM_TIERS.GOLD &&
                userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM
              ">
              <ng-container
                *ngTemplateOutlet="downgradeBtnTemp; context: { $implicit: COLOSSEUM_TIERS.GOLD }"></ng-container>
            </ng-container>

            <ng-container *ngIf="!userSubscriptionData.type">
              <ng-container *ngTemplateOutlet="startBtnTemp"></ng-container>
            </ng-container>
            <div>
              Unlock Gold-Tier Experiences - Exclusive content, GMRX rewards, 10% shop discount & other unique perks.
            </div>
          </div>
        </div>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level2 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level2.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level2.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level2.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level2')">
            {{ levelsVisibility.level2 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level2 }"></span>
          </span>
        </div>
      </div>
      <div>
        <div class="grid__header-col">
          <div class="grid__header-col__data">
            <h2>Platinum</h2>
            <div class="grid__header-col__data-price">
              <h3>Lock</h3>
              <div class="price-wrapper">
                <span class="currency">$</span><span class="price">100</span><span class="period">worth of GMRX</span>
              </div>
            </div>
            <div class="grid__header-col__data-hint">
              + $30 worth of GMRX Yearly Activation Fee (First Year is FREE)
            </div>
            <img src="assets/icons/packages/platinum.png" alt="" />
          </div>
          <div class="grid__header-col__btn">
            <ng-container *ngIf="userSubscriptionData.type === COLOSSEUM_TIERS.PLATINUM">
              <ng-container *ngTemplateOutlet="activeBtnTemp"></ng-container>
            </ng-container>
            <ng-container *ngIf="userSubscriptionData.type !== COLOSSEUM_TIERS.PLATINUM && userSubscriptionData.type">
              <ng-container
                *ngTemplateOutlet="upgradeBtnTemp; context: { $implicit: COLOSSEUM_TIERS.PLATINUM }"></ng-container>
            </ng-container>
            <ng-container *ngIf="!userSubscriptionData.type">
              <ng-container *ngTemplateOutlet="startBtnTemp"></ng-container>
            </ng-container>

            <div>
              Unlock Platinum-Tier Experiences – The Ultimate perk list, 25% shop discount, exclusive experiences,
              direct communication, and many more.
            </div>
          </div>
        </div>
        <ng-container *ngFor="let data of tableData; let i = index">
          <div class="grid__row hide-desktop" *ngIf="showAll || levelsVisibility.level3 || i < visibleFeaturesCount">
            <div class="grid__row-col">
              {{ data.name }}
              <span
                class="icon icon-tooltip icon-Question"
                [tooltip]="data.description"
                [tooltipPosition]="'left'"></span>
              <div class="icons-container">
                <span
                  class="icon"
                  [ngClass]="data.level3.available ? 'icon-Available active' : 'icon-Disabled'"
                  [tooltip]="data.level3.description"
                  [tooltipPosition]="'bottom'"></span>
                <span class="plus" *ngIf="data.level3.plus">+</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="show-more hide-desktop" *ngIf="!isLevelsVisible">
          <span (click)="toggleItemShowAll('level3')">
            {{ levelsVisibility.level3 ? 'View Less Features' : 'View More Features' }}
            <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: levelsVisibility.level3 }"></span>
          </span>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let data of tableData; let i = index">
      <div class="grid__row hide-mobile" *ngIf="showAll || i < visibleFeaturesCount">
        <div class="grid__row-col">
          {{ data.name }}
          <span class="icon icon-tooltip icon-Question" [tooltip]="data.description" [tooltipPosition]="'right'"></span>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level0.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level0.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level0.plus">+</span>
          </div>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level1.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level1.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level1.plus">+</span>
          </div>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level2.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level2.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level2.plus">+</span>
          </div>
        </div>
        <div class="grid__row-col">
          <div class="icons-container">
            <span
              class="icon"
              [ngClass]="data.level3.available ? 'icon-Available active' : 'icon-Disabled'"
              [tooltip]="data.level3.description"
              [tooltipPosition]="'bottom'"></span>
            <span class="plus" *ngIf="data.level3.plus">+</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="package__show-more hide-mobile" *ngIf="!isLevelsVisible">
    <span (click)="toggleShowAll()">
      {{ showAll ? 'View Less Features' : 'View More Features' }}
      <span class="icon icon-Arrow-Bottom" [ngClass]="{ open: showAll }"></span>
    </span>
  </div>
</div>

<ng-template #activeBtnTemp>
  <app-button [buttonData]="activeBtn" [disabled]="true"></app-button>
</ng-template>

<ng-template #upgradeBtnTemp let-tierType>
  <app-button (click)="upgradeSubscription(tierType, upgradeBtn.name)" [buttonData]="upgradeBtn"></app-button>
</ng-template>

<ng-template #downgradeBtnDisabledTemp>
  <app-button [buttonData]="downgradeBtnDisabled" [disabled]="true"></app-button>
</ng-template>

<ng-template #downgradeBtnTemp let-tierType>
  <app-button (click)="unsubscribe(tierType, downgradeBtn.name)" [buttonData]="downgradeBtn"></app-button>
</ng-template>

<ng-template #startBtnTemp>
  <app-button (click)="signUp()" [buttonData]="startBtn"></app-button>
</ng-template>
